import * as React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Footer, Header } from '../components'
import Login from '../pages/Login/Login'
import LoginLTI from '../pages/Login/LoginLTI'
import paths from './paths'
import PrivateRoute from './router.guardian'

const LTI = process.env.REACT_APP_LTI

export default function Routes() {
  const { isLogged } = useSelector((state) => state.LoginApiReducer)
  return (
    <Switch>
      <Route exact path='/'>
        {isLogged ? (
          <Redirect to='/bienvenida' />
        ) : !LTI ? (
          <LoginLTI />
        ) : (
          <Login />
        )}
      </Route>
      <Route exact path='/loginLTI/:token/:roles?'>
        {<LoginLTI />}
      </Route>
      {paths.map((route) => {
        const tempComponent = route.component
        const Component = (props = {}) =>
          React.cloneElement(tempComponent, { ...props })
        return (
          <PrivateRoute exact path={route.path} key={route.id}>
            {route.header && <Header states={route.states}></Header>}
            <Component />
            {route.footer && <Footer states={route.states}></Footer>}
          </PrivateRoute>
        )
      })}
    </Switch>
  )
}
