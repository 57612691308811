import { Icon, Text } from 'ui-components'
import { CardInfoReportStyle } from './styled'

function CardInfoReport({ title, porcent, icon }) {
  return (
    <CardInfoReportStyle>
      <Icon nameIcon={icon} />
      <div>
        <p>
          {porcent.value} <b>{porcent.format}</b>
        </p>
        <Text type='h6'>{title}</Text>
      </div>
    </CardInfoReportStyle>
  )
}

export { CardInfoReport }
