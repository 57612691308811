import styled from 'styled-components'

export const NavDeskoptStyle = styled.nav`
  display: grid;
  grid-template-columns: 100px 1fr 170px 50px;
  align-items: center;
  gap: 10px;
  .c-nav {
    &-logout {
      background-color: transparent;
      border: none;
    }
    &-navbar {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
      align-items: center;
      justify-content: center;
      height: 100%;
      &--link {
        height: 100%;

        a {
          display: flex;
          justify-content: center;
          height: 100%;
          position: relative;
          &.active {
            &::before {
              content: '';
              width: 100%;
              height: 5px;
              background-color: var(--secondaryColor);
              position: absolute;
              bottom: 0;
            }
          }
          span {
            color: transparent;
            font-size: 0;
          }
          &[disabled] {
            opacity: 0.5;
          }
        }
        :first-child {
          &.help {
            display: flex;
            justify-content: flex-end;
            padding-right: 1.5rem;
            span {
              color: transparent;
              font-size: 0;
            }
          }
        }
      }
    }
    &-user {
      grid-column: 3/4;
      justify-content: center;
      display: flex;
    }
    &-logo {
      padding: 1rem 0;
    }
  }
`
