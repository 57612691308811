import styled from 'styled-components'

export const ManageUserStyle = styled.div`
  .ui-gridTable-body {
    &-item {
      &:nth-child(1) {
        grid-column: 1 / 4;
      }
    }
    .c-item-user {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media screen and (max-width: 700px) {
    .ui-gridTable-head {
      display: none;
    }
    .ui-gridTable-body {
      &-item {
        &:nth-child(1) {
          grid-column: 1 / 3;
        }
        &:nth-child(2) {
          grid-column: 3 / 5;
        }
      }
      .c-item-user {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span:nth-child(3) {
          font-weight: bold;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .ui-gridTable-body {
      &-item {
        &:nth-child(1) {
          grid-column: 1 / 4;
        }
        &:nth-child(2) {
          grid-column: 4 / 5;
        }
      }
    }
  }
`
