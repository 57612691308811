import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading, TableGrid } from 'ui-components'
import { NotFound, Search, Wrapper } from '../../../../components'
import { useSearch } from '../../../../hooks'
import { setDataTitle } from '../../../../redux/reducers'
import { fetchUsers } from '../../../../services/user.service'
import dataArray from './dataTable'
import { UserReportStyle } from './styled'

function UserReport() {
  const [Hidden, setHidden] = useState(false)
  const dispatch = useDispatch()
  const { users, loadingUsers } = useSelector((state) => state.usersApiReducer)
  const { DataSearch, setDataRequest, setDataValue } = useSearch(
    users,
    '',
    'name',
    'courseCode'
  )

  useEffect(() => {
    dispatch(setDataTitle('Reporte por usuario'))
  }, [dispatch])

  function handleChange(e) {
    const targ = e.target
    setDataValue(targ.value)
  }

  useEffect(() => {
    users.length <= 0 && dispatch(fetchUsers())
  }, [dispatch])

  useEffect(() => {
    setDataRequest(users)
  }, [users])

  useEffect(() => {
    DataSearch && DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [DataSearch])

  return (
    <Wrapper>
      <UserReportStyle>
        <Search
          back
          otherSearch={{
            label: 'Curso',
            func: handleChange
          }}
          search={{
            label: 'Nombre...',
            func: handleChange
          }}
        />

        {loadingUsers ? (
          <Loading />
        ) : DataSearch && DataSearch.length > 0 ? (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: 'repeat(3, 1fr) 100px'
              }}
              headContent={[
                { type: 'default', label: 'Nombres' },
                { type: 'default', label: 'Correo' },
                { type: 'default', label: 'Código del curso' },
                { type: 'default', label: 'Ver' }
              ]}
              bodyContent={dataArray(DataSearch)}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound />}
          </>
        ) : DataSearch && DataSearch.length <= 0 ? (
          <NotFound text='No hay usuarios' />
        ) : null}
      </UserReportStyle>
    </Wrapper>
  )
}

export default UserReport
