import styled from 'styled-components'

export const CurtainStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 0 auto !important;
  .c-video {
    width: 100%;
    margin: 2rem 0;
  }
  a {
    width: 100%;
    max-width: 400px;
  }
`
