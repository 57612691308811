import styled from 'styled-components'

export const FooterStyle = styled.footer`
  padding-top: 3rem;
  .c-cont {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    grid-template-rows: repeat(4, 1fr);
    background: linear-gradient(
      0deg,
      var(--primaryColorDark) 50%,
      rgba(255, 255, 255, 0) 50%
    );
    align-items: center;
    height: 100px;
    &-create {
      grid-column: 2 / 3;
      grid-row: 2 / 4;
      display: flex;
      align-items: center;
      justify-content: center;
      .c-btn-footer {
        min-width: 60px;
        min-height: 60px;
        box-shadow: none;
        border: 5px solid white;
        span {
          display: none;
        }
        i {
          margin: 0%;
          font-size: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &-back {
      grid-row: 3 / 5;
      .button-icon {
        background: none;
        color: lightgray;
        min-width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: none;
        i {
          font-size: 2.5rem;
          margin: 0;
          width: max-content;
          height: max-content;
        }
      }
    }
  }
  .InputItem-control {
    width: 200px;
  }
`
