import { Icon, Link } from 'ui-components'
import { ButtonModal } from '../../../components'

export default function dataArray(
  getDataTable,
  visible,
  cases = [],
  handleNotify
) {
  const formarDate = (value) => {
    const date = new Date(value)
    const months = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ]
    const formatDate = `
      ${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`

    return formatDate
  }

  const getNameCaseById = (id) => {
    if (cases.length > 0 && cases) {
      const getElemCase = cases.find((elem) => elem.id === id)
      return getElemCase?.title
    }
    return ''
  }

  const data = getDataTable.map((item, idx) => {
    if (visible || item.status_id === 1) {
      return [
        [
          <div key={`${item.id}_name`} className='c-item-record'>
            <span className='c-item-name'>{item.name}</span>
            <figure>
              <img src={item.image} alt={item.name} />
            </figure>
            <span>
              <Icon nameIcon='event' />
              {formarDate(item.date)}
            </span>
          </div>
        ],
        [
          <div key={`${item.id}_user`} className='c-item-user'>
            <span>
              <Icon nameIcon='person' />
              {item.user_name}
            </span>
            <span>{getNameCaseById(item.case_id)}</span>
          </div>
        ],
        [
          <div key={`${item.id}_btns`} className='c-item-btns'>
            {visible && (
              <>
                <ButtonModal
                  payload={{
                    title: `¿Está seguro de ${
                      item.status_id === 1 ? 'inhabilitar' : 'habilitar'
                    } esta grabación?`,
                    idx,
                    recording_id: item.id,
                    status_id: item.status_id
                  }}
                  type={{
                    styled: 'primary-icon',
                    addClass: 'button-icon',
                    icon: item.status_id === 1 ? 'visibility' : 'visibility_off'
                  }}
                  target='modalEnable'
                  key={`${item.id}_visibility`}
                />
                <ButtonModal
                  type={{
                    styled: 'primary-icon',
                    addClass: 'button-icon',
                    icon: 'delete'
                  }}
                  target='modalAlert'
                  payload={{
                    title: '¿Está seguro de eliminar esta grabación?',
                    id: item.record_id,
                    idx
                  }}
                />
              </>
            )}
            <button
              onClick={() => handleNotify(item.id)}
              onAuxClick={() => handleNotify(item.id)}
              className='button-func'
              title='Descargar'
            >
              <Link
                key={`${item.id}_download`}
                href={item.path}
                addClass='button-icon'
                icon='vertical_align_bottom'
                target='_blank'
                download
              />
            </button>
          </div>
        ]
      ]
    }
    return false
  })

  let dataFilter = data.filter((data) => data !== false)
  dataFilter === undefined && (dataFilter = [])

  return dataFilter
}
