import { ButtonModal } from '../../../../components'

export default function dataArray(getDataTable) {
  const data = getDataTable.map((item, index) => [
    [
      <span key={`${item.id}_rol`}>
        <b>Acción: </b>
        {item.authorAction}
      </span>
    ],
    [
      <span key={`${item.id}_name`}>
        <b>Autor: </b>
        {item.author}
      </span>
    ],
    [
      <span key={`${item.id}_rol`}>
        <b>Fecha: </b>
        {item.created_at}
      </span>
    ],
    [
      <ButtonModal
        type={{
          styled: 'primary-icon',
          addClass: 'button-icon',
          icon: 'visibility'
        }}
        payload={{
          title: 'Cambio de información',
          userId: item.id,
          idx: index,
          before: item.beforeData,
          after: item.afterData,
          action: item.authorAction
        }}
        title='Ver'
        target='ModalAuditView'
        key={`${item.id}_visibility`}
      />
    ]
  ])
  return data
}
