import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon, InputField, Loading, Select, Text } from 'ui-components'
import {
  getOptionsCase,
  getOptionsCategory,
  getOptionsUser
} from '../../services/formCreateRoom.service'
import { fetchRoles } from '../../services/role.service'
import { listRoomRoles } from '../../services/roleRoom.service'
import { FormRoomStyle } from './styled'

function FormRoom({ submitFn }) {
  const dispatch = useDispatch()
  const { newResponse } = useSelector((state) => state.utilitiesReducer)
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const [dataSelect, setDataSelect] = useState({})
  const [dataTemp, setDataTemp] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [dataDate, setDataDate] = useState({
    date: '',
    time: ''
  })
  const { optionsUser, optionsCategory, optionsCases } = useSelector(
    (state) => state.formCreateRoomReducer
  )
  const [dataForm, setDataForm] = useState({
    name: '',
    welcome: '',
    duration: 0,
    caseId: 0,
    startTime: '',
    authorName: `${profile.name} ${profile.lastName}`,
    authorEmail: profile.email
  })

  const handleInput = ({ target }) => {
    const { name, value } = target
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const handleInputDate = ({ target }) => {
    const { name, value } = target
    setDataDate({
      ...dataDate,
      [name]: value
    })
  }

  useEffect(() => {
    if (dataDate) {
      // const dataDay = dataDate.date?.replace('-', '')
      // const dataTime = dataDate.time?.replace(':', '')
      // const data = `${dataDay}T${dataTime}00`
      const data = new Date(`${dataDate.date}T${dataDate.time}`).toJSON()
      setDataForm({
        ...dataForm,
        startTime: data,
        date: dataDate.date,
        time: dataDate.time
      })
    }
  }, [dataDate])
  const handleSelect = ({ target }) => {
    const { name, value } = target
    setDataSelect({
      ...dataSelect,
      [name]: Number(value)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setErrorMessage('')
    if (validateDate(dataForm.startTime)) {
      submitFn(dataForm)
    } else {
      setErrorMessage(
        'No se pueden crear salas que inicien en menos de 15 minutos.'
      )
    }
  }

  const validateDate = (value) => {
    const startTime = new Date(value).getTime()
    const currentTime = new Date().getTime()
    const minute = (startTime - currentTime) / 60000 // min as milliseconds
    return minute > 15
  }

  useEffect(() => {
    setDataTemp(optionsUser)
  }, [optionsUser])

  useEffect(() => {
    const data = []
    let newArray = dataTemp
    if (dataSelect.rolUserId) {
      newArray = newArray.filter((user) => user.roleId === dataSelect.rolUserId)
    }
    newArray.map((item) => {
      const option = { id: item.id, value: `${item.name} ${item.lastName}` }
      data.push(option)
      return true
    })
  }, [dataSelect.rolUserId, dataTemp])

  useEffect(() => {
    dispatch(getOptionsUser({ profile }))
  }, [dispatch, profile])

  useEffect(() => {
    dispatch(getOptionsCase(dataSelect.categoryId))
  }, [dispatch, dataSelect.categoryId])

  useEffect(() => {
    dispatch(getOptionsCategory())
    dispatch(listRoomRoles())
    dispatch(fetchRoles())
  }, [dispatch])

  return (
    <FormRoomStyle>
      <Text type='h5' addClass='c-title-create'>
        <Icon nameIcon='add' /> Crear sala
      </Text>
      {newResponse.state !== 'idle' ? (
        newResponse.state === 'loading' ? (
          <Loading />
        ) : (
          <div className='c-contInfo-modal'>
            <Text type='h6'> {newResponse.message} </Text>{' '}
          </div>
        )
      ) : (
        <form onSubmit={handleSubmit}>
          <div className='c-itemConts'>
            <div className='ItemCont'>
              <InputField
                type='text'
                label='Nombre'
                addClass='InputItem'
                name='name'
                value=''
                onChange={handleInput}
                required={true}
                minlength='4'
              />
            </div>
            <div className='ItemCont'>
              <InputField
                type='number'
                label='Duración en minutos'
                addClass='InputItem'
                name='duration'
                value=''
                onChange={handleInput}
              />
            </div>
            <div className='ItemCont'>
              <InputField
                type='text'
                label='Bienvenida'
                addClass='InputItem'
                name='welcome'
                value=''
                onChange={handleInput}
                required={true}
                minlength='5'
              />
            </div>
            <div className='ItemCont'>
              <InputField
                type='date'
                addClass='InputItem'
                name='date'
                value=''
                onChange={handleInputDate}
                required={true}
              />
            </div>
            <div className='ItemCont'>
              <InputField
                type='time'
                addClass='InputItem'
                name='time'
                value=''
                onChange={handleInputDate}
                required={true}
              />
            </div>

            <div className='ItemCont'>
              <Text type='h6'>Seleccione un caso</Text>
              <Select
                options={optionsCategory}
                addClass='SelectItem'
                name='categoryId'
                nameIcon='keyboard_arrow_down'
                placeholder='Categorías'
                onChange={handleSelect}
                value
                required={true}
              />
            </div>
            <div className='ItemCont'>
              <Select
                options={optionsCases}
                addClass='SelectItem'
                name='caseId'
                nameIcon='keyboard_arrow_down'
                placeholder='Casos'
                onChange={handleInput}
                value
                disabled={!dataSelect.categoryId}
                required={true}
              />
            </div>
          </div>
          {errorMessage && <Text type='h6'> {errorMessage} </Text>}
          <Button
            label='Guardar'
            styled='secondary'
            addClass='btnItem'
            type='submit'
          ></Button>
          <br />
        </form>
      )}
    </FormRoomStyle>
  )
}

export { FormRoom }
