import { PageNotFoundStyle } from './styled'

function PageNotFound() {
  return (
    <PageNotFoundStyle>
      <figure className='c-cont'>
        <img src='assets/images/alert.png' alt='pagina no encontrada' />
      </figure>
      {/* <section>
        <img src='assets/images/content.png' alt='pagina no encontrada' />
      </section> */}
    </PageNotFoundStyle>
  )
}

export default PageNotFound
