import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Text } from 'ui-components'
import { listGuestRoom } from '../../services/room.service'
import { ListGuestStyle } from './styled'

function ListGuest({ onClick }) {
  const dispatch = useDispatch()
  const { listGuest } = useSelector((state) => state.roomApiReducer)
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const { id } = dataTemp

  useEffect(() => {
    id && dispatch(listGuestRoom({ id }))
  }, [dataTemp])

  return (
    <ListGuestStyle>
      <Text type='h5' addClass='c-title-create'>
        Lista usuarios
      </Text>

      <div className='c-cont'>
        <div className='ItemCont-user'>
          {listGuest.length > 0 && (
            <>
              {listGuest[0]?.users.length > 0 &&
                listGuest[0]?.users.map((item, idx) => (
                  <div key={`item-${idx}`} className='ItemCont-user-item'>
                    <Text type='p'>
                      {item.user_name} {item.user_lastName}
                    </Text>
                    <Text type='p'>{item.role_name}</Text>
                  </div>
                ))}

              {listGuest[0]?.guests.length > 0 && (
                <>
                  <br />
                  <Text type='h6'>Invitados</Text>
                  {listGuest[0]?.guests.map((item, idx) => (
                    <div key={`item-${idx}`} className='ItemCont-user-item'>
                      <Text type='p'>{item.email}</Text>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
        <Button
          label='Cerrar'
          styled='secondary'
          addClass='btnItem'
          type='submit'
          onClick={onClick}
        ></Button>
      </div>
    </ListGuestStyle>
  )
}

export { ListGuest }
