import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Conditions, Modal, Search, Wrapper } from '../../../components'
import { setDataTitle } from '../../../redux/reducers'
import { dataFake } from './dataFake'
import { HelpStyle } from './style'

function Help() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setDataTitle('Ayuda'))
  }, [dispatch])

  return (
    <Wrapper>
      <HelpStyle>
        <Search />
        <div className='c-contCards'>
          {dataFake.map((item, idx) => (
            <div key={`${idx}-help`} className='c-contCards-item'>
              <Card options={item} />
            </div>
          ))}
        </div>
      </HelpStyle>

      <Modal id='ModalConsent' size='large'>
        <div className='c-contModal'>
          <Conditions />
        </div>
      </Modal>
    </Wrapper>
  )
}

export default Help
