import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'ui-components'
import { useValidateRole } from '../../hooks/ValidateRole/useValidateRole'
import { ButtonModal } from '../ButtonModal'
import { FooterStyle } from './style'

function Footer({ states }) {
  const { hiddenMedia } = useSelector((state) => state.utilitiesReducer)
  const { profile } = useSelector((state) => state.LoginApiReducer)

  const { goBack } = useHistory()

  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  return (
    <FooterStyle>
      {!hiddenMedia && (
        <div className='c-cont'>
          <div className='c-cont-back'>
            <Button
              onClick={() => goBack()}
              addClass='button-icon'
              title='Regresar'
              icon='keyboard_arrow_left'
            />
          </div>
          {states.create && visible ? (
            <div className='c-cont-create'>
              <ButtonModal
                target='modalCrear'
                type={{
                  styled: 'secondary-icon',
                  icon: 'add',
                  addClass: 'c-btn-footer'
                }}
              />
            </div>
          ) : null}
        </div>
      )}
    </FooterStyle>
  )
}

export { Footer }
