import styled from 'styled-components'

export const ModalStyle = styled.div`
  &.is-visible {
    .c-modal-overlay {
      display: block;
    }

    .c-modal {
      top: 10vh;
    }
  }
  .c-modal {
    max-width: var(--maxWidthModal);
    margin: auto;
    box-shadow: var(--boxModal);
    z-index: 5;
    position: fixed;
    transition: 0.4s;
    top: -100vh;
    left: 10vw;
    right: 10vw;
    background-color: var(--backgroundModal);
    padding: 0;
    max-height: 80vh;
    text-align: left;
    & > i {
      background: var(--secondaryColor);
      color: white;
      border-radius: 50%;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      margin-bottom: 1rem;
      position: absolute;
      right: -17px;
      top: -7px;
    }
  }
  .c-modal-overlay {
    position: fixed;
    background-color: var(--colorOverlay);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    z-index: 2;
    display: none;
  }

  .c-modal[data-size='small'] {
    height: max-content;
    max-width: 400px;
  }
  .c-modal[data-size='medium'] {
    max-width: 700px;
  }
  .c-modal[data-size='large'] {
    max-width: 1000px;
  }

  .category {
    .c-itemConts {
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      gap: 5px;
      @media screen and (max-width: 720px) {
        display: block;
      }
      .ItemCont:first-child {
        grid-column: 1 / 3;
      }
    }
    .ItemCont {
      label[styled='secondary'] {
        margin: 0;
      }
    }
  }

  .document {
    .c-itemConts {
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      .ItemCont:first-child {
        grid-column: 1 / 3;
      }
    }
  }

  .documents {
    .c-itemConts {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 0 1rem;
      .ItemCont {
        &:nth-child(1) {
          grid-column: 1 / 4;
        }
        &:nth-child(2) {
          grid-column: 4 / 5;
        }
        &:nth-child(3) {
          grid-column: 1 / 4;
        }
      }
    }
  }

  .c-contModal {
    padding: 1rem 0 2rem;
    & > div {
      padding: 1.5rem 1.5rem 0 1.5rem;
      overflow: auto;
      max-height: 70vh;
    }
  }

  .help {
    .c-itemConts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .ItemCont:first-child {
        grid-column: 1 / 4;
      }
    }
  }
`
