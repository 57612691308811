import { useState } from 'react'
import {
  Button,
  InputAction,
  InputField,
  Select,
  Textarea
} from 'ui-components'
import { FormStyle } from './styled'

// TODO:Actualizar estructure
function Form({ structure, children, submitFn }) {
  const [dataForm, setDataForm] = useState()
  const [isTouched, setIsTouched] = useState(true)

  const handleInput = (event, _value = 'value') => {
    const name = event.target.name
    setIsTouched(false)

    setDataForm({
      ...dataForm,
      [name]: event.target[_value]
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    submitFn(dataForm)
  }

  return (
    <FormStyle>
      <form className='FormCont' onSubmit={handleSubmit}>
        <div className='c-itemConts'>
          {structure &&
            structure.map((elem) => (
              <div className='ItemCont' key={elem.key}>
                {elem.type === 'select' ? (
                  <Select
                    options={elem.options}
                    name={elem.name}
                    nameIcon='keyboard_arrow_down'
                    placeholder={elem.label}
                    addClass='SelectItem'
                    value={elem.value}
                    onChange={handleInput}
                    required={true}
                  />
                ) : elem.type === 'textarea' ? (
                  <Textarea
                    placeholder={elem.label}
                    rows={elem.rows}
                    name={elem.name}
                    value={elem.value}
                    onChange={handleInput}
                    minWords={elem.minWords}
                    required={true}
                    className='c-textarea'
                  />
                ) : elem.type === 'file' ? (
                  <InputAction
                    styled='secondary'
                    type={elem.type}
                    label={elem.label}
                    icon={elem.dataIcon}
                    addClass='InputItem-control'
                    name={elem.name}
                    value={elem.value}
                    required={elem.required}
                    onChange={(event) => handleInput(event, 'files')}
                  />
                ) : (
                  <InputField
                    type={elem.type}
                    label={elem.label}
                    addClass='InputItem'
                    name={elem.name}
                    value={elem.value}
                    onChange={handleInput}
                    required={true}
                    minlength={elem.minWords}
                  />
                )}
              </div>
            ))}
        </div>
        {children}
        <Button
          label='Guardar'
          styled='secondary'
          addClass='btnItem'
          disabled={isTouched}
          type='submit'
        ></Button>
      </form>
    </FormStyle>
  )
}

export { Form }
