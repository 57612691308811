import {
  createFileCases,
  deleteFileCases,
  listFilesCases,
  updateFileCases
} from '../../../services/fileCases.service'

export const fileCasesActions = {
  [listFilesCases.pending]: (state) => {
    state.loadingFile = true
  },
  [listFilesCases.fulfilled]: (state, { payload }) => {
    payload.data?.sort(function (a, b) {
      if (a.id < b.id) {
        return 1
      }
      if (a.id > b.id) {
        return -1
      }
      return 0
    })
    state.filesCases = payload.data
    state.loadingFile = false
  },
  [listFilesCases.rejected]: (state, action) => {
    state.error = action.error
    state.loadingFile = false
  },

  [createFileCases.fulfilled]: (state, { payload }) => {
    state.filesCases.unshift(payload)
  },

  [updateFileCases.fulfilled]: (state, { payload }) => {
    const { data, idx } = payload
    state.filesCases.splice(idx, 1, data)
  },

  [deleteFileCases.fulfilled]: (state, { payload }) => {
    const { index } = payload
    state.filesCases.splice(index, 1)
  }
}
