import styled from 'styled-components'

export const ConditionsStyled = styled.div`
  .c-title {
    margin: 0 0 1.2rem 0;
  }
  [text-align='justify'] {
    text-align: justify;
  }
`
