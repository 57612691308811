import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading, TableGrid } from 'ui-components'
import {
  Consent,
  Modal,
  ModalAlert,
  NotFound,
  Search,
  Wrapper
} from '../../../components'
import { useFilter, useSearch } from '../../../hooks'
import { useValidateRole } from '../../../hooks/ValidateRole/useValidateRole'
import { setDataTitle } from '../../../redux/reducers'
import { listCases } from '../../../services/case.service'
import {
  deleteRecording,
  listRecording,
  recordingNotify,
  updateRecording
} from '../../../services/recording.service'
import dataArray from './dataTable'
import { RecordingsStyle } from './styled'

function Recordings() {
  const [Data, setData] = useState([])
  const [optionCase, setOptionCase] = useState([])
  const [Hidden, setHidden] = useState(false)
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { cases } = useSelector((state) => state.caseAPIReducer)
  const { recordingAcceptTerms } = useSelector(
    (state) => state.recordingApiReducer
  )
  const { setRolId, visible } = useValidateRole()
  const [dataTable, setDataTable] = useState(Data)
  const { recording, loadingRecording } = useSelector(
    (state) => state.recordingApiReducer
  )
  const { DataFilter, setDataValueFilter } = useFilter(recording, '', 'case_id')
  const { DataSearch, setDataRequest, setDataValue } = useSearch(DataFilter, '')

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  function handleChange({ target }) {
    const { value } = target
    target.type === 'text'
      ? setDataValue(value)
      : setDataValueFilter(Number(value))
  }

  function handleNotify(item) {
    dispatch(recordingNotify(item))
  }
  useEffect(() => {
    if (Data.length > 0) {
      const dataTable = dataArray(Data, visible, cases, handleNotify)
      setDataTable(dataTable)
    }
  }, [Data, visible, cases])

  useEffect(() => {
    dispatch(setDataTitle('Lista de Grabaciones'))
    recording.length <= 0 && dispatch(listRecording())
    dispatch(listCases())
  }, [dispatch])

  useEffect(() => {
    setData(DataSearch)
    setDataRequest(DataFilter)
    DataSearch && DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [DataSearch, setDataRequest])

  useEffect(() => {
    const data = []
    cases?.map((item) => {
      const option = {
        id: item.id,
        value: item.title
      }
      data.push(option)
      return true
    })
    setOptionCase(data)
  }, [cases])

  useEffect(() => {
    const modalElm = document.querySelector(`#consent`)
    if (
      profile.acceptTerms.recordingsTerms === 0 &&
      recordingAcceptTerms === false
    ) {
      modalElm && modalElm.classList.toggle('is-visible')
    }
  }, [])

  async function handleDeleteRecording(params) {
    await dispatch(deleteRecording(params))
  }

  const handleUpdate = async ({ status_id, recording_id, idx }) => {
    if (status_id === 1) {
      status_id = 2
    } else {
      status_id = 1
    }
    const data = {
      status_id,
      recording_id
    }
    await dispatch(updateRecording({ data, idx }))
  }

  return (
    <Wrapper>
      <RecordingsStyle>
        <Search
          options={{
            opts: optionCase,
            label: 'Caso',
            func: handleChange,
            type: 'default'
          }}
          search={{
            label: 'Nombre sala...',
            func: handleChange
          }}
        />
        {loadingRecording ? (
          <Loading />
        ) : dataTable && dataTable.length > 0 ? (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: 'repeat(5, 1fr) 150px'
              }}
              headContent={[
                { type: 'default', label: 'Nombre sala ' },
                { type: 'default', label: 'Imagen miniatura' },
                { type: 'default', label: ' Fecha' },
                { type: 'default', label: 'Usuario' },
                { type: 'default', label: 'Caso' },
                { type: 'default', label: '' }
              ]}
              bodyContent={dataTable}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound text='Este caso no cuenta con grabaciones.' />}
          </>
        ) : dataTable && dataTable.length <= 0 ? (
          <NotFound text='No hay grabaciones activas.' />
        ) : null}
      </RecordingsStyle>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteRecording} />
      </Modal>
      <Modal id='modalEnable'>
        <ModalAlert handleConfirm={handleUpdate} />
      </Modal>

      <Modal id='consent' size='large' close>
        <ModalAlert>
          <Consent btnNext={false} recordingTerm />
        </ModalAlert>

        {/* <div className='c-contModal consent'>
      </div> */}
      </Modal>
    </Wrapper>
  )
}

export default Recordings
