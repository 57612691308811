import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, post, put } from './request.service'

const className = 'case'

const listCases = createAsyncThunk('cases/list', async () => {
  const response = await get(className)

  return { data: response.data.body }
})

const createCase = createAsyncThunk(
  'cases/create',
  async (data, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await post(className, data)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El caso ha sido creado correctamente'
        })
      )
      return { data: response.data.body }
    } catch (error) {
      dispatch(
        setNewResponse({ state: 'error', message: 'El caso no fue creado.' })
      )
      throw error
    }
  }
)

const updateCase = createAsyncThunk(
  'cases/update',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await put(`${className}/${params.data.id}`, params.data)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El caso ha sido editado correctamente'
        })
      )
      return { data: response.data.body, id: params.data.id }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'El caso no fue editado correctamente'
        })
      )
      throw error
    }
  }
)

const deleteCase = createAsyncThunk(
  'cases/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await destroy(`${className}/${params.id}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El caso ha sido eliminado correctamente'
        })
      )
      return { response, id: params.id }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: `${error}`
        })
      )
      throw error
    }
  }
)

export { listCases, createCase, updateCase, deleteCase }
