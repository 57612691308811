import styled from 'styled-components'

export const IconStyle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    content: '';
    width: 52px;
    height: 30px;
    display: block;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  &[data-icon='ayuda'] {
    &::after {
      background-image: url('./assets/images/icons/ayuda.svg');
    }
  }

  &[data-icon='bancoCasos'] {
    &::after {
      background-image: url('./assets/images/icons/bancoCasos.svg');
    }
  }

  &[data-icon='gestionDocumentos'] {
    &::after {
      background-image: url('./assets/images/icons/gestionDocumentos.svg');
    }
  }

  &[data-icon='grabaciones'] {
    &::after {
      background-image: url('./assets/images/icons/grabaciones.svg');
    }
  }

  &[data-icon='materialApoyo'] {
    &::after {
      background-image: url('./assets/images/icons/materialApoyo.svg');
    }
  }

  &[data-icon='orientacion'] {
    &::after {
      background-image: url('./assets/images/icons/orientacion.svg');
    }
  }

  &[data-icon='reportes'] {
    &::after {
      background-image: url('./assets/images/icons/reportes.svg');
    }
  }

  &[data-icon='ssl'] {
    &::after {
      background-image: url('./assets/images/icons/ssl.svg');
    }
  }

  &[data-icon='usuarios'] {
    &::after {
      background-image: url('./assets/images/icons/usuarios.svg');
    }
  }

  &[data-icon='protocolos'] {
    &::after {
      background-image: url('./assets/images/icons/protocolos.svg');
    }
  }

  &[data-icon='notificacion'] {
    &::after {
      background-image: url('./assets/images/icons/notificacion.svg');
    }
  }
  &[data-icon='notificacionActiva'] {
    &::after {
      background-image: url('./assets/images/icons/notificacionActiva.svg');
    }
  }
  &[data-icon='logout'] {
    &::after {
      background-image: url('./assets/images/icons/logout.svg');
    }
  }

  @media screen and (max-width: 1180px) {
    &[data-icon='bancoCasos'] {
      &::after {
        background-image: url('./assets/images/icons/bancoCasosv.svg');
      }
    }

    &[data-icon='gestionDocumentos'] {
      &::after {
        background-image: url('./assets/images/icons/gestionDocumentosv.svg');
      }
    }

    &[data-icon='grabaciones'] {
      &::after {
        background-image: url('./assets/images/icons/grabacionesv.svg');
      }
    }

    &[data-icon='materialApoyo'] {
      &::after {
        background-image: url('./assets/images/icons/materialApoyov.svg');
      }
    }

    &[data-icon='orientacion'] {
      &::after {
        background-image: url('./assets/images/icons/orientacionv.svg');
      }
    }

    &[data-icon='reportes'] {
      &::after {
        background-image: url('./assets/images/icons/reportesv.svg');
      }
    }

    &[data-icon='ssl'] {
      &::after {
        background-image: url('./assets/images/icons/sslv.svg');
      }
    }

    &[data-icon='usuarios'] {
      &::after {
        background-image: url('./assets/images/icons/usuariosv.svg');
      }
    }

    &[data-icon='protocolos'] {
      &::after {
        background-image: url('./assets/images/icons/protocolosv.svg');
      }
    }
    &[data-icon='notificacion'] {
      &::after {
        background-image: url('./assets/images/icons/notificacion.svg');
      }
    }
    &[data-icon='logout'] {
      &::after {
        background-image: url('./assets/images/icons/logoutv.svg');
      }
    }
  }
`
