import { ButtonModal } from '../../../components'
// TODO: pasar handleClick
export default function dataArray(getDataTable) {
  const data = getDataTable.map((item, index) => [
    [
      <div key={`${item.id}_name`} className='c-item-user'>
        <span>{`${item.name} ${item.lastName}`}</span>
        <span>{item.email}</span>
        <span>{item.role}</span>
      </div>
    ],
    [
      <ButtonModal
        type={{
          styled: 'primary-icon',
          addClass: 'button-icon',
          icon: 'delete'
        }}
        payload={{
          title: '¿Está seguro de eliminar este usuario?',
          userId: item.id,
          idx: index
        }}
        target='modalAlert'
        key={`${item.id}_delete`}
      />
    ]
  ])

  return data
}
