import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Button, Text } from 'ui-components'
import { Modal, Search, Wrapper } from '../../../../components'
import { setDataTitle } from '../../../../redux/reducers'
import {
  sendNotification,
  subscription
} from '../../../../services/notification.service'
import { listRoom } from '../../../../services/room.service'
import { RoomStyle } from './styled'

function Room() {
  const { params } = useRouteMatch()
  const dispatch = useDispatch()
  const [url, setUrl] = useState({})
  const { room } = useSelector((state) => state.roomApiReducer)
  const { profile } = useSelector((state) => state.LoginApiReducer)

  useEffect(() => {
    dispatch(listRoom())
  }, [dispatch])

  useEffect(() => {
    const newRoom = room.find((room) => room.roomId === Number(params.idroom))
    newRoom && dispatch(setDataTitle(`Sala ${newRoom.roomName}`))
    setUrl(newRoom)
  }, [params.idroom, room, dispatch])

  useEffect(() => {
    const modalElm = document.querySelector(`#orientation`)
    modalElm && modalElm.classList.toggle('is-visible')
  }, [])

  useEffect(() => {
    if (profile.r.i !== 'pnWt') {
      subscription()
      Notification.onshow = function () {
        // console.log('easy!')
      }
    }
  }, [profile])

  const handleSendNotification = async () => {
    await dispatch(sendNotification(params.idroom))
  }

  return (
    <Wrapper>
      <RoomStyle>
        <Search />
        {url && (
          <div className='c-content'>
            <div className='cont-video'>
              <div className='fieldContent'>
                <video
                  className='cortinillaIframe'
                  src='./assets/videos/cortinilla.mp4'
                  muted={true}
                  autoPlay={true}
                  loop={true}
                />
                <div className='contIframe'>
                  <iframe
                    title={url.roomName}
                    src={url.url}
                    style={{ width: '100%', height: '100%' }}
                    allow='camera; microphone; display-capture'
                  ></iframe>
                </div>
              </div>
            </div>
            {profile.r.i === 'pnWt' && (
              <Button
                styled='secondary-icon'
                icon='error_outline'
                addClass='btn-alert'
                onClick={handleSendNotification}
              />
            )}
          </div>
        )}

        <Modal id='orientation'>
          <Text type='h6' addClass='c-title-video'>
            {/* <Icon nameIcon='camera' /> */}
            Orientación al estudiante
          </Text>
          <div className='c-contInfo-modal'>
            <Text type='p'>
              &quot;Estimado estudiante, si requiere algún tipo de apoyo de tipo
              psicológico para usted, producto de las emociones, sentimientos,
              pensamientos o reflexiones, generadas en el caso trabajado en la
              sesión de hoy, le invitamos a visitar el espacio de orientación al
              estudiante, con el fin de acceder a rutas de apoyo para su
              situación&quot;
            </Text>
            <a
              href='https://view.genial.ly/60566608dda7ec0cfc90147b/video-presentation-primeros-auxilios-psicologicos'
              target='blank'
            >
              <Button
                label='Video Primeros Auxilios Psicológicos'
                styled='secondary'
              />
            </a>
          </div>
        </Modal>
      </RoomStyle>
    </Wrapper>
  )
}

export default Room
