export const dataFake = [
  {
    helpId: '1',
    title: 'Términos y condiciones',
    image: './assets/images/terminosCondiciones.png',
    action: 'ModalConsent'
  },
  {
    helpId: '2',
    title: 'Orientación para el estudiante',
    image: './assets/images/orientacionEstudiante.png',
    navigation: '/orientacion-estudiante'
  },
  {
    helpId: '3',
    title: 'Protocolos',
    image: './assets/images/protocolos.png',
    navigation: '/protocolos'
  },
  {
    helpId: '4',
    title: 'Material de apoyo',
    image: './assets/images/materialApoyo.png',
    navigation: '/material-apoyo'
  },
  {
    helpId: '5',
    title: 'Grabaciones',
    image: './assets/images/grabaciones.png',
    navigation: '/grabaciones'
  }
]
