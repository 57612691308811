import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, patch } from './request.service'

const nameCollection = 'user'

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await destroy(`${nameCollection}/${params.userId}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'el usuario ha sido eliminado correctamente.'
        })
      )
      return { response: response.data, params }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: `${error}`
        })
      )
      throw error
    }
  }
)

export const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (params, { getState }) => {
    const { profile } = getState().LoginApiReducer
    const rolId = profile.r.i === 'pnWt'
    if (rolId) {
      return { response: [] }
    } else {
      const response = await get(nameCollection)
      return { response: response.data.body }
    }
  }
)

export const postTerms = createAsyncThunk('user/getTerms', async () => {
  // const resolve = await patch(`recording/updateTerms`)
  const response = await patch(`${nameCollection}/acceptTerms`)
  // console.log('resolve', resolve)
  console.log('postTerms', response)
  return response
})
