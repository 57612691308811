import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import { Button, Loading } from 'ui-components'
import { Consent, Wrapper } from '../../../../components'
import { setDataTitle } from '../../../../redux/reducers'
import { ConsentStyle } from './styled'

function ConsentRoom() {
  const { params } = useRouteMatch()
  const dispatch = useDispatch()
  const { loadingRoom, roomAcceptTerms } = useSelector(
    (state) => state.roomApiReducer
  )

  useEffect(() => dispatch(setDataTitle('')))

  return (
    <Wrapper>
      <ConsentStyle>
        <Consent />
        {loadingRoom ? (
          <Loading />
        ) : (
          <Link
            to={`/lista-salas/sala/${params.idroom}`}
            disabled={!roomAcceptTerms}
          >
            <Button
              disabled={!roomAcceptTerms}
              label='continuar'
              addClass='button-continue'
              styled='secondary'
            ></Button>
          </Link>
        )}
      </ConsentStyle>
    </Wrapper>
  )
}

export default ConsentRoom
