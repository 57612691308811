import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Loading, TableGrid } from 'ui-components'
import {
  Modal,
  ModalAlert,
  NotFound,
  Search,
  Wrapper
} from '../../../components'
import { useFilter, useSearch } from '../../../hooks'
import { setDataTitle } from '../../../redux/reducers'
import { fetchRoles } from '../../../services/role.service'
import { deleteUser, fetchUsers } from '../../../services/user.service'
import dataArray from './dataTable'
import { ManageUserStyle } from './styled'

function ManageUser() {
  const [Hidden, setHidden] = useState(false)
  const { users, loadingUsers } = useSelector((state) => state.usersApiReducer)
  const { roles } = useSelector((state) => state.roleApiReducer)
  const { DataFilter, setDataValueFilter } = useFilter(users, '', 'role')
  const { DataSearch, setDataRequest, setDataValue } = useSearch(
    DataFilter,
    '',
    'name',
    'lastName'
  )
  const history = useHistory()
  const dispatch = useDispatch()

  function handleChange(e) {
    const targ = e.target
    targ.type === 'text'
      ? setDataValue(targ.value)
      : setDataValueFilter(targ.value)
  }

  async function handleDeleteUser(params) {
    await dispatch(deleteUser(params))
  }

  useEffect(() => {
    users.length <= 0 && dispatch(fetchUsers())
    roles.length <= 0 && dispatch(fetchRoles())
  }, [history, dispatch])

  useEffect(() => {
    setDataRequest(DataFilter)
    DataSearch && DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [DataSearch, DataFilter, setDataRequest])

  useEffect(() => {
    dispatch(setDataTitle('Usuarios'))
  }, [dispatch])

  return (
    <Wrapper>
      <ManageUserStyle>
        <Search
          search={{
            label: 'Nombre...',
            func: handleChange
          }}
          options={{
            opts: roles,
            label: 'Rol',
            func: handleChange
          }}
        />
        {loadingUsers ? (
          <Loading />
        ) : DataSearch && DataSearch.length > 0 ? (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: 'repeat(3, 1fr) 100px'
              }}
              headContent={[
                { type: 'default', label: 'Nombres' },
                { type: 'default', label: 'Correo' },
                { type: 'default', label: 'Rol' },
                { type: 'default', label: '' },
                { type: 'default', label: '' }
              ]}
              bodyContent={dataArray(DataSearch)}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound />}
          </>
        ) : DataSearch && DataSearch.length <= 0 ? (
          <NotFound text='No hay usuarios' />
        ) : null}
      </ManageUserStyle>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteUser} />
      </Modal>
    </Wrapper>
  )
}

export default ManageUser
