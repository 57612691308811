import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, InputField, Select } from 'ui-components'
import { useValidateRole } from '../../hooks/ValidateRole/useValidateRole'
import { ButtonModal } from '../ButtonModal'
import { Title } from '../Title'
import { SearchStyle } from './styled'
const ExportJsonExcel = require('js-export-excel')

function Search({
  create,
  btns,
  options,
  otheroptions,
  search,
  back,
  searchDate,
  otherSearch,
  exportData,
  download
}) {
  const { hiddenMedia } = useSelector((state) => state.utilitiesReducer)
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { setRolId, visible } = useValidateRole()
  const [disabledDownload, setdisabledDownload] = useState(false)

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    if (exportData) {
      setdisabledDownload(true)
    } else {
      setdisabledDownload(false)
    }
  }, [exportData])

  const downloadReport = () => {
    if (exportData) {
      const toExcel = new ExportJsonExcel(exportData)
      toExcel.saveExcel()
    }
  }

  return (
    <SearchStyle>
      {hiddenMedia && <Title type='h4' back={back} />}
      <div className='c-search-cont'>
        {create && hiddenMedia && visible ? (
          <ButtonModal
            label={create}
            target='modalCrear'
            type={{ styled: 'secondary', icon: 'add' }}
          />
        ) : null}
        {download && (
          <Button
            label='Exportar excel'
            icon='download'
            styled='secondary'
            disabled={!disabledDownload}
            onClick={downloadReport}
          ></Button>
        )}
        {btns &&
          btns.map((btn, idx) => (
            <Button
              key={`${idx}-${btn.label}`}
              label={btn.label}
              icon={btn.icon}
              styled='secondary'
              onClick={btn.func}
            ></Button>
          ))}

        {search && (
          <InputField
            label={search.label}
            type='text'
            dataIcon={{ state: true, nameIcon: 'search' }}
            addClass='InputItem'
            onChange={search.func}
          />
        )}
        {otherSearch && (
          <InputField
            label={otherSearch.label}
            type='text'
            dataIcon={{ state: true, nameIcon: 'search' }}
            addClass='InputItem'
            onChange={otherSearch.func}
          />
        )}
        {searchDate && (
          <InputField
            type='date'
            addClass='InputItem'
            name='date'
            onChange={searchDate.func}
          />
        )}

        {options && (
          <Select
            options={options.opts}
            addClass='SelectItem'
            nameIcon='keyboard_arrow_down'
            placeholder={options.label}
            onChange={options.func}
            type={options.type ? null : 'search'}
            value
          />
        )}
        {otheroptions && (
          <Select
            options={otheroptions.opts}
            addClass='SelectItem'
            nameIcon='keyboard_arrow_down'
            placeholder={otheroptions.label}
            onChange={otheroptions.func}
            type={otheroptions.type ? null : 'search'}
            value
          />
        )}
      </div>
    </SearchStyle>
  )
}

export { Search }
