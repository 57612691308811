import { useSelector } from 'react-redux'
import { Button, Loading, Text } from 'ui-components'
import { ModalAlertStyle } from './styled'

function ModalAlert({ handleConfirm, handleCancel, onClick, children }) {
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const { newResponse } = useSelector((state) => state.utilitiesReducer)
  function handleClick() {
    handleConfirm(dataTemp)
  }

  function aceptAlert(e) {
    handleCancel && handleCancel(e)
  }

  return (
    <ModalAlertStyle aria-label={dataTemp.title}>
      {!children && (
        <Text type='h5' addClass='c-title-delete'>
          {dataTemp.title}
        </Text>
      )}
      {children ? (
        <div className='c-contInfo-child'>{children}</div>
      ) : newResponse.state !== 'idle' ? (
        newResponse.state === 'loading' ? (
          <Loading />
        ) : (
          <div className='c-contInfo-modal'>
            <Text type='h6'> {newResponse.message} </Text>
            {newResponse.link && (
              <>
                <Text type='p'>Enlace: </Text>
                <a
                  href={newResponse.link}
                  target='_blank'
                  label='Enlace de invitación'
                  rel='noreferrer'
                >
                  {newResponse.link}
                </a>
              </>
            )}
            <Button
              label='Aceptar'
              styled='secondary'
              onClick={(e) => {
                aceptAlert(e)
                onClick(e)
              }}
            />
          </div>
        )
      ) : (
        <div className='c-cont-btns'>
          {handleConfirm && (
            <Button
              label='Confirmar'
              onClick={handleClick}
              styled='secondary'
            />
          )}
          <Button
            label={handleConfirm ? 'Cancelar' : 'Aceptar'}
            styled='secondary'
            onClick={handleConfirm && onClick}
          />
        </div>
      )}
    </ModalAlertStyle>
  )
}

export { ModalAlert }
