import styled from 'styled-components'

export const CardStyle = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template: 200px 1fr / 1fr;
  border: 1px solid var(--colorBorderCard);
  box-shadow: var(--boxCard);
  border-radius: 5px;
  .c-card-img {
    position: relative;
    figure {
      width: 100%;
      height: 100%;
      max-width: 100%;
      img {
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .modal-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      background: var(--primaryColorLight);
    }
  }
  .c-card-cont {
    /* display: grid; */
    /* grid-template: max-content 1fr / 1fr; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: white;
    &--title {
      text-align: center;
      h6 {
        margin: 0;
        color: #333;
      }
    }
    &--btn {
      display: flex;
      padding: 10px;
      gap: 10px;
      align-items: center;
      width: 100%;
      .button-modal {
        width: 97%;
      }
      a,
      .c-btnFn {
        width: 97%;
        button {
          width: 100%;
        }
      }
      button {
        width: 50px;
        span {
          pointer-events: none;
        }
      }
    }
  }
`
