import styled from 'styled-components'

export const UserCardStyle = styled.div`
  color: white;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1180px) {
    height: 100%;
    background: var(--primaryColorDark);
    padding: 1.5rem;
    .c-card-img {
      img {
        width: 60px;
      }
    }
  }
`
