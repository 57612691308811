import styled from 'styled-components'

export const PageNotFoundStyle = styled.div`
  /* background-image: url("/assets/images/fondo.jpg"); */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  section {
    /* border: 1px solid black; */
    min-height: 500px;
    width: 100%;
    max-width: 1200px;
    margin: 0.5rem;
    img {
      width: 100%;
    }
  }
  .c-cont {
    max-width: 800px;
    width: 100%;
    img {
      width: 100%;
    }
  }
`
