export const dataCreateForm = {
  category: {
    title: 'Crear categoría',
    label: 'Crear categoría',
    size: 'medium',
    form: [
      {
        key: '1-createCategory',
        label: 'Nombre',
        type: 'text',
        name: 'title',
        value: ''
      },
      {
        key: '2-createCategory',
        label: 'Imagen',
        type: 'file',
        dataIcon: 'upload',
        name: 'file',
        value: ''
      }
    ]
  },

  case: {
    title: 'Crear caso',
    label: 'Agregar caso',
    size: 'large',
    form: [
      {
        key: '1-caseCreate',
        label: 'Título',
        type: 'text',
        name: 'title',
        minWords: 5,
        value: ''
      },
      {
        key: '2-caseCreate',
        label: 'Intenciones de aprendizaje',
        type: 'textarea',
        name: 'learning',
        minWords: 5,
        rows: '5',
        value: ''
      },
      {
        key: '3-caseCreate',
        label: 'Párrafo inicial',
        type: 'textarea',
        name: 'introduction',
        minWords: 5,
        rows: '5',
        value: ''
      },
      {
        key: '4-caseCreate',
        label: 'Contexto del caso',
        type: 'textarea',
        name: 'context',
        minWords: 5,
        rows: '5',
        value: ''
      },
      {
        key: '5-caseCreate',
        label: 'Cuerpo del caso',
        type: 'textarea',
        name: 'body',
        minWords: 5,
        rows: '5',
        value: ''
      }
    ]
  },

  caseDocument: {
    title: 'Agregar documento',
    label: 'Agregar documento',
    form: [
      {
        key: '3-caseDocCreate',
        label: 'Rol',
        type: 'select',
        name: 'roleId',
        options: [
          { value: 'Administrador', id: 1 },
          { value: 'Docente director', id: 2 },
          { value: 'Docente tutor', id: 3 },
          { value: 'Estudiante', id: 4 }
        ]
      },
      {
        key: '2-caseDocCreate',
        label: 'Documento',
        type: 'file',
        name: 'file',
        required: true,
        dataIcon: 'upload'
      }
    ]
  },

  caseCharacter: {
    title: 'Agregar personaje',
    label: 'Agregar personaje',
    size: 'large',
    form: [
      {
        key: '2-caseChartCreate',
        label: 'Características físicas',
        type: 'textarea',
        rows: '5',
        name: 'physical',
        value: '',
        minWords: 5
      },
      {
        key: '3-caseChartCreate',
        label: 'Características psicológicas',
        type: 'textarea',
        rows: '5',
        name: 'psychological',
        value: '',
        minWords: 5
      },
      {
        key: '4-caseChartCreate',
        label:
          '¿Cuáles son sus posibilidades de actuación dentro de la situación planteada?',
        type: 'textarea',
        rows: '5',
        name: 'performance',
        value: '',
        minWords: 5
      },
      {
        key: '5-caseChartCreate',
        label: 'Intereses',
        type: 'textarea',
        rows: '5',
        name: 'interest',
        value: '',
        minWords: 5
      },
      {
        key: '6-caseChartCreate',
        label: 'Papel que juega dentro de la historia',
        type: 'textarea',
        rows: '5',
        name: 'history',
        value: '',
        minWords: 5
      }
    ]
  },

  documents: {
    title: 'Agregar documento',
    label: 'Agregar documento',
    form: [
      {
        key: '3-documentCreate',
        label: 'Agregar archivo',
        type: 'file',
        dataIcon: 'upload',
        name: 'file'
      }
    ]
  },

  room: {
    title: 'Crear sala',
    label: 'Crear sala',
    form: [
      {
        key: '1-roomCreate',
        label: 'Nombre',
        minWords: 5,
        type: 'text'
      },
      {
        key: '2-roomCreate',
        label: 'Bienvenida',
        minWords: 5,
        type: 'text'
      },
      {
        key: '3-roomCreate',
        label: 'Duración',
        minWords: 5,
        type: 'text'
      },
      {
        key: '4-roomCreate',
        label: 'Usuarios',
        minWords: 5,
        type: 'text'
      }
    ]
  },

  help: {
    title: 'Agregar ayuda',
    label: 'Agregar ayuda',
    form: [
      {
        key: '1-helpCreate',
        label: 'Nombre',
        minWords: 5,
        type: 'text'
      },
      {
        key: '2-helpCreate',
        label: 'Imagen',
        type: 'file',
        dataIcon: 'upload'
      },
      {
        key: '3-helpCreate',
        label: 'Documento',
        type: 'file',
        dataIcon: 'upload'
      }
    ]
  },

  orientation: {
    title: 'Crear orientación',
    label: 'Crear orientación',
    size: 'medium',
    form: [
      {
        key: '2-createOrienation',
        label: 'link',
        type: 'url',
        name: 'link',
        minWords: 5,
        value: ''
      },
      {
        key: '1-createOrienation',
        label: 'Título',
        type: 'text',
        minWords: 5,
        name: 'title',
        value: ''
      },
      {
        key: '3-createOrienation',
        label: 'Imagen',
        type: 'file',
        dataIcon: 'upload',
        name: 'file',
        value: ''
      }
    ]
  },
  protocols: {
    title: 'Agregar protocolo',
    label: 'Agregar protocolo',
    size: 'medium',
    form: [
      {
        key: '1-createProtocols',
        label: 'Rol',
        type: 'select',
        name: 'roleId',
        value: '',
        options: [
          { value: 'Administrador', id: 1 },
          { value: 'Docente director', id: 2 },
          { value: 'Docente tutor', id: 3 },
          { value: 'Estudiante', id: 4 }
        ]
      },
      {
        key: '2-createProtocols',
        label: 'Archivos',
        type: 'file',
        dataIcon: 'upload',
        name: 'file',
        value: ''
      }
    ]
  }
}
