import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import * as Cookies from 'js-cookie'
import { post } from './request.service'

const nameCollection = 'auth'
const host = process.env.REACT_APP_API_URL
const prefix = 'cg_'

const login = createAsyncThunk('session/login', async (params) => {
  const response = await post(`${nameCollection}/login`, params)
  const data = response.data.body
  return data
})

const logout = createAsyncThunk('session/logout', async () => {
  window.location.replace('https://campus0b.unad.edu.co/campus/')
  const response = await post(`auth/logout`)
  return response.data.body
})

const loginLTI = createAsyncThunk(
  'session/loginLTI',
  async ({ token, roleId }) => {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      },
      (err) => {
        throw new Error(err.response.data.message)
      }
    )

    if (roleId) {
      const response = await axios.post(
        `${host}/${nameCollection}/getDataLTI`,
        {
          roleId
        }
      )

      const data = response.data.body
      return data
    } else {
      const response = await axios.post(`${host}/${nameCollection}/getDataLTI`)
      const data = response.data.body
      return data
    }
  }
)

const setSessionCookie = (name, token) => {
  // const expireTime = new Date(new Date().getTime() + 5 * 60 * 1000)
  Cookies.remove(`${prefix}${name}`)
  Cookies.set(`${prefix}${name}`, token, { expires: 1 })
}

const deleteSessionCookie = (name) => {
  Cookies.remove(`${prefix}${name}`)
}

const getSessionCookie = (name) => {
  const sessionCookie = Cookies.get(`${prefix}${name}`)
  if (sessionCookie === undefined) {
    return undefined
  } else {
    return sessionCookie
  }
}
export {
  loginLTI,
  logout,
  setSessionCookie,
  getSessionCookie,
  deleteSessionCookie
}
export default login
