import { createSlice } from '@reduxjs/toolkit'
import { inviteRoomActions } from './inviteRoom.actions'
import { roomsActions } from './rooms.actions'

const roomApi = createSlice({
  name: 'roomApi',
  initialState: {
    room: [],
    listGuest: [],
    loadingRoom: false,
    loadingGuest: false,
    roomAcceptTerms: false,
    currentRequestId: undefined,
    error: null
  },

  extraReducers: { ...roomsActions, ...inviteRoomActions }
})

const roomApiReducer = roomApi.reducer

export { roomApiReducer }
