import axios from 'axios'
import {
  deleteSessionCookie,
  getSessionCookie,
  setSessionCookie
} from './session.service'

const host = process.env.REACT_APP_API_URL
const LTI = process.env.REACT_APP_LTI
axios.interceptors.request.use(
  (config) => {
    const token = getSessionCookie('token')
    const refreshToken = getSessionCookie('refreshToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
      config.headers['refresh-token'] = `${refreshToken}`
    }
    return config
  },
  (err) => {
    throw new Error(err.response.data.error)
  }
)

axios.interceptors.response.use(
  function (response) {
    const { headers } = response
    if (headers['x-token']) {
      setSessionCookie('token', headers['x-token'])
    }
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      deleteSessionCookie('token')
      deleteSessionCookie('refreshToken')
      localStorage.clear()
      LTI
        ? window.location.replace('https://campus0b.unad.edu.co/campus/')
        : window.location.replace('/')
    } else {
      throw new Error(error.response.data.error)
    }
  }
)

const post = (endpoint, body) => axios.post(`${host}/${endpoint}`, body)

const get = (endpoint) => axios.get(`${host}/${endpoint}`)

const put = (endpoint, body) => axios.put(`${host}/${endpoint}`, body)

const destroy = (endpoint) => axios.delete(`${host}/${endpoint}`)

const patch = (endpoint, body) => axios.patch(`${host}/${endpoint}`, body)

export { post, get, put, destroy, patch }
