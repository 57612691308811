import styled from 'styled-components'

export const ReportUserSection = styled.div`
  .ui-gridTable-body-item {
    b {
      display: none;
    }
  }
  @media screen and (max-width: 540px) {
    .ui-gridTable-body-item {
      b {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .ui-gridTable-body-row {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: baseline;
    }
    .ui-gridTable-head {
      display: none;
    }
  }
`
