import { useEffect, useState } from 'react'

export default function useSearch(data, value, type, otherType) {
  const [DataSearch, setDataSearch] = useState([])
  const [DataRequest, setDataRequest] = useState(data)
  const [DataValue, setDataValue] = useState(value)
  useEffect(() => {
    data && setDataRequest(data)
  }, [data])

  useEffect(() => {
    if (DataValue !== '') {
      const dataFilterInput = DataRequest.filter((item) => {
        const textName = type
          ? item[type].toUpperCase()
          : item.name.toUpperCase()
        const textValue = DataValue.toUpperCase()
        if (otherType) {
          const otherText =
            item[otherType] != null && item[otherType].toUpperCase()
          const campo = textName + ' ' + otherText
          return campo.indexOf(textValue) > -1
        } else {
          return textName.indexOf(textValue) > -1
        }
      })
      setDataSearch(dataFilterInput)
    } else {
      setDataSearch(DataRequest)
    }
  }, [DataRequest, DataValue])

  return { DataSearch, setDataRequest, setDataValue }
}
