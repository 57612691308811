import { useSelector } from 'react-redux'
import { Text } from 'ui-components'
import { UserCardStyle } from './styled'

function UserCard() {
  const { profile } = useSelector((state) => state.LoginApiReducer)

  return (
    <UserCardStyle>
      <div className='c-card-info'>
        <Text type='p'>
          <b>
            {profile.name} {profile.lastName}
          </b>
          <br />
          <span>{profile.r.n}</span>
        </Text>
      </div>
    </UserCardStyle>
  )
}

export { UserCard }
