import { createSlice } from '@reduxjs/toolkit'
import { protocolsApiActions } from './protocolsApi.actions'

const ProtocolsApi = createSlice({
  name: 'protocolsApi',
  initialState: {
    protocols: [],
    loadingprotocols: false,
    currentRequestId: undefined,
    error: null
  },
  extraReducers: protocolsApiActions
})

const ProtocolsApiReducer = ProtocolsApi.reducer

export { ProtocolsApiReducer }
