import { Icon, Link } from 'ui-components'

export default function dataArray(getDataTable, visible) {
  const formarDate = (value) => {
    const date = new Date(value)
    const months = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ]
    const formatDate = `${date.getDate()}-${
      months[date.getMonth()]
    }-${date.getFullYear()}`

    return formatDate
  }
  const data = getDataTable.map((item, index) => {
    if (visible || item.status_id === 1) {
      return [
        [
          <div key={`${item.id}_name`} className='c-item-record'>
            <span>{item.name}</span>
            <figure>
              <img src={item.image} alt={item.name} />
            </figure>
            <span>
              <Icon nameIcon='schedule' />
              {formarDate(item.date)}
            </span>
          </div>
        ],
        [
          <span className='c-item-user' key={`${item.id}_user`}>
            <span>
              <Icon nameIcon='person' />
              {item.user_name}
            </span>
          </span>
        ],
        [
          <Link
            key={`${item.id}_download`}
            addClass='button-icon'
            href={item.path}
            icon='vertical_align_bottom'
            target='_blank'
            title='Descargar'
            download
          />
        ]
      ]
    }
    return false
  })

  let dataFilter = data.filter((data) => data !== false)
  dataFilter === undefined && (dataFilter = [])
  return dataFilter
}
