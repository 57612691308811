import { createSlice } from '@reduxjs/toolkit'
import { userApiActions } from './usersAPI.actions'

const usersApi = createSlice({
  name: 'usersApi',
  initialState: {
    users: [],
    loadingUsers: false,
    currentRequestId: undefined,
    userAcceptTerms: false,
    userById: [],
    error: null
  },
  reducers: {
    getUserById(state, { payload }) {
      state.userById = state.users.filter(
        (item) => item.id === parseInt(payload)
      )
    }
  },
  extraReducers: userApiActions
})

const usersApiReducer = usersApi.reducer
const { getUserById } = usersApi.actions

export { usersApiReducer, getUserById }
