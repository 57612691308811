import styled from 'styled-components'

export const ModalCreateStyle = styled.div`
  min-height: 130px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  text-align: center;
  justify-content: space-between;
  overflow: hidden;
  .c-title-create {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }
`
