import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, post, put } from './request.service'

const className = 'category'

const listCategories = createAsyncThunk('category/list', async () => {
  const response = await get(className)
  return response
})

const createCategory = createAsyncThunk(
  'category/create',
  async ({ title, file }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const params = new FormData()
      title && params.append('name', title)
      file && params.append('img/category', file[0])

      const response = await post(className, params)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La categoría ha sido creada correctamente.'
        })
      )
      return response.data.body
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'La categoria no fue creada.'
        })
      )
      throw error
    }
  }
)

const updateCategory = createAsyncThunk(
  'category/update',
  async ({ id, title, file, idx }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))

    try {
      const params = new FormData()

      title && params.append('name', title)
      file && params.append('img/category', file[0])

      const response = await put(`${className}/${id}`, params)
      const editCategory = response.data.body
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La categoría ha sido editada correctamente.'
        })
      )
      return { editCategory, idx }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La categoría no ha sido editada correctamente.'
        })
      )
      throw error
    }
  }
)

const deleteCategory = createAsyncThunk(
  'category/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await destroy(`${className}/${params.id}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La categoría ha sido eliminada correctamente.'
        })
      )
      return { params, response }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: `${error}`
        })
      )
      throw error
    }
  }
)

export { listCategories, createCategory, updateCategory, deleteCategory }
