import {
  listActions,
  listAudits,
  listFiles,
  listFilesCases,
  listQuantities,
  listRecordings,
  listSessions,
  listTables,
  reportCourses,
  reportCoursesGroup,
  reportCoursesYears
} from '../../../services/report.service'

const reportActions = {
  [listAudits.pending]: (state) => {
    state.loadingReport = true
  },
  [listAudits.fulfilled]: (state, { payload }) => {
    state.loadingReport = false
    state.report = payload?.data.body
  },
  [listAudits.rejected]: (state, payload) => {
    state.loadingReport = false
    state.errorReport = payload.error.message
  },
  [listTables.fulfilled]: (state, { payload }) => {
    state.reportTables = payload?.data.body
  },
  [listActions.fulfilled]: (state, { payload }) => {
    state.reportListActions = payload?.data.body
  },
  [listQuantities.fulfilled]: (state, { payload }) => {
    state.reportQuantities = payload?.data.body
  },
  [listFiles.pending]: (state, { payload }) => {
    state.loadingReport = true
  },
  [listFiles.fulfilled]: (state, { payload }) => {
    state.loadingReport = false
    state.reportDataUser = payload?.data.body
  },
  [listFilesCases.pending]: (state, { payload }) => {
    state.loadingReport = true
  },
  [listFilesCases.fulfilled]: (state, { payload }) => {
    state.loadingReport = false
    state.reportDataUser = payload?.data.body
  },
  [listRecordings.pending]: (state) => {
    state.loadingReport = true
  },
  [listRecordings.fulfilled]: (state, { payload }) => {
    state.loadingReport = false
    state.reportDataUser = payload?.data.body
  },
  [listSessions.pending]: (state) => {
    state.loadingReport = true
  },
  [listSessions.fulfilled]: (state, { payload }) => {
    state.loadingReport = false
    state.reportDataUser = payload?.data.body
  },
  [reportCourses.pending]: (state) => {
    state.loadingReport = true
  },
  [reportCourses.fulfilled]: (state, { payload }) => {
    state.loadingReport = false
    state.reportDataCourses = payload?.data.body
  },
  [reportCourses.rejected]: (state, { payload }) => {
    state.loadingReport = false
    state.reportDataCourses = undefined
  },
  [reportCoursesGroup.pending]: (state) => {
    state.loadingReportGroup = true
  },
  [reportCoursesGroup.fulfilled]: (state, { payload }) => {
    state.loadingReportGroup = false
    state.reportGroup = payload?.data.body
  },
  [reportCoursesYears.fulfilled]: (state, { payload }) => {
    state.reportGroupYear = payload?.data.body
  }
}

export { reportActions, listTables, listActions }
