import { useDispatch } from 'react-redux'
import { Icon, Link } from 'ui-components'
import { ButtonModal } from '../../../components'
import { notifyDownload } from '../../../services/file.service'

export default function dataArray(getDataTable, visible) {
  const dispatch = useDispatch()
  function handleChange(item) {
    dispatch(notifyDownload(item))
  }
  const { REACT_APP_API_URL } = process.env

  const data = getDataTable.map((item, index) => [
    [
      <div key={`${item.id}_name`} className='c-item-document'>
        <span>{item.name}</span>
        <span>{item.weight} kb</span>
        <span>{item.format.toLowerCase()}</span>
      </div>
    ],
    [
      <div key={`${item.id}_doc`} className='c-item-user'>
        <span key={`${item.id}_doc`}>
          <Icon nameIcon='person'></Icon>
          {item.userName} {item.lastNameUser}
        </span>
        {visible && (
          <ButtonModal
            payload={{
              title: `¿Está seguro de ${
                item.statusId === 1 ? 'inhabilitar' : 'habilitar'
              } este documento?`,
              index,
              fileId: item.id,
              statusId: item.statusId
            }}
            type={{
              styled: 'primary-icon',
              addClass: 'button-icon',
              icon: item.statusId === 1 ? 'visibility' : 'visibility_off'
            }}
            target='modalEnable'
            key={`${item.id}_visibility`}
          />
        )}
        {visible && (
          <ButtonModal
            payload={{
              title: '¿Está seguro de eliminar este documento?',
              fileName: item.name,
              id: item.id,
              index
            }}
            type={{
              styled: 'primary-icon',
              addClass: 'button-icon',
              icon: 'delete'
            }}
            target='modalAlert'
            key={`${item.id}_delete`}
          />
        )}
        <button
          onClick={() => handleChange(item.id)}
          onAuxClick={() => handleChange(item.id)}
          className='button-func'
          title='Descargar'
        >
          <Link
            key={`${item.id}_download`}
            addClass='button-icon'
            href={`${REACT_APP_API_URL}/download/file?nameFile=${item.name}`}
            icon='vertical_align_bottom'
            download
            target='_blank'
          />
        </button>
      </div>
    ]
  ])

  return data
}
