import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading, TableGrid } from 'ui-components'
import { dataCreateForm } from '../../../assets/data/dataCreate'
import {
  Modal,
  ModalAlert,
  ModalCreate,
  NotFound,
  Search,
  Wrapper
} from '../../../components'
import { useSearch } from '../../../hooks'
import { useValidateRole } from '../../../hooks/ValidateRole/useValidateRole'
import { setDataCreate, setDataTitle } from '../../../redux/reducers'
import {
  createFile,
  deleteFile,
  listFiles,
  updateFile
} from '../../../services/file.service'
import dataArray from './dataTable'
import { DocumentsStyle } from './styled'

function Documents() {
  const { files, loadingFile } = useSelector((state) => state.fileApiReducer)
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const dispatch = useDispatch()
  const [Hidden, setHidden] = useState(false)
  const { DataSearch, setDataValue, setDataRequest } = useSearch(files, '')
  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  function handleChange(e) {
    const targ = e.target
    setDataValue(targ.value)
  }

  const handleUpdateFile = async ({ statusId, fileId, index }) => {
    if (statusId === 1) {
      statusId = 2
    } else {
      statusId = 1
    }
    const data = {
      statusId,
      fileId
    }
    await dispatch(updateFile({ data, index }))
  }

  async function handleDeleteFile({ fileName, index, id }) {
    await dispatch(deleteFile({ fileName, index, id }))
  }

  async function handleCreateFile({ file }) {
    await dispatch(createFile({ file }))
  }

  useEffect(() => {
    dispatch(listFiles())
  }, [dispatch])

  useEffect(() => {
    setDataRequest(files)
  }, [files])

  useEffect(() => {
    dispatch(setDataTitle('Material de apoyo'))
    dispatch(setDataCreate(dataCreateForm.documents))
  }, [dispatch])

  useEffect(() => {
    DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [DataSearch])

  return (
    <Wrapper>
      <DocumentsStyle>
        <Search
          create='Agregar documento'
          search={{
            label: 'Nombre...',
            func: handleChange
          }}
        />

        {loadingFile ? (
          <Loading />
        ) : (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: '1fr 100px 100px 1fr 40px 40px 40px'
              }}
              headContent={[
                { type: 'default', label: 'Nombres' },
                { type: 'default', label: 'Peso' },
                { type: 'default', label: 'Formato' },
                { type: 'default', label: 'Usuario' },
                { type: 'default', label: '' },
                { type: 'default', label: '' },
                { type: 'default', label: '' }
              ]}
              bodyContent={dataArray(DataSearch, visible)}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound />}
          </>
        )}
      </DocumentsStyle>
      <Modal id='modalCrear' size='small'>
        <ModalCreate styled='category' handleCreate={handleCreateFile} />
      </Modal>

      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteFile} />
      </Modal>
      <Modal id='modalEnable'>
        <ModalAlert handleConfirm={handleUpdateFile} />
      </Modal>
    </Wrapper>
  )
}

export default Documents
