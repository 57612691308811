import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, patch, put } from './request.service'

const nameCollection = 'recording'

const listRecording = createAsyncThunk('recording/list', async () => {
  const response = await get(nameCollection)
  return response
})

const updateRecording = createAsyncThunk(
  'recording/update',
  async ({ data, idx }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))

    try {
      const response = await put(`${nameCollection}/${data.recording_id}`, data)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La grabación ha sido editada correctamente.'
        })
      )
      return { editRecording: response.data.body, idx }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La grabación no ha sido editada correctamente.'
        })
      )
      throw error
    }
  }
)

const deleteRecording = createAsyncThunk(
  'recording/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))

    try {
      const response = await destroy(`${nameCollection}/${params.id}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La grabación ha sido eliminada correctamente.'
        })
      )
      return { response, params }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La grabación no ha sido eliminada correctamente.'
        })
      )
      throw error
    }
  }
)

const postTermsRecording = createAsyncThunk('recording/getTerms', async () => {
  const response = await patch(`${nameCollection}/accept_terms `)
  console.log('postTermsRecording', response)
  return response
})

const recordingNotify = createAsyncThunk(
  'recording/recordingNotify',
  async (params) => {
    const response = await patch(`${nameCollection}/${params}/notify_download `)
    return response
  }
)

export {
  listRecording,
  updateRecording,
  deleteRecording,
  postTermsRecording,
  recordingNotify
}
