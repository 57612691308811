import { createSlice } from '@reduxjs/toolkit'
import { listRoomRoles } from '../../../../services/roleRoom.service'

const roleRoomApi = createSlice({
  name: 'roleRoomApi',
  initialState: {
    rolesRoom: [],
    loadingRol: false,
    currentRequestId: undefined,
    error: null
  },
  extraReducers: {
    [listRoomRoles.pending]: (state) => {
      state.loadingRol = true
    },
    [listRoomRoles.fulfilled]: (state, { payload }) => {
      const arrRoles = payload?.response.map((role) => {
        return { value: role.roleName, id: role.roleId }
      })
      state.rolesRoom = arrRoles
      state.loadingRol = false
    },
    [listRoomRoles.rejected]: (state, action) => {
      state.error = action.error
      state.loadingRol = false
    }
  }
})

const roleRoomApiReducer = roleRoomApi.reducer

export { roleRoomApiReducer }
