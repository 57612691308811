import styled from 'styled-components'

export const TitleStyle = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  .c-title {
    margin: 0;
    grid-column: 2 / 3;
  }
  .button-icon {
    background: none;
    color: gray;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: none;
    margin: 0;
    i {
      font-size: 2.5rem;
      width: max-content;
      height: max-content;
    }
  }
`
