import styled from 'styled-components'

export const RecordingCaseStyle = styled.div`
  .ui-gridTable-body {
    &-row {
      gap: 1rem;
      img {
        max-width: 80px;
      }
    }
    &-item {
      &:nth-child(1) {
        grid-column: 1 / 4;
      }
      .c-item {
        &-record {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
        }
      }
    }
  }
  @media screen and (max-width: 630px) {
    .ui-gridTable-head {
      display: none;
    }
    .ui-gridTable-body-row {
      .c-item {
        &-record {
          grid-template-columns: repeat(2, 1fr);
          &-name {
            grid-column: 2/ 3;
          }
          figure {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
          }
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .ui-gridTable-body-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 0.4rem;
      .c-item-user {
        width: 200px;
      }
    }
  }
`
