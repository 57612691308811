import styled from 'styled-components'

export const ModalAlertStyle = styled.div`
  /* padding: 1.5rem; */
  min-height: 130px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
  text-align: center;
  overflow: hidden;
  .c-cont-btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 1.5rem 0 0 0;
    button {
      width: 115px;
    }
  }

  .c-title-delete {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }
  .c-contInfo-child {
    overflow-y: auto;
    max-height: 70vh;
    padding: 0 1rem;
  }
`
