import { createSlice } from '@reduxjs/toolkit'
import { reportActions } from './report.actions'

const reportApi = createSlice({
  name: 'reportApi',
  initialState: {
    report: [],
    reportTables: [],
    reportListActions: [],
    reportQuantities: [],
    reportDataUser: [],
    reportDataCourses: [],
    listOptionCase: [],
    reportGroup: [],
    reportGroupYear: [],
    loadingReportGroup: false,
    loadingReport: false,
    errorReport: null
  },
  extraReducers: reportActions
})

const reportApiReducer = reportApi.reducer

export { reportApiReducer }
