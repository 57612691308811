import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, patch, post, put } from './request.service'

const nameCollection = 'file'

const listFiles = createAsyncThunk('files/list', async () => {
  const response = await get(nameCollection)
  return { data: response.data.body }
})

const listFileTypes = createAsyncThunk('files/listTypes', async () => {
  const response = await get('fileType')
  const arrFileTypes = response.data.body.map((fileType) => {
    return { value: fileType.name }
  })
  return arrFileTypes
})

const updateFile = createAsyncThunk(
  'files/update',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await put(
        `${nameCollection}/${params.data.fileId}`,
        params.data
      )
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido editado correctamente.'
        })
      )
      return { data: response.data.body, idx: params.index }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido editado correctamente.'
        })
      )
      throw error
    }
  }
)

const deleteFile = createAsyncThunk(
  'files/delete',
  async ({ fileName, index, id }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))

    try {
      const response = await destroy(`${nameCollection}/${fileName}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido eliminado correctamente.'
        })
      )
      return { response, index, id }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo no ha sido eliminado correctamente.'
        })
      )
      throw error
    }
  }
)

const createFile = createAsyncThunk(
  'files/create',
  async ({ file }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const params = new FormData()
      params.append('files', file[0])
      const response = await post(nameCollection, params)
      const newDocument = response.data.body
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido cargado correctamente.'
        })
      )
      return newDocument
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo no pudo ser cargado.'
        })
      )
      throw error
    }
  }
)

const notifyDownload = createAsyncThunk('files/notify', async (params) => {
  await patch(`${nameCollection}/${params}/notify_download`)
})

export {
  listFiles,
  listFileTypes,
  deleteFile,
  createFile,
  updateFile,
  notifyDownload
}
