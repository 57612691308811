import styled from 'styled-components'

export const AuditStyle = styled.div`
  .view-description {
    color: var(--primaryColor);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .c-contCards {
    display: grid;
    //grid-template: max-content/repeat(auto-fill, minmax(300px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    justify-content: center;
    &-item {
      > div {
        grid-template: 1fr / 1fr;
      }
      background: white;
      .c-card-img {
        display: none;
      }
    }
  }
`

export const AuditViewStyle = styled.div`
  .ui-gridTable-body-item {
    b {
      display: none;
    }
  }
  @media screen and (max-width: 540px) {
    .ui-gridTable-body-item {
      b {
        display: inline-block;
        margin-right: 10px;
      }
    }
    .ui-gridTable-body-row {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
    .ui-gridTable-head {
      display: none;
    }
  }
`
