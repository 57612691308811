import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Loading, TableGrid } from 'ui-components'
import {
  AuditCase,
  Modal,
  NotFound,
  Search,
  Wrapper
} from '../../../../components'
import { useFilter, useSearch } from '../../../../hooks'
import { setDataTitle } from '../../../../redux/reducers'
import {
  listActions,
  listAudits,
  listTables
} from '../../../../services/report.service'
import dataArray from './dataTable'
import { AuditViewStyle } from './styled'

function AuditView() {
  const [Hidden, setHidden] = useState(false)
  const [Title, setTitle] = useState('')
  const [excelData, setexcelData] = useState([])
  const { params } = useRouteMatch()
  const dispatch = useDispatch()
  const { report, loadingReport, reportTables, errorReport } = useSelector(
    (state) => state.reportApiReducer
  )

  const { DataFilter, setDataValueFilter } = useFilter(
    report,
    '',
    'authorAction'
  )
  const { DataSearch, setDataRequest, setDataValue } = useSearch(
    DataFilter,
    '',
    'author',
    'created_at'
  )

  function handleChange(e) {
    const targ = e.target
    targ.type === 'text' || targ.type === 'date'
      ? setDataValue(targ.value)
      : setDataValueFilter(targ.value)
  }

  useEffect(() => {
    setDataRequest(DataFilter)
    DataSearch && DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [DataSearch, DataFilter, setDataRequest])

  useEffect(() => {
    dispatch(listAudits(params.id))
    dispatch(listActions())
    reportTables.length <= 0 && dispatch(listTables())
  }, [dispatch])

  useEffect(() => {
    const value = reportTables[params.id - 1]
    value && setTitle(value.value)
    value && dispatch(setDataTitle(`Auditoría ${value.value.toLowerCase()}`))
  }, [dispatch, reportTables, params.id])

  const option = {
    fileName: `Auditoría_${Title}`,
    datas: [
      {
        sheetHeader: [
          'id',
          'Autor',
          'Antes',
          'Después',
          'Acción',
          'Tipo',
          'Fecha'
        ],
        sheetData: excelData,
        sheetName: `${Title}`,
        columnWidths: ['5', '10', '10', '10', '10', '10', '10']
      }
    ]
  }

  const cloneObj = (obj) => {
    const newValue = {}
    const keys = Object.keys(obj)
    const values = Object.values(obj)

    keys.map((key, index) => {
      const field = `${key}`
      const value = values[index]

      newValue[field] = value
      return true
    })
    return newValue
  }

  const jsonToString = (json) => {
    let textString = ''
    const keys = Object.keys(json)
    keys.map((item, idx) => {
      textString += `${item}: ${json[item]}`
      idx < keys.length - 1 && (textString += `\r\n`)
      return true
    })
    return textString
  }

  const convertJson = useCallback(() => {
    const objectEditable = [...DataSearch]
    objectEditable.map((obj, idx) => {
      const newObject = cloneObj(obj)
      Object.keys(newObject).map((item) => {
        if (item.includes('Data') && newObject[item]) {
          const valuetext = jsonToString(newObject[item])
          newObject[item] = valuetext
        }
        return true
      })
      objectEditable.splice(idx, 1, newObject)
      return true
    })
    setexcelData(objectEditable)
  }, [DataSearch])

  useEffect(() => {
    convertJson()
  }, [convertJson])

  return (
    <Wrapper>
      <AuditViewStyle>
        <Search
          back
          download
          exportData={option}
          options={{
            opts: [
              { id: 1, value: 'Crear' },
              { id: 2, value: 'Actualizar' },
              { id: 3, value: 'Eliminar' }
            ],
            label: 'Acción',
            func: handleChange
          }}
          search={{
            label: 'Autor...',
            func: handleChange
          }}
          searchDate={{
            func: handleChange
          }}
        />
        {loadingReport ? (
          <Loading />
        ) : (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: 'repeat(4, 1fr)'
              }}
              headContent={[
                { type: 'default', label: 'Acción' },
                { type: 'default', label: 'Autor' },
                { type: 'default', label: 'Fecha' },
                { type: 'default', label: 'Ver' }
              ]}
              bodyContent={dataArray(DataSearch)}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound text={errorReport} />}
          </>
        )}
      </AuditViewStyle>
      <Modal
        id='ModalAuditView'
        size={Title === 'Casos' || Title === 'Personajes' ? 'large' : 'medium'}
      >
        <AuditCase />
      </Modal>
    </Wrapper>
  )
}

export default AuditView
