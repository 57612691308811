import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dataCreateForm } from '../../../assets/data/dataCreate'
import { Card, Modal, ModalCreate, Search, Wrapper } from '../../../components'
import { useValidateRole } from '../../../hooks/ValidateRole/useValidateRole'
import { setDataCreate, setDataTitle } from '../../../redux/reducers'
import {
  createProtocols,
  listProtocols
} from '../../../services/protocols.service'
import { dataFake } from './dataFake'
import { ProtocolsStyle } from './styled'

function Protocols() {
  const { setRolId, visible } = useValidateRole()
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)

  useEffect(() => {
    dispatch(setDataTitle('Protocolos'))
    dispatch(setDataCreate(dataCreateForm.protocols))
  }, [dispatch])

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    dispatch(listProtocols())
  }, [dispatch])

  async function handleCreateProtocol({ roleId, file }) {
    await dispatch(createProtocols({ roleId, file }))
  }
  return (
    <Wrapper>
      <ProtocolsStyle>
        <Search create='Agregar archivo' back />
        <div className='c-contCards' data-center={profile.r.i === 'X1dI'}>
          {dataFake.map((item, idx) => (
            <Fragment key={`${idx}-category`}>
              {visible || item.role === profile.r.i ? (
                <div className='c-contCards-item'>
                  <Card options={item} />
                </div>
              ) : null}
            </Fragment>
          ))}
        </div>
      </ProtocolsStyle>
      <Modal id='modalCrear' size='small'>
        <ModalCreate styled='category' handleCreate={handleCreateProtocol} />
      </Modal>
    </Wrapper>
  )
}

export default Protocols
