import styled from 'styled-components'

export const VideoIntroductionStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('assets/images/bgIntroduction.png');
  background-size: cover;
  background-position: center;
  .c-video {
    max-width: 700px;
    width: 100%;
    margin: 2rem 0;
    &-controls {
      height: 26px;
      bottom: 4px;
      left: 0px;
    }
    &-progress {
      left: 0;
    }
  }
  a {
    width: 100%;
  }
`
