import { createSlice } from '@reduxjs/toolkit'
import { roleApiActions } from './roleAPI.actions'

const roleApi = createSlice({
  name: 'roleApi',
  initialState: {
    roles: [],
    loadingRol: false,
    currentRequestId: undefined,
    error: null
  },
  extraReducers: roleApiActions
})

const roleApiReducer = roleApi.reducer

export { roleApiReducer }
