import { createSlice } from '@reduxjs/toolkit'
import { NotificationApiActions } from './notificationApi.actions'

const NotificationApi = createSlice({
  name: 'notificationApi',
  initialState: {
    notifications: [
      {
        user: 'John Doe',
        alert: 'Lorem ipsum dolor sit amet',
        lastTime: '2 min'
      },
      {
        user: 'Luisa Malaver',
        alert: 'Lorem ipsum dolor sit amet',
        lastTime: '5 min'
      }
    ],
    loadingNotifications: false
  },
  extraReducers: NotificationApiActions
})

const NotificationApiReducer = NotificationApi.reducer
export { NotificationApiReducer }
