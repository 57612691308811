import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Loading, Text } from 'ui-components'
import {
  Accordion,
  ButtonModal,
  Modal,
  NotFound,
  Search,
  Wrapper
} from '../../../../components'
import { useFilter, useSearch } from '../../../../hooks'
import { setDataTitle } from '../../../../redux/reducers'
import {
  reportCourses,
  reportCoursesYears
} from '../../../../services/report.service'
import { CoursesReportStyled } from './styled'

function CoursesReport() {
  const dispatch = useDispatch()
  const [report, setReport] = useState({})
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const {
    reportDataCourses,
    loadingReport,
    reportGroup,
    reportGroupYear,
    loadingReportGroup
  } = useSelector((state) => state.reportApiReducer)
  const { DataFilter, setDataValueFilter } = useFilter(
    reportDataCourses,
    '',
    'year'
  )
  const { DataSearch, setDataRequest, setDataValue } = useSearch(
    DataFilter,
    '',
    'courseCode'
  )

  function handleChange(e) {
    const targ = e.target
    targ.type === 'text'
      ? setDataValue(targ.value)
      : setDataValueFilter(targ.value)
  }

  useEffect(() => {
    dispatch(setDataTitle('Cursos'))
    dispatch(reportCoursesYears())
  }, [dispatch])

  useEffect(() => {
    dispatch(reportCourses())
  }, [dispatch])

  useEffect(() => {
    setDataRequest(DataFilter)
  }, [DataFilter, setDataRequest])

  const baseOption = {
    fileName: 'Reporte_cursos',
    datas: []
  }

  const assembleOptions = useCallback(() => {
    DataSearch.map((courses) => {
      const baseData = {
        sheetHeader: ['Código del Curso', 'Año', 'Grupo', 'Integrantes'],
        sheetData: [],
        sheetName: '',
        columnWidths: ['10', '10', '10', '10']
      }
      if (courses?.courseName?.length >= 25) {
        let NewName = ''
        const witdth = courses.courseName.length - 25
        NewName = courses.courseName.slice(0, -witdth)
        baseData.sheetName = NewName
      } else {
        baseData.sheetName = courses.courseName
      }
      Object.keys(courses).map((key) => {
        if (key === 'groups' && courses[key]) {
          courses[key].map((group) => {
            const sheetData = {
              Código: courses.courseCode,
              Año: courses.year,
              Grupo: group.name,
              Integrantes: ''
            }
            if (group.users) {
              let listUsers = ''
              group.users.map((item, idx) => {
                listUsers += item.user
                idx < group.users.length - 1 && (listUsers += ` \r\n`)
                return listUsers
              })
              sheetData.Integrantes = listUsers
            }
            baseData.sheetData.push(sheetData)
            return true
          })
        }
        return true
      })
      baseOption.datas.push(baseData)
      return true
    })

    setReport(baseOption)
  }, [DataSearch])

  useEffect(() => {
    assembleOptions()
  }, [assembleOptions])

  return (
    <Wrapper>
      <CoursesReportStyled>
        <Search
          back
          download
          exportData={report}
          options={{
            opts: reportGroupYear,
            label: 'Año',
            func: handleChange
          }}
          search={{
            label: 'Código...',
            func: handleChange
          }}
        />
        {loadingReport ? (
          <Loading />
        ) : DataSearch && DataSearch.length > 0 ? (
          DataSearch.map((item, idx) => (
            <Fragment key={`course-${idx}`}>
              <Accordion
                title={{
                  strong: item.courseName,
                  text: ''
                }}
                idx={idx}
                id={item.courseId}
                role={profile.r}
                iconReport={{ date: item.year, code: item.courseCode }}
              >
                <div className='c-tab-content-txt'>
                  {!item.groups && (
                    <NotFound text='Este curso no cuenta con grupos.' />
                  )}
                  {item.groups &&
                    item.groups.map((group, idx) => (
                      <div
                        key={`item-group-${idx}`}
                        className='c-tab-content-item'
                      >
                        <p>{group.name}</p>
                        <ButtonModal
                          target='group'
                          payload={group.id}
                          report
                          type={{
                            styled: 'primary-icon',
                            addClass: 'button-icon',
                            icon: 'people'
                          }}
                        ></ButtonModal>
                      </div>
                    ))}
                </div>
              </Accordion>
            </Fragment>
          ))
        ) : DataSearch && DataSearch.length <= 0 ? (
          <NotFound />
        ) : (
          !reportDataCourses && <NotFound text='No hay cursos' />
        )}
        <Modal id='group'>
          <Text type='h5' addClass='c-title-groups'>
            <Icon nameIcon='people' />
            Integrantes
          </Text>
          <div className='c-contInfo-modal'>
            <div className='c-contInfo'>
              {loadingReportGroup ? (
                <Loading />
              ) : reportGroup.length > 0 ? (
                reportGroup.map((item, idx) => (
                  <div key={`item-${idx}`} className='c-item-user'>
                    <Text type='p'>{item.user}</Text>
                  </div>
                ))
              ) : (
                <Text type='h6'>Este grupo aún no cuenta con integrantes.</Text>
              )}
            </div>
          </div>
        </Modal>
      </CoursesReportStyled>
    </Wrapper>
  )
}

export default CoursesReport
