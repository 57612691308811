import { combineReducers } from '@reduxjs/toolkit'
import { caseAPIReducer } from './casesAPI/casesApi'
import { categoryApiReducer } from './categoryApi/categoryApi'
import { characterApiReducer } from './characterApi/characterApi'
import { fileApiReducer } from './fileApi/fileApi'
import { fileCasesApiReducer } from './fileCasesApi/fileCasesApi'
import { LoginApiReducer, setLogged } from './loginApi'
import { NotificationApiReducer } from './notificationApi/notificationApi'
import { orientationApiReducer } from './orientationApi/orientationApi'
import { ProtocolsApiReducer } from './protocolsApi/protocolsApi'
import { recordingApiReducer } from './recordingApi/recordingApi'
import { reportApiReducer } from './reportApi/reportApi'
import {
  formCreateRoomReducer,
  roleRoomApiReducer,
  roomApiReducer
} from './roomsApi'
import { roleApiReducer, usersApiReducer } from './usersAPI'
import {
  setDataCreate,
  setDataTemp,
  setDataTitle,
  setHiddenMedia,
  setNewResponse,
  utilitiesReducer
} from './utilities/utilities'

export {
  setDataCreate,
  setDataTitle,
  setNewResponse,
  setLogged,
  setHiddenMedia,
  setDataTemp
}
export default combineReducers({
  fileCasesApiReducer,
  utilitiesReducer,
  usersApiReducer,
  roleApiReducer,
  categoryApiReducer,
  caseAPIReducer,
  fileApiReducer,
  roomApiReducer,
  roleRoomApiReducer,
  formCreateRoomReducer,
  recordingApiReducer,
  characterApiReducer,
  LoginApiReducer,
  orientationApiReducer,
  NotificationApiReducer,
  ProtocolsApiReducer,
  reportApiReducer
})

// const rootReducer = (state, action) => {
//   if (action.type === 'RESET') {
//     state = combineReducer({}, {})
//   }
//   return combineReducer(state, action)
// }
//  rootReducer
