import { useDispatch } from 'react-redux'
import { InputControl } from 'ui-components'
import { Conditions, Introduction } from '../../../components'
import { postTerms } from '../../../services/user.service'
import { TermsConditionsStyle } from './styled'

function TermsConditions() {
  const dispatch = useDispatch()

  const handleCheck = () => {
    dispatch(postTerms())
  }

  return (
    <TermsConditionsStyle>
      <Introduction navigation='/secciones'>
        <Conditions />
        <InputControl
          type='checkbox'
          name='name_1'
          label='Aceptar términos y condiciones.'
          onChange={handleCheck}
        ></InputControl>
      </Introduction>
    </TermsConditionsStyle>
  )
}

export default TermsConditions
