import { createSlice } from '@reduxjs/toolkit'
import { getSessionCookie } from '../../../services/session.service'
import { getStorageValue } from '../../../utilities/js/localStorage'
import { LoginApiActions } from './loginApi.actions'

const LoginApi = createSlice({
  name: 'LoginApi',
  initialState: {
    token: getSessionCookie('token'),
    profile: getStorageValue('profile'),
    nav: getStorageValue('nav'),
    isLogged: Boolean(getSessionCookie('refreshToken')),
    loadingResponse: {
      state: 'idle',
      message: ''
    }
  },
  reducers: {
    setLogged(state, action) {
      state.isLogged = action.payload
    }
  },
  extraReducers: LoginApiActions
})

const { setLogged } = LoginApi.actions
const LoginApiReducer = LoginApi.reducer
export { LoginApiReducer, setLogged }
