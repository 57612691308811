import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Loading, Text } from 'ui-components'
import { Search, Wrapper } from '../../../../components'
import { setDataTitle } from '../../../../redux/reducers'
import { getCaseByCategory } from '../../../../redux/reducers/casesAPI/casesApi'
import { listCases } from '../../../../services/case.service'
import { CaseStyle } from './styled'

function Case() {
  const [Data, setData] = useState([])
  const { idcategory, idcaso } = useParams()
  const { cases, loadingCases, casesBycategory } = useSelector(
    (state) => state.caseAPIReducer
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (cases.length <= 0) {
      dispatch(listCases())
    } else {
      dispatch(getCaseByCategory(idcategory))
    }
  }, [dispatch, idcategory, cases])

  useEffect(() => {
    const dataCase = casesBycategory.filter(
      (item) => item.id === Number(idcaso)
    )
    setData(...dataCase)
  }, [casesBycategory])

  useEffect(() => {
    if (Data) {
      dispatch(setDataTitle(Data.title))
    }
  }, [dispatch, Data])
  return (
    <Wrapper>
      <CaseStyle>
        <Search back />
        {loadingCases ? (
          <Loading />
        ) : (
          Data && (
            <div className='c-cont-paragraph'>
              <Text type='h6'> Intenciones de aprendizaje: </Text>
              <Text type='p'>{Data.learning}</Text>
              <Text type='h6'> Párrafo inicial: </Text>
              <Text type='p'>{Data.introduction}</Text>
              <Text type='h6'> Contexto del caso: </Text>
              <Text type='p'>{Data.context}</Text>
              <Text type='h6'> Cuerpo del caso: </Text>
              <Text type='p'>{Data.body}</Text>
            </div>
          )
        )}
      </CaseStyle>
    </Wrapper>
  )
}

export default Case
