import {
  createCategory,
  deleteCategory,
  listCategories,
  updateCategory
} from '../../../services/category.service'

const categoryApiActions = {
  [listCategories.pending]: (state) => {
    state.loadingCategory = true
  },
  [listCategories.fulfilled]: (state, { payload }) => {
    if (payload) {
      state.category = payload?.data?.body
    }
    state.loadingCategory = false
  },
  [listCategories.rejected]: (state, action) => {
    state.loadingCategory = false
  },
  [createCategory.fulfilled]: (state, { payload }) => {
    state.category.push(payload)
  },

  [updateCategory.fulfilled]: (state, { payload }) => {
    const { idx, editCategory } = payload
    state.category.splice(idx, 1, editCategory)
  },
  [deleteCategory.fulfilled]: (state, { payload }) => {
    const { params } = payload
    state.category.splice(params.idx, 1)
  }
}

export { categoryApiActions }
