import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Loading, TableGrid } from 'ui-components'
import { NotFound, Search, Wrapper } from '..'
import { useSearch } from '../../hooks'
import { useValidateRole } from '../../hooks/ValidateRole/useValidateRole'
import { setDataTitle } from '../../redux/reducers'
import { notifyDownload } from '../../services/file.service'
import {
  deleteProtocols,
  listProtocols,
  updateProtocols
} from '../../services/protocols.service'
import { Modal } from '../Modal'
import { ModalAlert } from '../Modal/ModalAlert'
import dataArray from './dataTable'
import { ProtocolsFileStyle } from './styled'

function ProtocolsFile() {
  const [Hidden, setHidden] = useState(false)
  const [FilterUser, setFilterUser] = useState('')
  const [dataTable, setDataTable] = useState()
  const [data, setdata] = useState()
  const { idRole } = useParams()
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { protocols, loadingprotocols } = useSelector(
    (state) => state.ProtocolsApiReducer
  )
  const { DataSearch, setDataValue, setDataRequest } = useSearch(protocols, '')
  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  function handleChange(e) {
    const targ = e.target
    setDataValue(targ.value)
  }

  function handleNotify(item) {
    dispatch(notifyDownload(item))
  }

  useEffect(() => {
    switch (idRole) {
      case 'Administrador':
        setFilterUser('X1dI')
        break
      case 'Docente-director':
        setFilterUser('DyIe')
        break
      case 'Docente-tutor':
        setFilterUser('IbQJ')
        break
      case 'Estudiante':
        setFilterUser('pnWt')
        break

      default:
        break
    }
  }, [idRole])

  useEffect(() => {
    const filterRol = protocols.filter((item) => item.ri === FilterUser)
    setDataRequest(filterRol)
  }, [idRole, protocols, FilterUser])

  useEffect(() => {
    setdata(DataSearch)
    DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [setDataRequest, DataSearch])

  useEffect(() => {
    const title = idRole.replace('-', ' ')
    dispatch(setDataTitle(`Protocolos por ${title.toLowerCase()}`))
  }, [dispatch])

  useEffect(() => {
    if (protocols.length <= 0) {
      dispatch(listProtocols())
    }
  }, [dispatch])

  useEffect(() => {
    if (data) {
      const dataTable = dataArray(data, visible, handleNotify)
      setDataTable(dataTable)
    }
  }, [data, visible])

  const handleUpdateProtocol = async ({ statusId, fileId, index }) => {
    if (statusId === 1) {
      statusId = 2
    } else {
      statusId = 1
    }
    const info = {
      statusId,
      fileId
    }
    await dispatch(updateProtocols({ info, index }))
  }

  async function handleDeleteProtocol({ fileName, id }) {
    await dispatch(deleteProtocols({ fileName, id }))
  }

  return (
    <Wrapper>
      <ProtocolsFileStyle>
        <Search
          search={{
            label: 'Nombre...',
            func: handleChange
          }}
          back
        />
        {loadingprotocols ? (
          <Loading />
        ) : dataTable && dataTable.length > 0 ? (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: '1fr 100px 100px 1fr 40px 40px 40px'
              }}
              headContent={[
                { type: 'default', label: 'Nombres' },
                { type: 'default', label: 'Peso' },
                { type: 'default', label: 'Formato' },
                { type: 'default', label: 'Usuario' },
                { type: 'default', label: '' },
                { type: 'default', label: '' },
                { type: 'default', label: '' }
              ]}
              bodyContent={dataTable}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound />}
          </>
        ) : dataTable && dataTable.length <= 0 ? (
          <NotFound text={`No hay archivos disponibles.`} />
        ) : null}
      </ProtocolsFileStyle>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteProtocol} />
      </Modal>
      <Modal id='modalEnable'>
        <ModalAlert handleConfirm={handleUpdateProtocol} />
      </Modal>
    </Wrapper>
  )
}

export { ProtocolsFile }
