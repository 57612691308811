import styled from 'styled-components'

export const OrientationStyle = styled.div`
  .c-contCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    justify-content: center;

    &-item {
      background: white;
    }
  }
  .c-card-img figure img {
    object-position: 20% 15%;
  }
`
