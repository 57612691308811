import styled from 'styled-components'

export const NotificationStyle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  > span {
    width: 100%;
    height: 100%;
  }
  .c-notificationModal {
    position: absolute;
    top: 95px;
    background-color: white;
    width: 300px;
    padding: 5px 10px;
    box-shadow: var(--boxCard);
    z-index: 2;
    height: max-content;
    border-radius: 0 0 10px 10px;
    cursor: initial;
    @media screen and (max-width: 1180px) {
      top: 47px;
      right: 0;
    }
  }
  .c-notification-item {
    display: grid;
    grid-template-columns: 1fr 65px;
    margin-top: 0.7rem;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: none;
    }
    .c-item-btn {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      button {
        margin: 0;
      }
    }
    .hours {
      font-size: 0.7rem;
      color: #898989;
    }
  }
`
