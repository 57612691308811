import { Introduction } from '../../../components'
import { WelcomeStyle } from './styled'

export default function Welcome() {
  return (
    <WelcomeStyle>
      <video
        src='./assets/videos/bgWelcome.mp4'
        controls
        autoPlay={true}
        muted
        loop
        className='c-bgVideo'
        poster='./assets/images/poster.png'
      ></video>
      <Introduction navigation='/video-introduccion' showBtn>
        <figure className='c-contImg'>
          <img
            src='./assets/images/logoUnadCirculo.svg'
            alt='Logo universidad nacional abierta y a distancia'
          />
        </figure>
      </Introduction>
    </WelcomeStyle>
  )
}
