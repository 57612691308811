import styled from 'styled-components'

export const HomeStyle = styled.nav`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));

  .c-item {
    position: relative;
    background: linear-gradient(
      0deg,
      rgb(0 0 0 / 67%) 24%,
      rgba(255, 255, 255, 0) 40%
    );
    &[disabled] {
      filter: grayscale(1);
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      background-position: center;
      filter: blur(3px);
      transition: all 0.1s ease-in-out;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &::after {
      content: '';
      width: 0;
      height: 10px;
      position: absolute;
      bottom: 0;
      background: var(--secondaryColor);
      transition: 0.5s ease-in-out;
    }
    &:hover {
      &::after {
        width: 100%;
      }
      &::before {
        filter: blur(0);
      }
    }
    &[data-img='usuarios']::before {
      background-image: url('assets/images/sections/usuarios.png');
    }
    &[data-img='bancoCasos']::before {
      background-image: url('assets/images/sections/bancoCasos.png');
    }
    &[data-img='gestionDocumentos']::before {
      background-image: url('assets/images/sections/gestionDocumentos.png');
    }
    &[data-img='ssl']::before {
      background-image: url('assets/images/sections/ssl.png');
    }
    &[data-img='ssl']::before {
      background-image: url('assets/images/sections/ssl.png');
    }
    &[data-img='materialApoyo']::before {
      background-image: url('assets/images/sections/materialApoyo.png');
    }
    &[data-img='orientacion']::before {
      background-image: url('assets/images/sections/orientacion.png');
    }
    &[data-img='protocolos']::before {
      background-image: url('assets/images/sections/protocolos.png');
    }
    &[data-img='reportes']::before {
      background-image: url('assets/images/sections/reportes.png');
    }
    &[data-img='grabaciones']::before {
      background-image: url('assets/images/sections/grabaciones.png');
    }

    &-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;
      span {
        padding-bottom: 5rem;
        color: white;
      }
      &[disabled] {
        span {
          text-shadow: 0px 0 4px #211f1f;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    .c-item {
      background: linear-gradient(
        271deg,
        rgb(0 0 0 / 75%) 18%,
        rgba(255, 255, 255, 0) 79%
      );
      &::before {
        background-position: 10% 22%;
        background-size: cover;
      }
      &::after {
        width: 10px;
        height: 0;
      }
      &:hover {
        &::after {
          width: 10px;
          height: 100%;
        }
      }
      &-link {
        justify-content: flex-end;
        align-items: center;
        span {
          padding-bottom: 0;
          padding-right: 3rem;
        }
      }
    }
  }
`
