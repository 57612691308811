import {
  createFile,
  deleteFile,
  listFiles,
  listFileTypes,
  updateFile
} from '../../../services/file.service'

const fileApiActions = {
  [listFiles.pending]: (state) => {
    state.loadingFile = true
  },
  [listFiles.fulfilled]: (state, { payload }) => {
    const { data } = payload
    state.files = data
    state.loadingFile = false
  },
  [listFiles.rejected]: (state, action) => {
    state.error = action.error
    state.loadingFile = false
  },

  [listFileTypes.pending]: (state) => {
    state.loadingFileType = true
  },
  [listFileTypes.fulfilled]: (state, { payload }) => {
    state.fileTypes = payload
    state.loadingFileType = false
  },
  [listFileTypes.rejected]: (state, action) => {
    state.error = action.error
    state.loadingFileType = false
  },

  [createFile.fulfilled]: (state, { payload }) => {
    state.files.unshift(payload)
  },

  [updateFile.fulfilled]: (state, { payload }) => {
    const { data } = payload
    const itemSelected = state.files.findIndex((item) => item.id === data.id)
    state.files.splice(itemSelected, 1, data)
  },

  [deleteFile.fulfilled]: (state, { payload }) => {
    const { id } = payload
    const newItems = state.files.filter((item) => item.id !== id)
    state.files = newItems
  }
}

export { fileApiActions }
