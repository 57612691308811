import {
  createOrientation,
  deleteOrientation,
  listOrientation,
  updateOrientation
} from '../../../services/orientation.service'

const orientationApiActions = {
  [listOrientation.pending]: (state) => {
    state.loadingOrientation = true
  },
  [listOrientation.fulfilled]: (state, { payload }) => {
    if (payload) {
      state.orientation = payload?.data?.body
    }
    state.loadingOrientation = false
  },
  [listOrientation.rejected]: (state, action) => {
    state.error = action.error
    state.loadingOrientation = false
  },

  [createOrientation.fulfilled]: (state, { payload }) => {
    state.orientation.push(payload)
  },

  [updateOrientation.fulfilled]: (state, { payload }) => {
    const { idx, editOrientation } = payload
    state.orientation.splice(idx, 1, editOrientation)
  },

  [deleteOrientation.fulfilled]: (state, { payload }) => {
    const { params } = payload
    state.orientation.splice(params.idx, 1)
  }
}

export { orientationApiActions }
