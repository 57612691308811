import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, post, put } from './request.service'

const className = 'character'

const listCharacter = createAsyncThunk('character/list', async (caseId) => {
  const response = await get(`${className}/case/${caseId}`)
  return response.data.body
})

const createCharacter = createAsyncThunk(
  'character/create',
  async (data, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await post(className, data)
      dispatch(
        setNewResponse({
          state: 'succes',
          message: 'El personaje ha sido creado correctamente'
        })
      )
      return { data: response.data.body }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message:
            'El personaje no fue creado, los campos deben tener un mínimo de 5 caracteres.'
        })
      )
      throw error
    }
  }
)

const updateCharacter = createAsyncThunk(
  'character/update',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await put(
        `${className}/${params.characterId}`,
        params.data
      )
      dispatch(
        setNewResponse({
          state: 'success',
          message: 'El personaje ha sido editado correctamente'
        })
      )
      return { data: response.data.body, idx: params.idx }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'El personaje no ha sido editado correctamente'
        })
      )
      throw error
    }
  }
)

const deleteCharacter = createAsyncThunk(
  'character/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await destroy(`${className}/${params.id}`)
      dispatch(
        setNewResponse({
          state: 'success',
          message: 'El personaje ha sido eliminado correctamente'
        })
      )
      return { response, idx: params.idx }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'El personaje no ha sido eliminado correctamente'
        })
      )
      throw error
    }
  }
)

export { listCharacter, createCharacter, updateCharacter, deleteCharacter }
