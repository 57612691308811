import { createSlice } from '@reduxjs/toolkit'

const UtilitiesReducer = createSlice({
  name: 'dataCreate',
  initialState: {
    dataCreate: {},
    dataTitle: '',
    hiddenMedia: false,
    dataTemp: {
      title: ''
    },
    newResponse: {
      state: 'idle',
      message: '',
      errors: []
    }
  },

  reducers: {
    setDataCreate(state, action) {
      state.dataCreate = action.payload
    },
    setDataTitle(state, action) {
      state.dataTitle = action.payload
    },
    setNewResponse(state, action) {
      state.newResponse = action.payload
    },
    setDataTemp(state, action) {
      state.dataTemp = action.payload
    },
    setHiddenMedia(state, { payload }) {
      state.hiddenMedia = payload
    }
  }
})

const {
  setDataCreate,
  setDataTitle,
  setNewResponse,
  setDataTemp,
  setHiddenMedia
} = UtilitiesReducer.actions

const utilitiesReducer = UtilitiesReducer.reducer
export {
  utilitiesReducer,
  setDataCreate,
  setDataTitle,
  setNewResponse,
  setDataTemp,
  setHiddenMedia
}
