export const dataFake = [
  {
    image: './assets/images/administrador.png',
    title: 'Administrador',
    navigation: '/protocolos/rol/Administrador',
    role: 'X1dI'
  },
  {
    image: './assets/images/docenteDirector.png',
    title: 'Docente director',
    navigation: '/protocolos/rol/Docente-director',
    role: 'DyIe'
  },
  {
    image: './assets/images/docenteTutor.png',
    title: 'Docente tutor',
    navigation: '/protocolos/rol/Docente-tutor',
    role: 'IbQJ'
  },
  {
    image: './assets/images/estudiante.png',
    title: 'Estudiante',
    navigation: '/protocolos/rol/Estudiante',
    role: 'pnWt'
  }
]
