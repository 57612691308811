import { createSlice } from '@reduxjs/toolkit'
import { orientationApiActions } from './orientationApi.actions'

const orientationApi = createSlice({
  name: 'orientationApi',
  initialState: {
    orientation: [],
    loadingOrientation: false,
    currentRequestId: undefined,
    error: null
  },
  extraReducers: orientationApiActions
})

const orientationApiReducer = orientationApi.reducer

export { orientationApiReducer }
