import { Text } from 'ui-components'
import { NotFoundStyle } from './styled'

function NotFound({ text }) {
  return (
    <NotFoundStyle>
      <Text type='h6'>
        {text ||
          'No se encontraron coincidencias con los criterios de búsqueda.'}
      </Text>
    </NotFoundStyle>
  )
}

export { NotFound }
