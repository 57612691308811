import styled from 'styled-components'

export const TermsConditionsStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('assets/images/bgIntroduction.png');
  background-size: cover;
  background-position: center;

  a {
    width: 100%;
  }
`
