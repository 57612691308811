import { createAsyncThunk } from '@reduxjs/toolkit'
// import { setNewResponse } from '../redux/reducers'
import { get } from './request.service'

// {host}}/report/audits?table=1 -> lista las auditorias por tabla
// {{host}}/report/audits/tables ->lista las tablas
// {{host}}/report/audits/actions -> lista las acciones

const nameCollection = 'report'

const listAudits = createAsyncThunk('report/audits/list', async (params) => {
  const response = await get(`${nameCollection}/audits?table=${params}`)
  return response
})

const listTables = createAsyncThunk('report/audits/listTables', async () => {
  const response = await get(`${nameCollection}/audits/tables`)
  return response
})

const listActions = createAsyncThunk('report/audits/listActions', async () => {
  const response = await get(`${nameCollection}/audits/actions`)
  return response
})

const listQuantities = createAsyncThunk('report/listQuantities', async () => {
  const response = await get(`${nameCollection}/quantities`)
  return response
})

const listRecordings = createAsyncThunk(
  'report/listRecordings',
  async (params) => {
    const response = await get(`${nameCollection}/recordings/user/${params}`)
    return response
  }
)

const listFiles = createAsyncThunk('report/listfiles', async (params) => {
  const response = await get(`${nameCollection}/files/user/${params}`)
  return response
})

const listFilesCases = createAsyncThunk(
  'report/listfilescases',
  async (params) => {
    const response = await get(`${nameCollection}/filesCases/user/${params}`)
    return response
  }
)

const listSessions = createAsyncThunk('report/listSessions', async (params) => {
  const response = await get(`${nameCollection}/sessions/user/${params}`)
  return response
})

const reportCourses = createAsyncThunk('report/courses', async () => {
  const response = await get(`${nameCollection}/courses`)
  return response
})

const reportCoursesGroup = createAsyncThunk(
  'report/coursesGroup',
  async (params) => {
    const response = await get(`${nameCollection}/courses/groups/${params}`)
    return response
  }
)

const reportCoursesYears = createAsyncThunk('report/coursesYears', async () => {
  const response = await get(`${nameCollection}/courses/years`)
  return response
})

export {
  listAudits,
  listActions,
  listTables,
  listQuantities,
  reportCourses,
  listRecordings,
  listFiles,
  listSessions,
  listFilesCases,
  reportCoursesGroup,
  reportCoursesYears
}
