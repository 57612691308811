import styled from 'styled-components'

export const NavMobileStyle = styled.nav`
  display: grid;
  grid-template-rows: 150px 1fr;
  height: 100vh;
  align-items: center;
  overflow: auto;
`

export const AsideNavStyle = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  align-items: center;
  justify-content: center;
  padding: 0 0 1rem 0;
  .ui-aside {
    padding: 0;
    width: 390px;
    height: 100%;
    background: var(--primaryColorLight);
    & ~ i {
      color: white;
      font-size: 2rem;
      width: auto;
      height: auto;
    }
  }
  .c-title {
    margin: 0;
    color: white;
  }
  /* .c-link {
    grid-column: 3 / 4;
  } */
  .c-nav {
    &-navbar {
      display: grid;
      /* grid-template-rows: repeat(auto-fit,minmax(10%,1fr)); */
      grid-auto-rows: 60px;
      height: 100%;
      align-items: center;
      padding-top: 1rem;

      &--link {
        height: 100%;
        display: flex;
        position: relative;
        a,
        .c-logout {
          width: 100%;
          display: grid;
          align-items: center;
          color: gray;
          grid-template-columns: 40px 1fr;
          gap: 1rem;
          padding: 0 1.5rem;
          background: transparent;
          border: none;
          span {
            font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial,
              sans-serif;
            text-align: left;
            color: var(--primaryColorDark);
            font-weight: bold;
            font-size: 1rem;
          }
          &.active {
            background: #cfcaff;
            /* &::before{
                            content: '';
                            width: 5px;
                            height: 100%;
                            background-color: var(--primaryColorDark);
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        } */
          }
        }
      }
    }
  }
  .c-contHelpNoti {
    display: flex;
    grid-column: 3 / 4;
    span {
      color: transparent;
      font-size: 0;
    }
  }
`
