import styled from 'styled-components'

export const FormInvitedStyle = styled.div`
  form,
  .c-cont {
    padding: 1rem 1.5rem 0 1.5rem;
    overflow: auto;
    max-height: 70vh;
  }

  .c-toggle {
    padding: 0.5rem 0 0 1.2rem;
  }

  .c-cont {
    &-Inputs {
      display: grid;
      grid-template-columns: 1fr 180px 40px;
      gap: 5px;
      align-items: center;
      button {
        width: 100%;
        height: 100%;
        i {
          font-size: 1.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;
        }
      }
    }
    select {
      width: 100%;
    }
  }

  .ItemCont-user {
    margin-top: 1rem;
    &-item {
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--primaryColorLight);
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: 1fr 180px 40px;
      word-break: break-all;
      gap: 5px;
      margin-top: 0.35em;
      p:nth-child(2) {
        text-align: center;
      }
      button {
        width: 30px;
        height: 30px;
      }
    }
  }

  .ItemCont-upload {
    display: grid;
    grid-template-columns: 1fr 210px;
    align-items: center;
    a {
      border-radius: 30px;
      display: flex;
      align-items: center;
      box-shadow: 0 0 2px 0px var(--primaryColor);
      background: var(--primaryColorLight);
      width: 100%;
      height: max-content;
    }
    label {
      text-transform: inherit;
      input {
        width: 0;
      }
    }
  }

  .c-contInfo-modal {
    padding: 1rem;
  }

  @media screen and (max-width: 600px) {
    .c-cont {
      &-Inputs {
        grid-template-columns: 1fr 40px;

        button {
          width: 40px;
          height: 40px;
          margin: 0;
          grid-row: 1 / 4;
        }
        .ItemCont:nth-child(2) {
          grid-row: 2;
        }
      }
    }
    .ItemCont-user {
      &-item {
        grid-template-columns: 1fr 40px;
        gap: 0;
        p:nth-child(2) {
          grid-row: 2;
          text-align: left;
          font-weight: bold;
        }
        button {
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 550px) {
    .ItemCont-upload {
      display: block;
    }
  }

  .btnItem {
    width: 100%;
    max-width: 200px;
    margin: 1rem auto;
    display: block;
  }

  .InputItem,
  .SelectItem {
    width: 100%;
  }
  .c-title-create {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }
`
