import { useEffect, useState } from 'react'
import { Nav, Wrapper } from '../'
import { HeaderStyle } from './styled'
function Header({ states }) {
  const [Hidden, setHidden] = useState({})
  useEffect(() => {
    setHidden(states)
  }, [states])
  return (
    <HeaderStyle>
      <Wrapper>
        <Nav hidden={Hidden} />
      </Wrapper>
    </HeaderStyle>
  )
}

export { Header }
