import styled from 'styled-components'

export const WelcomeStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('assets/videos/bgWelcome.mp4');
  background-size: cover;
  background-position: center;
  position: relative;
  .c-contImg {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 1rem 0;
    img {
      width: 100%;
    }
  }
  h2 {
    color: white;
    text-shadow: 0 0 9px black;
  }
  .c-bgVideo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  button[styled='secondary'] {
    box-shadow: none;
  }
  a {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`
