import styled, { keyframes } from 'styled-components'

const fadeOff = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const RoomStyle = styled.div`
  .c-title-video {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }
  .fieldContent {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  .cortinillaIframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    animation: ${fadeOff} 10s ease forwards;
    object-fit: cover;
    pointer-events: none;
  }
  .contIframe {
    display: block;
    position: relative;
    padding-top: 56.5%;
    @media screen and (max-width: 44.75em) {
      padding-top: 100%;
    }
  }
  .c-content {
    display: grid;
    grid-template-columns: 1fr 2rem;
    align-items: flex-end;
    @media screen and (max-width: 44.75em) {
      grid-template-columns: 1fr;
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .c-contInfo-modal {
    min-height: 130px;
    padding: 1rem;
    button {
      width: max-content;
    }
  }
  .btn-alert {
    width: 50px;
    height: 50px;
    background-color: #f44336;
    i {
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
    &:hover {
      background-color: #f26d63;
    }
  }
`
