import { createSlice } from '@reduxjs/toolkit'
import { fileCasesActions } from './fileCasesApi.actions'

const fileCasesApi = createSlice({
  name: 'fileCasesApi',
  initialState: {
    filesCases: [],
    loadingFile: false,
    loadingResponse: {
      state: 'idle',
      message: ''
    },
    currentRequestId: undefined,
    error: null
  },
  extraReducers: fileCasesActions
})

const fileCasesApiReducer = fileCasesApi.reducer

export { fileCasesApiReducer }
