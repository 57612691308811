import styled from 'styled-components'

export const LoginStyle = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  justify-content: center;
  background: var(--primaryColorLight);
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 400px;
    border-radius: 20px;
    box-shadow: 4px 4px 5px -2px var(--primaryColorDark);
    button {
      margin: 1rem auto;
      display: block;
      width: 150px;
    }
  }
`

export const LoginLTIStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('assets/images/bgIntroduction.png');
  background-size: cover;
  background-position: center;
  .c-cont {
    width: 100%;
    padding: 1rem;
    h2 {
      margin-bottom: 4rem;
    }
  }
  .c-contCards {
    display: grid;
    /* grid-template: max-content/repeat(auto-fill, minmax(270px, 1fr)); */
    grid-template-columns: repeat(auto-fit, minmax(270px, 270px));
    gap: 4rem;
    width: 100%;
    justify-content: center;
    &-item {
      background: white;
    }
  }
  .c-card-img figure img {
    object-position: 20% 15%;
  }
`
