import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Loading, TableGrid } from 'ui-components'
import { dataCreateForm } from '../../../../assets/data/dataCreate'
import {
  Modal,
  ModalAlert,
  ModalCreate,
  NotFound,
  Search,
  Wrapper
} from '../../../../components'
import { useFilter } from '../../../../hooks'
import { useValidateRole } from '../../../../hooks/ValidateRole/useValidateRole'
import { setDataCreate, setDataTitle } from '../../../../redux/reducers'
import { notifyDownload } from '../../../../services/file.service'
import {
  createFileCases,
  deleteFileCases,
  listFilesCases,
  updateFileCases
} from '../../../../services/fileCases.service'
import { fetchRoles } from '../../../../services/role.service'
import dataArray from './dataTable'
import { DocumentCaseStyle } from './styled'

function DocumentCase() {
  const dispatch = useDispatch()
  const { idcaso } = useParams()
  const { filesCases, loadingFile } = useSelector(
    (state) => state.fileCasesApiReducer
  )
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { setRolId, visible } = useValidateRole()
  const { roles } = useSelector((state) => state.roleApiReducer)
  const { DataFilter, setDataValueFilter } = useFilter(filesCases, '', 'role')

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    roles.length <= 0 && dispatch(fetchRoles())
  }, [dispatch])

  useEffect(() => {
    dispatch(setDataCreate(dataCreateForm.caseDocument))
    dispatch(setDataTitle('Documentos del caso'))
  }, [dispatch])

  useEffect(() => {
    dispatch(listFilesCases(idcaso))
  }, [dispatch, idcaso])

  function handleChange(item) {
    dispatch(notifyDownload(item))
  }

  function handleFilter(e) {
    const targ = e.target
    setDataValueFilter(targ.value)
  }

  async function handleCreateFileCase(params) {
    await dispatch(createFileCases({ ...params, idcaso }))
  }

  const handleEditFileCase = async ({ statusId, fileId, index }) => {
    if (statusId === 1) {
      statusId = 2
    } else {
      statusId = 1
    }
    const data = {
      statusId,
      fileId
    }
    await dispatch(updateFileCases({ data, index }))
  }

  async function handleDeleteFileCase({ fileName, index }) {
    await dispatch(deleteFileCases({ fileName, index }))
  }

  return (
    <Wrapper>
      <DocumentCaseStyle>
        <Search
          create='Agregar documento'
          back
          options={
            visible
              ? {
                  opts: roles,
                  label: 'Rol',
                  func: handleFilter
                }
              : false
          }
        />

        {loadingFile ? (
          <Loading />
        ) : DataFilter && DataFilter.length > 0 ? (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: '1fr 100px 150px 1fr 40px 40px 40px'
              }}
              headContent={[
                { type: 'default', label: 'Nombre' },
                { type: 'default', label: 'Peso' },
                { type: 'default', label: visible ? 'Rol' : 'Formato' },
                { type: 'default', label: 'Usuario' },
                { type: 'default', label: '' },
                { type: 'default', label: '' },
                { type: 'default', label: '' }
              ]}
              bodyContent={dataArray(DataFilter, visible, handleChange)}
            ></TableGrid>
          </>
        ) : (
          <NotFound text='No hay documentos.' />
        )}
      </DocumentCaseStyle>

      <Modal id='modalCrear'>
        <ModalCreate styled='document' handleCreate={handleCreateFileCase} />
      </Modal>
      <Modal id='modalEnable'>
        <ModalAlert handleConfirm={handleEditFileCase} />
      </Modal>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteFileCase} />
      </Modal>
    </Wrapper>
  )
}

export default DocumentCase
