import { createSlice } from '@reduxjs/toolkit'
import { characterApiActions } from './characterApi.actions'

const characterAPI = createSlice({
  name: 'characterApi',
  initialState: {
    characters: [],
    loadingCharacter: false,
    currentRequestId: undefined,
    error: null
  },
  extraReducers: characterApiActions
})

const characterApiReducer = characterAPI.reducer

export { characterApiReducer }
