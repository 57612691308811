import styled from 'styled-components'

export const CaseStyle = styled.div`
  .c-cont-paragraph {
    background: white;
    padding: 3rem;
    p {
      margin-left: 1rem;
    }
    h6 {
      margin-top: 1rem;
    }
  }
`
