export default function dataArray(getDataTable) {
  // let { path } = useRouteMatch()
  const formarDate = (value) => {
    const date = new Date(value)
    const months = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ]
    const formatDate = `
      ${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`

    return formatDate
  }

  const data = getDataTable.map((item) => [
    [
      <span key={`${item.id}_name`}>
        <b>Nombre:</b>
        {item.fielName}
      </span>
    ],
    [
      <span key={`${item.id}_date`}>
        <b>Fecha:</b>
        {formarDate(item.fileDate)}
      </span>
    ],
    [
      <span key={`${item.id}_name`}>
        <b>Formato:</b>
        {item.file_format}
      </span>
    ]
  ])
  return data
}
