import styled from 'styled-components'

export const CardInfoReportStyle = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  background: var(--primaryColorLight);
  border-radius: 10px;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  margin: 1rem 0;
  i {
    width: 50px;
    height: 50px;
    font-size: 3rem;
    margin: 0;
    color: var(--primaryColorDark);
  }
  p,
  h6 {
    font-weight: bold;
    margin: 0;
  }
  h6 {
    line-height: 1 !important;
  }
`
