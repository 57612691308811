import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HomeStyle } from './styled'

function Home() {
  const { nav } = useSelector((state) => state.LoginApiReducer)

  const item2 = []
  const newArray = []

  nav.map((item, index) => item2.push(item))

  for (let i = 0; i < item2.length; i++) {
    const array2 = Object.values(item2[i])

    newArray.push(array2)
    if (array2[0] === 'SSL') {
      for (let j = 0; j < array2.length; j++) {
        if (j === 0) {
          array2[j] = 'Salas'
        }
      }
    }
  }

  return (
    <HomeStyle>
      {newArray.map((item, index) => (
        <div
          // hp={item.label}
          key={`${index}-home`}
          className='c-item'
          data-img={item[1]}
        >
          <Link
            to={item[2]}
            // disabled={item.icon === 'reportes'}
            className='c-item-link'
          >
            <span>{item[0]}</span>
          </Link>
        </div>
      ))}
    </HomeStyle>
  )
}

export default Home
