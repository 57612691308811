import {
  createProtocols,
  deleteProtocols,
  listProtocols,
  updateProtocols
} from '../../../services/protocols.service'

const protocolsApiActions = {
  [listProtocols.pending]: (state) => {
    state.loadingProtocols = true
  },
  [listProtocols.fulfilled]: (state, { payload }) => {
    const { data } = payload
    state.protocols = data
    state.loadingProtocols = false
  },
  [listProtocols.rejected]: (state, action) => {
    state.error = action.error
    state.loadingProtocols = false
  },

  [createProtocols.fulfilled]: (state, { payload }) => {
    state.protocols.unshift(payload)
  },

  [updateProtocols.fulfilled]: (state, { payload }) => {
    const { data } = payload
    const itemSelected = state.protocols.findIndex(
      (item) => item.id === data.id
    )
    state.protocols.splice(itemSelected, 1, data)
  },

  [deleteProtocols.fulfilled]: (state, { payload }) => {
    const { id } = payload
    const newItems = state.protocols.filter((item) => item.id !== id)
    state.protocols = newItems
  }
}

export { protocolsApiActions }
