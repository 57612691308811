import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, patch, post } from './request.service'

const nameCollection = 'userRoom'

const listRoom = createAsyncThunk('room/list', async () => {
  const response = await get(nameCollection)
  return { data: response.data.body }
})

const listGuestRoom = createAsyncThunk('room/listUsers', async (params) => {
  const response = await get(`${nameCollection}/listUsers/${params.id}`)
  return { data: response.data.body }
})

const createRoom = createAsyncThunk(
  'room/create',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading', errors: [] }))
    try {
      const response = await post('room', params)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La sala ha sido creada correctamente.',
          errors: []
        })
      )
      return response
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'La sala no ha sido creada.',
          errors: []
        })
      )
      throw error
    }
  }
)

const deleteRoom = createAsyncThunk(
  'room/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading', errors: [] }))
    try {
      const response = await destroy(`room/${params.id}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La sala ha sido eliminada correctamente.',
          errors: []
        })
      )
      return { response, params }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'La sala no ha sido eliminada.',
          errors: []
        })
      )
      throw error
    }
  }
)

const inviteRoom = createAsyncThunk(
  'room/invite',
  async ({ id, idx, data, type }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading', errors: [] }))
    try {
      let params
      if (type) {
        const { temp_xlsx } = data
        params = new FormData()
        params.append('temp_xlsx', temp_xlsx[0])
      } else {
        params = data
      }
      const response = await post(`room/${id}/save_users`, params)

      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La sala no ha sido eliminada.',
          errors: []
        })
      )
      const { reportUsers } = response.data.body
      const reportReject = reportUsers.filter((e) => e.status === 'rejected')
      if (reportReject.length > 0) {
        const arrErr = reportReject.map((e) => e.reason.errors[0])

        dispatch(
          setNewResponse({
            state: 'sucess',
            message: 'los siguentes usuarios no se han agregado:',
            errors: arrErr
          })
        )
      } else {
        dispatch(
          setNewResponse({
            state: 'sucess',
            message: 'Los usuarios se han agregado correctamente.',
            errors: []
          })
        )
      }
      return { response: response.data.body, id, idx }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'Los usuarios no se han podido agregar.',
          errors: []
        })
      )
      throw error
    }
  }
)
const roomPostTerms = createAsyncThunk('room/getTerms', async (params) => {
  const resolve2 = await patch(`recording/updateTerms`)
  const resolve = await patch(`${nameCollection}/accept_terms/room/${params}`)
  return [resolve, resolve2]
  // return resolve
})

export {
  listRoom,
  createRoom,
  deleteRoom,
  inviteRoom,
  roomPostTerms,
  listGuestRoom
}
