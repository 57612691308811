import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, InputField } from 'ui-components'
import { Modal, ModalAlert } from '../../components'
import { setDataTemp, setNewResponse } from '../../redux/reducers'
import login from '../../services/session.service'
import { LoginStyle } from './styled'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const modalElm = document.querySelector('#ModalAlert')
  const { loadingResponse, isLogged } = useSelector(
    (state) => state.LoginApiReducer
  )

  useEffect(() => {
    dispatch(setNewResponse(loadingResponse))
  }, [dispatch, loadingResponse])

  useEffect(() => {
    if (isLogged) history.push('/bienvenida')
  }, [isLogged, history])

  async function handleClick(e) {
    e.preventDefault()
    const credentials = {
      email,
      password
    }
    await dispatch(login(credentials))
  }

  useEffect(() => {
    if (loadingResponse.state === 'error') {
      dispatch(setDataTemp({ title: 'Alerta' }))
      modalElm && modalElm.classList.toggle('is-visible')
    }
    return () => {
      dispatch(
        setNewResponse({
          state: 'idle',
          message: '',
          errors: []
        })
      )
    }
  }, [loadingResponse])

  return (
    <LoginStyle>
      <form onSubmit={handleClick}>
        <InputField
          type='email'
          label='Correo'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <InputField
          type='password'
          label='Contraseña'
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <Button label='Ingresar' type='submit' />
      </form>

      <Modal id='ModalAlert'>
        <ModalAlert></ModalAlert>
      </Modal>
    </LoginStyle>
  )
}
