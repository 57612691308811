import styled from 'styled-components'

export const FormRoomStyle = styled.div`
  form {
    padding: 1.5rem 1.5rem 0 1.5rem;
    overflow: auto;
    max-height: 70vh;
  }

  .btnItem {
    width: 100%;
    max-width: 200px;
    margin: 1rem auto;
    display: block;
  }
  .c-itemConts {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: flex-end; */
  }

  .ItemCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem auto;
    &-user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--primaryColorLight);
      padding-bottom: 5px;
    }
    &-item {
      display: flex;
      margin-left: 10px;
    }
    /* &:nth-child(3) {
      grid-column: 1 / 3;
    } */
  }
  .InputItem,
  .SelectItem {
    width: 100%;
  }

  .c-title-create {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }
`
