import { sendNotification } from '../../../services/notification.service'
export const NotificationApiActions = {
  /**
   * pendiente
   */
  [sendNotification.pending]: (state, { payload }) => {
    state.loadingNotifications = false
  },
  [sendNotification.fulfilled]: (state, { payload }) => {
    // state.notification.push(payload)
    state.loadingNotifications = true
  },
  [sendNotification.error]: (state, { payload }) => {
    state.loadingNotifications = true
  }
}
