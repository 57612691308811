function useFormData() {
  // const [dataForm, setDataForm] = useState(null);
  // const [objData, setObjData] = useState();
  // useEffect(() => {
  //   if (dataForm) {
  //     const form = new FormData(dataForm);
  //   }
  // }, [dataForm]);
  // return {
  //   setDataForm,
  //   objData,
  // };
}

export default useFormData
