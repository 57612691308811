import styled from 'styled-components'

export const ReportSectionStyle = styled.div`
  .c-contCards {
    display: grid;
    //grid-template: max-content/repeat(auto-fill, minmax(300px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    justify-content: center;
    &-item {
      > div {
        grid-template: 1fr / 1fr;
      }
      background: white;
      .c-card-img {
        display: none;
      }
    }
  }
`
