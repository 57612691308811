import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from 'ui-components'
import { dataCreateForm } from '../../../assets/data/dataCreate'
import {
  Card,
  Modal,
  ModalAlert,
  ModalCreate,
  ModalEdit,
  NotFound,
  Search,
  Wrapper
} from '../../../components'
import { setDataCreate, setDataTitle } from '../../../redux/reducers'
import {
  createOrientation,
  deleteOrientation,
  listOrientation,
  updateOrientation
} from '../../../services/orientation.service'
import { OrientationStyle } from './styled'

function Orientation() {
  const dispatch = useDispatch()

  const { orientation, loadingOrientation } = useSelector(
    (state) => state.orientationApiReducer
  )
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)

  useEffect(() => {
    dispatch(setDataTitle('Orientación para el estudiante'))
    dispatch(setDataCreate(dataCreateForm.orientation))
  }, [dispatch])

  useEffect(() => {
    orientation.length <= 0 && dispatch(listOrientation())
  }, [dispatch])

  const HandleCreateOrientation = async (data) => {
    await dispatch(createOrientation(data))
    dispatch(listOrientation())
  }

  const HandleDeleteOrientation = async (params) => {
    await dispatch(deleteOrientation(params))
  }

  const HandleUpdateOrientation = async ({ title, file, link }) => {
    const newData = {
      id: dataTemp.id,
      title,
      file,
      link,
      idx: dataTemp.idx
    }
    await dispatch(updateOrientation(newData))
    dispatch(listOrientation())
  }
  return (
    <Wrapper>
      <OrientationStyle>
        <Search create='Agregar orientación' />
        {loadingOrientation ? (
          <Loading />
        ) : orientation && orientation.length > 0 ? (
          <div className='c-contCards'>
            {orientation.map((item, idx) => (
              <div key={`${item.id}-orientation`} className='c-contCards-item'>
                <Card
                  options={{
                    image: item.orientationPath,
                    title: item.title,
                    link: item.link
                  }}
                  dataEdit={{
                    title: 'Editar orientación',
                    titleDelete: '¿Está seguro de eliminar esta orientación?',
                    id: item.id,
                    idx: idx,
                    form: [
                      {
                        key: '2-editOrienation',
                        label: 'link',
                        type: 'url',
                        name: 'link',
                        minWords: 5,
                        value:
                          item.link === 'undefined' ? 'Pendiente' : item.link
                      },
                      {
                        key: '1-editOrienation',
                        label: 'Título',
                        type: 'text',
                        minWords: 5,
                        name: 'title',
                        value: item.title
                      },
                      {
                        key: '3-editOrienation',
                        label: 'Imagen',
                        type: 'file',
                        dataIcon: 'upload',
                        required: false,
                        name: 'file',
                        value: ''
                      }
                    ]
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <NotFound text='No hay orientaciones creadas.' />
        )}
      </OrientationStyle>
      <Modal id='modalCrear'>
        <ModalCreate styled='category' handleCreate={HandleCreateOrientation} />
      </Modal>
      <Modal id='modalEdit'>
        <ModalEdit styled='category' handleEdit={HandleUpdateOrientation} />
      </Modal>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={HandleDeleteOrientation} />
      </Modal>
    </Wrapper>
  )
}

export default Orientation
