import { useSelector } from 'react-redux'
import { Button, Text } from 'ui-components'
import { TitleStyle } from './styled'

function Title({ type, align, back }) {
  const { dataTitle } = useSelector((state) => state.utilitiesReducer)
  const goBack = () => window.history.back()
  return (
    <TitleStyle>
      {back && (
        <Button
          onClick={() => goBack()}
          addClass='button-icon'
          icon='keyboard_arrow_left'
        />
      )}
      <Text type={type} text-align={align} addClass='c-title'>
        {dataTitle}
      </Text>
    </TitleStyle>
  )
}

export { Title }
