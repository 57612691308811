import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { InputControl, List, Text } from 'ui-components'
import { postTermsRecording } from '../../services/recording.service'
import { roomPostTerms } from '../../services/room.service'
import { ConsentStyle } from './styled'

function Consent({ recordingTerm }) {
  const { params } = useRouteMatch()
  const dispatch = useDispatch()

  const handleCheck = () => {
    const modalElm = document.querySelector(`#consent`)
    modalElm && modalElm.classList.toggle('is-visible')
    if (recordingTerm) {
      dispatch(postTermsRecording())
    } else {
      dispatch(roomPostTerms(params.idroom))
    }
  }

  return (
    <ConsentStyle>
      <div>
        <Text type='h5' text-align='center' addClass='c-title'>
          Consentimiento informado Simulador Cámara de Gesell
        </Text>
        <Text type='p'>
          Estoy de acuerdo en que se me ha informado y despejado las dudas con
          anterioridad por parte de los tutores encargados del curso, en un
          lenguaje pertinente y claro.
        </Text>
        <Text type='p'>
          Se me ha explicado de forma detallada los siguientes puntos:
        </Text>
        <List
          type='decimal'
          arrItems={[
            'El espacio es netamente académico, por lo tanto, no corresponde a la realidad total (por nombres y fechas) de los sucesos que se manifiestan, sin embargo, es fiel al objetivo de aprendizaje que tiene el curso.',
            'La información al ser de carácter académico será evaluada por el tutor o director encargado del espacio en cualquier momento.',
            'Se deben respetar los roles asignados para cada estudiante/tutor/director, esto con el fin de mantener la dinámica que se efectuará durante el ejercicio académico.',
            'Se reconoce la importancia de disponer el espacio físico en el cual se estará durante la sesión, con el fin de evitar distractores, optimizar la atención e interacción en este escenario de total respeto.',
            'Si se presenta alguna dificultad de orden personal o hay alguna afectación emocional por el caso a trabajar, comunicará al tutor encargado para generar un acompañamiento acorde a la necesidad manifestada.',
            'Se reconoce que las grabaciones obtenidas en este escenario solo tienen fines académicos y que la interacción que se logre allí y el uso de las mismas, corresponden a ejercicios de retroalimentación articulados a la estrategia de aprendizaje del curso.'
          ]}
        ></List>

        <Text type='p' text-align='justify'>
          En este orden comprendo todos estos puntos y me hago responsable de
          mis propósitos con el espacio académico.
        </Text>
      </div>
      <InputControl
        type='checkbox'
        name='name_1'
        label='Aceptar consentimiento.'
        onChange={handleCheck}
      />
    </ConsentStyle>
  )
}

export { Consent }
