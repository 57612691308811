import { createSlice } from '@reduxjs/toolkit'
import { categoryApiActions } from './categoryAPI.actions'

const categoryApi = createSlice({
  name: 'catgoryApi',
  initialState: {
    category: [],
    loadingCategory: false,
    loadingResponse: {
      state: 'idle',
      message: ''
    },
    currentRequestId: undefined,
    error: null
  },
  extraReducers: categoryApiActions
})

const categoryApiReducer = categoryApi.reducer

export { categoryApiReducer }
