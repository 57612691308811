import styled from 'styled-components'

export const ReportStyle = styled.div`
  .view-description {
    color: var(--primaryColor);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin: 0 auto;
  }
  .c-contCards {
    display: grid;
    //grid-template: max-content/repeat(auto-fill, minmax(300px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    justify-content: center;
    &-item {
      background: white;
    }
  }
  .c-contCardsInfo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    gap: 5px;
    @media screen and (min-width: 1600px) {
      width: 1200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`
