import { useSelector } from 'react-redux'
import { Icon, Loading, Text } from 'ui-components'
import { Form } from '../../Form'
import { ModalEditStyle } from './styled'
// TODO:structure, submir, objData

function ModalEdit({ handleEdit, styled }) {
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const { newResponse } = useSelector((state) => state.utilitiesReducer)

  return (
    <ModalEditStyle aria-label={dataTemp.title}>
      <Text type='h5' addClass='c-title-edit'>
        <Icon nameIcon='edit' /> {dataTemp.title}
      </Text>
      {newResponse.state !== 'idle' ? (
        newResponse.state === 'loading' ? (
          <Loading />
        ) : (
          <div className='c-contInfo-modal'>
            <Text type='h6'> {newResponse.message} </Text>{' '}
          </div>
        )
      ) : (
        <div className={styled}>
          <Form structure={dataTemp.form} submitFn={handleEdit} />
        </div>
      )}
    </ModalEditStyle>
  )
}

export { ModalEdit }
