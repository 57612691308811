import { Fragment, memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setHiddenMedia } from '../../redux/reducers'
import NavDeskopt from './NavDeskopt'
import { NavMobile } from './NavMobile'

function NavMemu({ hidden }) {
  const { nav } = useSelector((state) => state.LoginApiReducer)

  const [StateMedia, setStateMedia] = useState({
    matches: window.innerWidth > 1180
  })
  const dispatch = useDispatch()
  useEffect(() => {
    const requestMedia = window.matchMedia('(min-width: 1180px)')
    requestMedia.addEventListener('change', setStateMedia, false)
    return () =>
      requestMedia.removeEventListener('change', setStateMedia, false)
  }, [dispatch, StateMedia])

  useEffect(() => {
    dispatch(setHiddenMedia(StateMedia.matches))
  }, [dispatch, StateMedia.matches])
  return (
    <Fragment>
      {StateMedia && !StateMedia.matches ? (
        <NavMobile data={nav} hidden={hidden.nav} />
      ) : (
        <NavDeskopt data={nav} hidden={hidden.nav} />
      )}
    </Fragment>
  )
}

const Nav = memo(NavMemu)

export { Nav }
