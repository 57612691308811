const formCreateRoomActions = {
  setOptionsUser(state, { payload }) {
    state.optionsUser = payload
  },
  setUsersList(state, { payload }) {
    state.userList = payload
  },
  setOptionsCategory(state, { payload }) {
    state.optionsCategory = payload
  },
  setOptionsCases(state, { payload }) {
    state.optionsCases = payload
  }
}

export { formCreateRoomActions }
