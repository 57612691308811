import styled from 'styled-components'

export const SearchStyle = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content);
  align-items: center;
  gap: 10px;
  margin: 1.5rem 0;
  /* width: max-content; */
  .c-search-cont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    grid-row: 2 / 3;
  }
`
