import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Card, Search, Wrapper } from '../../../../../components'
import { setDataTitle } from '../../../../../redux/reducers'
import { ReportSectionStyle } from './styled'

function ReportSection() {
  const dispatch = useDispatch()
  // const {idUser} = useParams()
  const { url } = useRouteMatch()
  const data = [
    { title: 'Grabaciones descargadas', link: `${url}/reporte-grabaciones` },
    { title: 'Sesiones', link: `${url}/reporte-sesiones` },
    { title: 'Material de apoyo', link: `${url}/reporte-documentos` },
    {
      title: 'Material de apoyo de los casos',
      link: `${url}/reporte-documentos-caso`
    }
  ]
  useEffect(() => {
    dispatch(setDataTitle('Reporte por secciones'))
  }, [dispatch])
  return (
    <Wrapper>
      <ReportSectionStyle>
        <Search back />
        <div className='c-contCards'>
          {data.map((item, idx) => (
            <div key={`reportSection-${idx}`} className='c-contCards-item'>
              <Card
                options={{
                  title: item.title,
                  navigation: item.link
                }}
              />
            </div>
          ))}
        </div>
      </ReportSectionStyle>
    </Wrapper>
  )
}

export default ReportSection
