import { fetchRoles } from '../../../services/role.service'

const roleApiActions = {
  [fetchRoles.pending]: (state) => {
    state.loadingRol = true
  },
  [fetchRoles.fulfilled]: (state, { payload }) => {
    const arrRoles = payload?.response.map((role) => {
      return { value: role.roleName, id: role.roleId }
    })
    state.roles = arrRoles
    state.loadingRol = false
  },
  [fetchRoles.rejected]: (state, action) => {
    state.error = action.error
    state.loadingRol = false
  }
}

export { roleApiActions }
