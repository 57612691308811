import { useEffect, useState } from 'react'

export const useValidateRole = () => {
  const [rolId, setRolId] = useState()
  const [visible, setVisible] = useState(false)

  /**
   * visible false
   * status false - true
   * f || f = f - estudiante / no ve aco, no ve crear
   * f || t = t - estudiante / si ve aco, no ve crear
   * t || t = t - admin / si
   * t || f = t -admin / si
   */

  useEffect(() => {
    switch (rolId) {
      case 'X1dI':
        setVisible(true)
        break
      case 'DyIe':
        setVisible(true)
        break
      case 'IbQJ':
        break
      case 'pnWt':
        break

      default:
        break
    }
  }, [rolId])

  return {
    setRolId,
    visible
  }
}
