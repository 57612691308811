import styled from 'styled-components'

export const HelpStyle = styled.div`
  .c-contCards {
    display: grid;
    //grid-template: max-content/repeat(auto-fill, minmax(300px, 1fr));
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    justify-content: center;

    &-item {
      background: white;
    }
  }
  .c-itemConts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .ItemCont:first-child {
      grid-column: 1 / 4;
    }
  }
  .c-card-img figure img {
    object-position: 20% 15%;
  }
`
