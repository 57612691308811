import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon, Loading, Text } from 'ui-components'
import { Form } from '../../'
import { ModalCreateStyle } from './styled'
// TODO:  submit
function ModalCreate({ handleCreate, styled }) {
  const [DataCreate, setDataCreate] = useState({})
  const { dataCreate, newResponse } = useSelector(
    (state) => state.utilitiesReducer
  )

  useEffect(() => {
    setDataCreate(dataCreate)
  }, [dataCreate])

  return (
    <ModalCreateStyle aria-label={DataCreate.title}>
      <Text type='h5' addClass='c-title-create'>
        <Icon nameIcon='add' /> {DataCreate.title}
      </Text>
      {newResponse.state !== 'idle' ? (
        newResponse.state === 'loading' ? (
          <Loading />
        ) : (
          <div className='c-contInfo-modal'>
            <Text type='h6'> {newResponse.message} </Text>{' '}
          </div>
        )
      ) : (
        <div className={styled}>
          <Form structure={DataCreate.form} submitFn={handleCreate} />
        </div>
      )}
    </ModalCreateStyle>
  )
}

export { ModalCreate }
