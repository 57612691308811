import { createSlice } from '@reduxjs/toolkit'
import { fileApiActions } from './fileAPI.actions'

const fileApi = createSlice({
  name: 'fileApi',
  initialState: {
    files: [],
    fileTypes: [],
    loadingFile: false
  },
  extraReducers: fileApiActions
})

const fileApiReducer = fileApi.reducer

export { fileApiReducer }
