import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, post, put } from './request.service'

const className = 'orientation'

const listOrientation = createAsyncThunk('orientation/list', async () => {
  const response = await get(className)
  return response
})

const createOrientation = createAsyncThunk(
  'orientation/create',
  async ({ title, link, file }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))

    try {
      const params = new FormData()
      title && params.append('title', title)
      link && params.append('link', link)
      file && params.append('img/orientation', file[0])

      const response = await post(className, params)
      const newOrientation = response.data.body
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La orientación ha sido creada correctamente.'
        })
      )
      return newOrientation
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La orientación no ha sido creada correctamente.'
        })
      )
      throw error
    }
  }
)

const updateOrientation = createAsyncThunk(
  'orientation/update',
  async ({ id, title, file, idx, link }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const params = new FormData()
      title && params.append('title', title)
      link && params.append('link', link)
      file && params.append('img/orientation', file[0])

      const response = await put(`${className}/${id}`, params)
      const editOrientation = response.data.body
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La orientación ha sido editada correctamente.'
        })
      )
      return { editOrientation, idx }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La orientación no ha sido editada correctamente.'
        })
      )
      throw error
    }
  }
)

const deleteOrientation = createAsyncThunk(
  'orientation/delete',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await destroy(`${className}/${params.id}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La orientación ha sido eliminada correctamente.'
        })
      )
      return { params, response }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'La orientación no ha sido eliminada.'
        })
      )
    }
  }
)

export {
  listOrientation,
  createOrientation,
  updateOrientation,
  deleteOrientation
}
