import styled from 'styled-components'

export const FormStyle = styled.div`
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow: auto;
  max-height: 70vh;

  .btnItem {
    width: 100%;
    max-width: 200px;
    margin: 1rem auto;
    display: block;
  }
  .ItemCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem auto;
    &-item {
      display: flex;
      margin-left: 10px;
    }
  }
  .InputItem,
  .SelectItem {
    width: 100%;
  }
  .c-textarea {
    width: 100%;
  }
  textarea {
    max-width: 100%;
    border-radius: 15px;
  }
`
