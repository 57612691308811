import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, post, put } from './request.service'

const nameCollection = 'protocol'

const listProtocols = createAsyncThunk('protocol/list', async () => {
  const response = await get(`${nameCollection}`)
  return { data: response.data.body }
})

const updateProtocols = createAsyncThunk(
  'protocol/update',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await put(
        `${nameCollection}/${params.info.fileId}`,
        params.info
      )
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido editado correctamente.'
        })
      )
      return { data: response.data.body, idx: params.index }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'El archivo no ha sido editado correctamente.'
        })
      )
      throw error
    }
  }
)

const deleteProtocols = createAsyncThunk(
  'protocol/delete',
  async ({ fileName, id }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))

    try {
      const response = await destroy(`${nameCollection}/${fileName}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido eliminado correctamente.'
        })
      )
      return { response, id }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo no ha sido eliminado correctamente.'
        })
      )
      throw error
    }
  }
)

const createProtocols = createAsyncThunk(
  'protocol/create',
  async ({ file, roleId }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const params = new FormData()
      params.append('files', file[0])
      params.append('roleId', roleId)
      const response = await post(nameCollection, params)
      const newDocument = response.data.body
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido cargado correctamente.'
        })
      )
      return newDocument
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'error',
          message: 'El archivo no pudo ser cargado.'
        })
      )
      throw error
    }
  }
)

export { listProtocols, deleteProtocols, createProtocols, updateProtocols }
