import styled from 'styled-components'

export const RecordingsStyle = styled.div`
  .ui-gridTable-body {
    &-row {
      img {
        max-width: 80px;
      }
    }
    &-item {
      &:nth-child(1) {
        grid-column: 1 / 4;
      }
      &:nth-child(2) {
        grid-column: 4 / span 2;
      }
      .c-item-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          margin: 0;
        }
      }
      .c-item {
        &-record,
        &-user {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          align-items: center;
        }
        &-user {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  @media screen and (max-width: 1020px) {
    .ui-gridTable-head {
      display: none;
    }
    .ui-gridTable-body-row {
      .c-item {
        &-record {
          grid-template-columns: repeat(2, 1fr);
          &-name {
            grid-column: 2/ 3;
          }
          figure {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
          }
        }
      }
    }
  }
  @media screen and (max-width: 830px) {
    .ui-gridTable-body {
      &-item {
        .c-item-user {
          grid-template-columns: 1fr;
          text-align: left;
          gap: 1rem;
        }
      }
      &-row {
        gap: 1rem;
      }
    }
  }

  @media screen and (max-width: 690px) {
    .ui-gridTable-body-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 0.4rem;
      .c-item-user {
        width: 200px;
      }
    }
  }
`
