import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Video } from 'ui-components'
import { Introduction } from '../../../components'
import { VideoIntroductionStyle } from './styled'

function VideoIntroduction() {
  const [navigation, setNavigation] = useState('/terminos-condiciones')
  const { profile } = useSelector((state) => state.LoginApiReducer)

  useEffect(() => {
    profile.acceptTerms.initialTerms === 1 && setNavigation('/secciones')
  }, [profile])
  if (profile.r.n === 'Administrador') {
    return (
      <VideoIntroductionStyle>
        <Introduction navigation={navigation} showBtn>
          <Video
            url='./assets/videos/admin_gesell_full.mp4'
            addClass='c-video'
            poster='./assets/images/posterDefault.png'
          ></Video>
        </Introduction>
      </VideoIntroductionStyle>
    )
  } else if (profile.r.n === 'Docente director') {
    return (
      <VideoIntroductionStyle>
        <Introduction navigation={navigation} showBtn>
          <Video
            url='./assets/videos/director_gesell_full.mp4'
            addClass='c-video'
            poster='./assets/images/posterDefault.png'
          ></Video>
        </Introduction>
      </VideoIntroductionStyle>
    )
  } else if (profile.r.n === 'Docente tutor') {
    return (
      <VideoIntroductionStyle>
        <Introduction navigation={navigation} showBtn>
          <Video
            url='./assets/videos/tutor_gesell_full.mp4'
            addClass='c-video'
            poster='./assets/images/posterDefault.png'
          ></Video>
        </Introduction>
      </VideoIntroductionStyle>
    )
  } else if (profile.r.n === 'Estudiante') {
    return (
      <VideoIntroductionStyle>
        <Introduction navigation={navigation} showBtn>
          <Video
            url='./assets/videos/estudiante_gesell_full.mp4'
            addClass='c-video'
            poster='./assets/images/posterDefault.png'
          ></Video>
        </Introduction>
      </VideoIntroductionStyle>
    )
  } else {
    return (
      <VideoIntroductionStyle>
        <Introduction navigation={navigation} showBtn>
          <Video
            url='./assets/videos/bgWelcome.mp4'
            addClass='c-video'
            poster='./assets/images/posterDefault.png'
          ></Video>
        </Introduction>
      </VideoIntroductionStyle>
    )
  }
}

export default VideoIntroduction
