import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Icon, Text } from 'ui-components'
import { AuditCaseStyled } from './styled'

function AuditCase() {
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const [dataBefore, setBefore] = useState({})
  const [dataAfter, setAfter] = useState({})
  const { after, before } = dataTemp

  useEffect(() => {
    setAfter(after && JSON.parse(after.replace(/(\n)/gm, '')))
    setBefore(before && JSON.parse(before.replace(/(\n)/gm, '')))
  }, [after, before])

  return (
    <AuditCaseStyled>
      <Text type='h5' addClass='c-title-audit'>
        <Icon nameIcon='description' />
        {dataTemp.title}
      </Text>
      <div className='c-contInfo-modal'>
        <div
          className='c-contInfo-modal--item'
          data-flex={dataTemp.action !== 'Actualizar'}
        >
          {dataBefore && (
            <div
              className='c-cont-before'
              data-border={dataTemp.action === 'Actualizar'}
            >
              {dataTemp.action === 'Actualizar' && <Text type='h5'>Antes</Text>}
              {dataBefore.title && (
                <>
                  <Text type='h6'>Título:</Text>
                  {dataBefore.title}
                </>
              )}
              {dataBefore.category && (
                <>
                  <Text type='h6'>Categoría</Text>
                  {dataBefore.category}
                </>
              )}
              {dataBefore.status && (
                <>
                  <Text type='h6'>Estado:</Text>
                  {dataBefore.status}
                </>
              )}
              {dataBefore.learning && (
                <>
                  <Text type='h6'>Intensiones de aprendizaje:</Text>
                  {dataBefore.learning}
                </>
              )}
              {dataBefore.introduction && (
                <>
                  <Text type='h6'>Párrafo inicial:</Text>
                  {dataBefore.introduction}
                </>
              )}
              {dataBefore.context && (
                <>
                  <Text type='h6'>Contexto del caso:</Text>
                  {dataBefore.context}
                </>
              )}
              {dataBefore.body && (
                <>
                  <Text type='h6'>Cuerpo del caso:</Text>
                  {dataBefore.body}
                </>
              )}
              {dataBefore.name && (
                <>
                  <Text type='h6'>Nombre:</Text>
                  {dataBefore.name}
                </>
              )}
              {dataBefore.physical && (
                <>
                  <Text type='h6'>Características físicas:</Text>
                  {dataBefore.physical}
                </>
              )}
              {dataBefore.psychological && (
                <>
                  <Text type='h6'>Características psicológicas:</Text>
                  {dataBefore.psychological}
                </>
              )}
              {dataBefore.performance && (
                <>
                  <Text type='h6'>
                    ¿Cuáles son sus posibilidades de actuación dentro de la
                    situación planteada?
                  </Text>
                  {dataBefore.performance}
                </>
              )}
              {dataBefore.interest && (
                <>
                  <Text type='h6'>Intereses:</Text>
                  {dataBefore.interest}
                </>
              )}
              {dataBefore.history && (
                <>
                  <Text type='h6'>Papel que juega dentro de la historia:</Text>
                  {dataBefore.history}
                </>
              )}
            </div>
          )}

          {dataAfter && (
            <div className='c-cont-after'>
              {dataTemp.action === 'Actualizar' && (
                <Text type='h5'>Después</Text>
              )}
              {dataAfter.title && (
                <>
                  <Text type='h6'>Título:</Text>
                  {dataAfter.title}
                </>
              )}
              {dataAfter.category && (
                <>
                  <Text type='h6'>Categoría</Text>
                  {dataAfter.category}
                </>
              )}
              {dataAfter.learning && (
                <>
                  <Text type='h6'>Intensiones de aprendizaje:</Text>
                  {dataAfter.learning}
                </>
              )}
              {dataAfter.introduction && (
                <>
                  <Text type='h6'>Párrafo inicial:</Text>
                  {dataAfter.introduction}
                </>
              )}
              {dataAfter.context && (
                <>
                  <Text type='h6'>Contexto del caso:</Text>
                  {dataAfter.context}
                </>
              )}
              {dataAfter.body && (
                <>
                  <Text type='h6'>Cuerpo del caso:</Text>
                  {dataAfter.body}
                </>
              )}
              {dataAfter.status && (
                <>
                  <Text type='h6'>Estado:</Text>
                  {dataAfter.status}
                </>
              )}
              {dataAfter.caseTitle && (
                <>
                  <Text type='h6'>Caso:</Text>
                  {dataAfter.caseTitle}
                </>
              )}
              {dataAfter.name && (
                <>
                  <Text type='h6'>Nombre:</Text>
                  {dataAfter.name}
                </>
              )}
              {dataAfter.physical && (
                <>
                  <Text type='h6'>Características físicas:</Text>
                  {dataAfter.physical}
                </>
              )}
              ,
              {dataAfter.psychological && (
                <>
                  <Text type='h6'>Características psicológicas:</Text>
                  {dataAfter.psychological}
                </>
              )}
              ,
              {dataAfter.performance && (
                <>
                  <Text type='h6'>
                    ¿Cuáles son sus posibilidades de actuación dentro de la
                    situación planteada?
                  </Text>
                  {dataAfter.performance}
                </>
              )}
              ,
              {dataAfter.interest && (
                <>
                  <Text type='h6'>Intereses:</Text>
                  {dataAfter.interest}
                </>
              )}
              ,
              {dataAfter.history && (
                <>
                  <Text type='h6'>Papel que juega dentro de la historia:</Text>
                  {dataAfter.history}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </AuditCaseStyled>
  )
}

export { AuditCase }
