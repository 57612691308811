import styled from 'styled-components'

export const DocumentsStyle = styled.div`
  .ui-gridTable-body {
    &-item {
      &:nth-child(1) {
        grid-column: 1 / 4;
      }
      &:nth-child(2) {
        grid-column: 4 / 8;
      }
      .c-item {
        &-document {
          display: grid;
          grid-template-columns: 1fr 100px 100px;
          gap: 0 10px;
        }
        &-user {
          display: grid;
          grid-template-columns: 1fr 40px 40px 40px;
          align-items: center;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .ui-gridTable-head {
      display: none;
    }
    .ui-gridTable-body {
      &-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 0 1rem;
      }
      &-item {
        .c-item {
          &-document {
            grid-template-columns: 1fr 1fr;
            width: 220px;
            span {
              &:nth-child(1) {
                grid-column: 1 / 3;
              }
              &:nth-child(2) {
                border-right: 1px solid;
              }
              &:nth-child(4) {
                grid-column: 1 / 3;
                grid-row: auto;
                font-weight: bold;
                padding-top: 5px;
              }
            }
          }
          &-user {
            gap: 5px;
          }
        }
      }
    }
  }
`
