import styled from 'styled-components'

export const ManageCaseStyle = styled.div`
  .c-contCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    &[data-center='true'] {
      justify-content: center;
    }
    @media screen and (max-width: 1180px) {
      justify-content: center;
    }
    &-item {
      background: white;
    }
  }
`
