import {
  deleteRecording,
  listRecording,
  postTermsRecording,
  updateRecording
} from '../../../services/recording.service'
import {
  getStorageValue,
  setStorageValue
} from '../../../utilities/js/localStorage'

const recordingActions = {
  [listRecording.pending]: (state) => {
    state.loadingRecording = true
  },
  [listRecording.fulfilled]: (state, { payload }) => {
    state.loadingRecording = false
    state.recording = payload?.data.body
  },
  [listRecording.reject]: (state) => {
    state.loadingRecording = false
  },

  [updateRecording.fulfilled]: (state, { payload }) => {
    const { editRecording } = payload
    const itemSelected = state.recording.findIndex(
      (item) => item.id === editRecording.id
    )
    state.recording.splice(itemSelected, 1, editRecording)
  },

  [deleteRecording.fulfilled]: (state, { payload }) => {
    const { params } = payload
    const newItems = state.recording.filter(
      (item) => item.record_id !== params.id
    )
    state.recording = newItems
  },

  [postTermsRecording.fulfilled]: (state) => {
    state.recordingAcceptTerms = true
    const res = getStorageValue('profile')
    res.acceptTerms.recordingsTerms = 1
    setStorageValue('profile', res)
  }
}

export { recordingActions }
