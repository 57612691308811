import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Loading, Text } from 'ui-components'
import { Card, Modal, ModalAlert } from '../../components'
import { setDataTemp, setLogged, setNewResponse } from '../../redux/reducers'
import { deleteSessionCookie, loginLTI } from '../../services/session.service'
import { LoginLTIStyle } from './styled'

function LoginLTI() {
  const { token, roles } = useParams()
  const [dataRol] = useState([]) // setDataRol
  const dispatch = useDispatch()
  const history = useHistory()
  const modalElm = document.querySelector('#ModalAlert')
  // const rol = [
  //   {
  //     idFn: 1,
  //     title: 'Administrador',
  //     image: 'assets/images/administrador.png'
  //   },
  //   {
  //     idFn: 2,
  //     title: 'Docente Director',
  //     image: 'assets/images/docenteDirector.png'
  //   },
  //   {
  //     idFn: 3,
  //     title: 'Docente Tutor',
  //     image: 'assets/images/docenteTutor.png'
  //   },
  //   {
  //     idFn: 4,
  //     title: 'Estudiante',
  //     image: 'assets/images/estudiante.png'
  //   }
  // ]

  const { isLogged, loadingResponse } = useSelector(
    (state) => state.LoginApiReducer
  )
  useEffect(() => {
    !token && window.location.replace('https://campus0b.unad.edu.co/campus/')
  }, [])

  useEffect(() => {
    if (isLogged) history.push('/bienvenida')
  }, [isLogged, history])

  useEffect(() => {
    dispatch(setLogged(false))
    localStorage.clear()
    deleteSessionCookie('token')
    deleteSessionCookie('refreshToken')
    // if (!roles) {
    const newRole = JSON.parse(roles)
    token && dispatch(loginLTI({ token, roleId: newRole[0] }))
    console.log('Role', newRole[0])
    // } else {
    // const arrRoles = JSON.parse(roles).sort()
    // const obj = []
    // // eslint-disable-next-line array-callback-return
    // rol.map((item, i) => {
    //   if (item.idFn === arrRoles[i]) {
    //     obj.push(item)
    //   }
    // })
    // setDataRol(obj)
    // }
  }, [token, roles])

  useEffect(() => {
    if (loadingResponse.state === 'error' && !roles) {
      dispatch(setDataTemp({ title: 'Alerta' }))
      modalElm && modalElm.classList.toggle('is-visible')
    }
    dispatch(setNewResponse(loadingResponse))
    return () => {
      dispatch(
        setNewResponse({
          state: 'idle',
          message: '',
          errors: []
        })
      )
    }
  }, [loadingResponse])

  const handleRol = ({ target }) => {
    const id = Number(target.dataset.id)
    dispatch(
      loginLTI({
        token,
        roleId: id
      })
    )
  }

  return (
    <LoginLTIStyle>
      {loadingResponse.status === 'idle' ? (
        <Loading />
      ) : roles && dataRol ? (
        <div className='c-cont'>
          <Text type='h2' text-align='center'>
            Escoja su rol
          </Text>
          <div className='c-contCards'>
            {dataRol.length > 0 &&
              dataRol.map((item, idx) => (
                <div className='c-contCards-item' key={idx} data-rol={item.id}>
                  <Card options={item} onFn={handleRol} />
                </div>
              ))}
          </div>
        </div>
      ) : (
        <Loading />
      )}

      <Modal id='ModalAlert'>
        <ModalAlert></ModalAlert>
      </Modal>
    </LoginLTIStyle>
  )
}

export default LoginLTI
