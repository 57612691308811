import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router'
import { Loading, TableGrid } from 'ui-components'
import { NotFound, Search, Wrapper } from '../../../../../../../components'
import { useSearch } from '../../../../../../../hooks'
import { setDataTitle } from '../../../../../../../redux/reducers'
import { getUserById } from '../../../../../../../redux/reducers/usersAPI'
import { listRecordings } from '../../../../../../../services/report.service'
import { fetchUsers } from '../../../../../../../services/user.service'
import { ReportUserSection } from '../styled'
import dataArray from './dataTable'

function ReportUserRecording() {
  const dispatch = useDispatch()
  const { params } = useRouteMatch()
  const [Hidden, setHidden] = useState(false)
  const [NameUser, setNameUser] = useState('')
  const { users, userById } = useSelector((state) => state.usersApiReducer)
  const { reportDataUser, loadingReport } = useSelector(
    (state) => state.reportApiReducer
  )
  const { DataSearch, setDataValue, setDataRequest } = useSearch(
    reportDataUser,
    '',
    'caseName'
  )

  function handleChange(e) {
    const targ = e.target
    setDataValue(targ.value)
  }

  useEffect(() => {
    setDataRequest(reportDataUser)
  }, [reportDataUser])

  useEffect(() => {
    DataSearch.length === 0 ? setHidden(true) : setHidden(false)
  }, [DataSearch])

  useEffect(() => {
    dispatch(listRecordings(params.idUser))
  }, [dispatch])

  useEffect(() => {
    NameUser && dispatch(setDataTitle(`Grabaciones descargadas - ${NameUser}`))
  }, [dispatch, NameUser])

  useEffect(() => {
    if (users.length <= 0) {
      dispatch(fetchUsers())
    } else {
      dispatch(getUserById(params.idUser))
    }
  }, [dispatch, users])

  useEffect(() => {
    if (userById && userById.length > 0) {
      const name = `${userById[0].name} ${userById[0].lastName}`
      setNameUser(name)
    }
  }, [userById])

  const option = {
    fileName: `Grabaciones_descargadas_de_${NameUser}`,
    datas: [
      {
        sheetHeader: ['Nombre', 'Fecha', 'Caso'],
        sheetData: DataSearch,
        sheetName: 'Grabaciones descargadas',
        columnWidths: ['10', '10', '10']
      }
    ]
  }

  return (
    <Wrapper>
      <ReportUserSection>
        <Search
          back
          download
          exportData={option}
          search={{
            label: 'Caso...',
            func: handleChange
          }}
        />
        {loadingReport ? (
          <Loading />
        ) : DataSearch && DataSearch.length > 0 ? (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: 'repeat(3, 1fr)'
              }}
              headContent={[
                { type: 'default', label: 'Nombre' },
                { type: 'default', label: 'Fecha' },
                { type: 'default', label: 'Caso' }
              ]}
              bodyContent={dataArray(DataSearch)}
              hidden={Hidden}
            ></TableGrid>
            {Hidden && <NotFound />}
          </>
        ) : DataSearch && DataSearch.length <= 0 ? (
          <NotFound text='Este usuario no ha descargado grabaciones.' />
        ) : null}
      </ReportUserSection>
    </Wrapper>
  )
}

export default ReportUserRecording
