import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Icon,
  InputAction,
  InputControl,
  InputField,
  Link,
  Loading,
  Select,
  Text
} from 'ui-components'
import { listRoomRoles } from '../../services/roleRoom.service'
import { FormInvitedStyle } from './styled'

function FormInvited({ submitFn }) {
  // const [dataValue, setDataValue] = useState({
  //   fullName: ''
  // })
  const dispatch = useDispatch()
  const [dataForm, setDataForm] = useState([])
  const [visible, setVisible] = useState(false)
  const { newResponse } = useSelector((state) => state.utilitiesReducer)
  const { rolesRoom } = useSelector((state) => state.roleRoomApiReducer)
  const [dataUsers, setDataUsers] = useState({ users: [] })
  const [newUser, setNewUser] = useState({})

  useEffect(() => {
    dispatch(listRoomRoles())
  }, [dispatch])

  const handleVisible = () => {
    setVisible(!visible)
  }

  const handleInput = ({ target }, _value = 'value') => {
    setDataForm({
      [target.name]: target[_value]
    })
  }

  const handleUser = ({ target }, _value = 'value') => {
    setNewUser({
      ...newUser,
      [target.name]: target[_value]
    })
    // <p>{rolesRoom[Number(item.roleId)].value}</p>
  }
  const handleSetUser = (e) => {
    e.preventDefault()
    const pattern = /\S+@\S+\.\S+/
    if (newUser.email && newUser.roleId && newUser.email.match(pattern)) {
      setDataUsers({
        users: [...dataUsers.users, newUser]
      })
      setNewUser({ email: '', roleId: '' })
    }
    document.querySelector('#refSelectRolUser select').value = ''
  }

  const handleDeleteUser = ({ $event, idx }) => {
    $event.preventDefault()
    setDataUsers({
      users: [
        ...dataUsers.users.slice(0, idx),
        ...dataUsers.users.slice(idx + 1)
      ]
    })
  }

  const handleSubmitMassive = (e) => {
    e.preventDefault()
    submitFn({ data: dataForm, type: 'massive' })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    submitFn({ data: dataUsers })
    setDataUsers({ users: [] })
  }

  return (
    <FormInvitedStyle>
      <Text type='h5' addClass='c-title-create'>
        <Icon nameIcon='add' addClass='btnAdd' /> Agregar usuarios
      </Text>
      {newResponse.state === 'idle' ? (
        <InputControl
          type='toggle'
          label={visible ? 'Importación masiva' : 'Agregar por correo'}
          onChange={handleVisible}
          addClass='c-toggle'
        />
      ) : null}
      {newResponse.state !== 'idle' ? (
        newResponse.state === 'loading' ? (
          <Loading />
        ) : (
          <div className='c-contInfo-modal'>
            <Text type='h6'> {newResponse.message} </Text>
            {newResponse.errors.length > 0 && (
              <>
                {newResponse.errors.map((err, idx) => (
                  <p key={idx}>{err}</p>
                ))}
              </>
            )}
          </div>
        )
      ) : (
        <>
          {visible ? (
            <form onSubmit={handleSubmitMassive}>
              <div className='c-itemConts'>
                <div className='ItemCont-upload'>
                  <Link
                    addClass='button-icon'
                    href={`./assets/docs/plantilla_user_import.xlsx`}
                    icon='vertical_align_bottom'
                    download
                    target='_blank'
                    label='Plantilla para cargue masivo.'
                  />
                  <InputAction
                    icon='upload'
                    label='Seleccione el archivo'
                    type='file'
                    name='temp_xlsx'
                    onChange={(event) => handleInput(event, 'files')}
                    accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  ></InputAction>
                </div>
                <Button
                  label='Agregar usuarios'
                  styled='secondary'
                  addClass='btnItem'
                  type='submit'
                ></Button>
              </div>
            </form>
          ) : (
            <div className='c-cont'>
              <div className='c-cont-Inputs'>
                <div className='ItemCont'>
                  <InputField
                    type='email'
                    label='Correo'
                    addClass='InputItem'
                    name='email'
                    value={newUser.email}
                    onChange={handleUser}
                    required={true}
                    id='refInput'
                  />
                </div>
                <div className='ItemCont'>
                  <Select
                    options={rolesRoom}
                    name='roleId'
                    nameIcon='keyboard_arrow_down'
                    placeholder='Seleccione el rol'
                    addClass='SelectItem'
                    onChange={handleUser}
                    required={true}
                    id='refSelectRolUser'
                  />
                </div>

                <Button
                  styled='secondary-icon'
                  icon='add'
                  addClass='btnAdd'
                  onClick={handleSetUser}
                ></Button>
              </div>

              <div className='ItemCont-user'>
                {dataUsers &&
                  dataUsers.users.map((item, idx) => (
                    <div key={`item-${idx}`} className='ItemCont-user-item'>
                      <Text type='p'>{item.email}</Text>
                      <Text type='p'>
                        {
                          rolesRoom.find((x) => x.id === Number(item.roleId))
                            .value
                        }
                      </Text>
                      <Button
                        styled='secondary-icon'
                        icon='close'
                        onClick={($event) =>
                          handleDeleteUser({ $event, idx, item })
                        }
                      />
                    </div>
                  ))}
              </div>
              <Button
                label='Agregar usuarios'
                styled='secondary'
                addClass='btnItem'
                type='submit'
                disabled={!dataUsers.users.length > 0}
                onClick={handleSubmit}
              ></Button>
            </div>
          )}
        </>
      )}
    </FormInvitedStyle>
  )
}

export { FormInvited }
