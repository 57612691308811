import Styled from 'styled-components'

export const ModalEditStyle = Styled.div`
    min-height: 130px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
    text-align: center;
    justify-content: space-between;
    overflow: hidden;
    .c-title-edit{
        background: var(--primaryColorDark);
        padding: .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        color: white;
        i {
            font-size: 1.5rem;
            width: max-content;
            height: max-content;
        }
    }

`
