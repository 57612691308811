import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Loading } from 'ui-components'
import { Icon, UserCard } from '../..'
import logo from '../../../assets/images/logoUnadWhite.svg'
import { useValidateRole } from '../../../hooks/ValidateRole/useValidateRole'
import { logout } from '../../../services/session.service'
import { NavDeskoptStyle } from './styled'

function NavDeskopt({ data, loading, hidden }) {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { setRolId } = useValidateRole()

  useEffect(() => {
    profile && setRolId(profile.r.i)
  }, [profile, setRolId])

  const handleLogout = async () => {
    await dispatch(logout())
  }
  return (
    <NavDeskoptStyle>
      <div className='c-nav-logo'>
        <img src={logo} alt='Logo de la UNAD' />
      </div>
      <div className='c-nav-navbar'>
        {loading ? (
          <Loading />
        ) : (
          hidden &&
          data.map((item, index) => (
            <div key={`${index}-nav`} className='c-nav-navbar--link'>
              <NavLink
                to={item.link}
                activeClassName='active'
                aria-current='page'
                // disabled={item.icon === 'reportes'}
              >
                <Icon iconName={item.icon} />
                <span>{item.label}</span>
              </NavLink>
            </div>
          ))
        )}
        <div className='c-nav-navbar--link help'>
          <NavLink to='/ayuda' activeClassName='active' aria-current='page'>
            <Icon iconName='ayuda' />
            <span>ayuda</span>
          </NavLink>
        </div>
      </div>
      <div className='c-nav-user'>
        <UserCard></UserCard>
      </div>
      <button title='cerrar sesion' className='c-nav-logout'>
        <Icon iconName='logout' onFn={handleLogout} />
      </button>
    </NavDeskoptStyle>
  )
}

export default NavDeskopt
