import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Loading, Text } from 'ui-components'
import { dataCreateForm } from '../../../../assets/data/dataCreate'
import {
  Accordion,
  Modal,
  ModalAlert,
  ModalCreate,
  ModalEdit,
  NotFound,
  Search,
  Wrapper
} from '../../../../components'
import { setDataCreate, setDataTitle } from '../../../../redux/reducers'
import {
  createCharacter,
  deleteCharacter,
  listCharacter,
  updateCharacter
} from '../../../../services/character.service'
import { CharacterStyled } from './styled'

function Character() {
  const dispatch = useDispatch()
  const { idcaso } = useParams()
  const { profile } = useSelector((state) => state.LoginApiReducer)

  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const { characters, loadingCharacter } = useSelector(
    (state) => state.characterApiReducer
  )

  useEffect(() => {
    dispatch(listCharacter(idcaso))
  }, [dispatch])

  const handleCreateCharacter = async (dataform) => {
    const newDataBd = {
      ...dataform,
      case_id: Number(idcaso)
    }
    await dispatch(createCharacter(newDataBd))
  }

  const handleUpdateCharacter = async (dataForm) => {
    const data = {
      ...dataForm,
      case_id: Number(idcaso)
    }
    await dispatch(
      updateCharacter({ data, idx: dataTemp.idx, characterId: dataTemp.id })
    )
  }

  const handleDeleteCharacter = async (params) => {
    await dispatch(deleteCharacter(params))
  }

  useEffect(() => {
    dispatch(setDataCreate(dataCreateForm.caseCharacter))
    dispatch(setDataTitle('Personajes'))
  }, [dispatch])

  return (
    <Wrapper>
      <CharacterStyled>
        <Search create='Agregar personaje' back />
        {loadingCharacter ? (
          <Loading />
        ) : characters && characters.length > 0 ? (
          characters.map((item, idx) => (
            <Accordion
              key={idx}
              title={{
                strong: `Personaje ${idx + 1}`,
                text: ''
              }}
              idx={idx}
              dataEdit={{
                titleEdit: `Editar personaje ${idx + 1}`,
                titleDelete: `¿Está seguro de eliminar el personaje ${
                  idx + 1
                }?`,
                form: [
                  {
                    key: '2-caseChartCreate',
                    label: 'Características físicas',
                    type: 'textarea',
                    rows: '5',
                    name: 'physical',
                    value: item.physical
                  },
                  {
                    key: '3-caseChartCreate',
                    label: 'Características psicológicas',
                    type: 'textarea',
                    rows: '5',
                    name: 'psychological',
                    value: item.psychological
                  },
                  {
                    key: '4-caseChartCreate',
                    label:
                      '¿Cuáles son sus posibilidades de actuación dentro de la situación planteada?',
                    type: 'textarea',
                    rows: '5',
                    name: 'performance',
                    value: item.performance
                  },
                  {
                    key: '5-caseChartCreate',
                    label: 'Intereses',
                    type: 'textarea',
                    rows: '5',
                    name: 'interest',
                    value: item.interest
                  },
                  {
                    key: '6-caseChartCreate',
                    label: 'Papel que juega dentro de la historia',
                    type: 'textarea',
                    rows: '5',
                    name: 'history',
                    value: item.history
                  }
                ]
              }}
              id={item.characterId}
              role={profile.r}
            >
              <div className='c-tab-content-txt'>
                <Text type='p' addClass='c-title'>
                  Características físicas:
                </Text>
                <Text type='p'>{item.physical}</Text>
                <Text type='p' addClass='c-title'>
                  Características psicológicas:
                </Text>
                <Text type='p'>{item.psychological}</Text>
                <Text type='p' addClass='c-title'>
                  ¿Cuáles son sus posibilidades de actuación dentro de la
                  situación planteada?
                </Text>
                <Text type='p'>{item.performance}</Text>
                <Text type='p' addClass='c-title'>
                  Intereses
                </Text>
                <Text type='p'>{item.interest}</Text>
                <Text type='p' addClass='c-title'>
                  Papél que juega dentro de la historia
                </Text>
                <Text type='p'>{item.history}</Text>
              </div>
            </Accordion>
          ))
        ) : (
          <NotFound text='No hay personajes creados.' />
        )}
      </CharacterStyled>

      <Modal id='modalCrear' size='large'>
        <ModalCreate handleCreate={handleCreateCharacter} />
      </Modal>
      <Modal id='modalEdit' size='large'>
        <ModalEdit handleEdit={handleUpdateCharacter} />
      </Modal>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteCharacter} />
      </Modal>
    </Wrapper>
  )
}

export default Character
