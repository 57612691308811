import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Loading } from 'ui-components'
import { dataCreateForm } from '../../../assets/data/dataCreate'
import {
  Card,
  Modal,
  ModalAlert,
  ModalCreate,
  ModalEdit,
  NotFound,
  Search,
  Wrapper
} from '../../../components'
import { setDataCreate, setDataTitle } from '../../../redux/reducers'
import {
  createCategory,
  deleteCategory,
  listCategories,
  updateCategory
} from '../../../services/category.service'
import { ManageCaseStyle } from './styled'

//  const { REACT_APP_IMAGE_URL } = process.env

function ManageCase() {
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  const history = useHistory()
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const { category, loadingCategory } = useSelector(
    (state) => state.categoryApiReducer
  )

  const HandleCreateCategory = async (data) => {
    await dispatch(createCategory(data))
    dispatch(listCategories())
  }

  const HandleDeleteCategory = async (params) => {
    await dispatch(deleteCategory(params))
  }

  const HandleUpdateCategory = async ({ title, file }) => {
    const newData = {
      id: dataTemp.id,
      title,
      file,
      idx: dataTemp.idx
    }
    await dispatch(updateCategory(newData))
    dispatch(listCategories())
  }

  useEffect(() => {
    category.length <= 0 && dispatch(listCategories())
  }, [history, dispatch])

  useEffect(() => {
    dispatch(setDataTitle('Categorías - Banco de casos'))
    dispatch(setDataCreate(dataCreateForm.category))
  })

  return (
    <Wrapper>
      <ManageCaseStyle>
        <Search create='Crear categoría' />

        {loadingCategory ? (
          <Loading />
        ) : category && category.length > 0 ? (
          <div className='c-contCards' data-center={category.length > 2}>
            {category.map((category, index) => (
              <div
                key={`${category.categoryId}-category`}
                className='c-contCards-item'
              >
                <Card
                  options={{
                    image: category.categoryPath,
                    title: category.category,
                    navigation: `${path}/categoria/${category.categoryId}`
                  }}
                  dataEdit={{
                    title: 'Editar categoría',
                    titleDelete: '¿Está seguro de eliminar esta categoría?',
                    id: category.categoryId,
                    idx: index,
                    form: [
                      {
                        key: '1-editCategory',
                        label: 'Nombre',
                        type: 'text',
                        name: 'title',
                        value: category.category
                      },
                      {
                        key: '2-editCategory',
                        label: 'Imagen',
                        type: 'file',
                        dataIcon: 'upload',
                        name: 'file',
                        value: ''
                      }
                    ]
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <NotFound text='No hay categorías creadas.' />
        )}
      </ManageCaseStyle>

      <Modal id='modalCrear'>
        <ModalCreate styled='category' handleCreate={HandleCreateCategory} />
      </Modal>
      <Modal id='modalEdit'>
        <ModalEdit styled='category' handleEdit={HandleUpdateCategory} />
      </Modal>
      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={HandleDeleteCategory} />
      </Modal>
    </Wrapper>
  )
}

export default ManageCase
