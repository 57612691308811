const prefix = 'cg_'

export function setStorageValue(name, data) {
  localStorage.setItem(`${prefix}${name}`, JSON.stringify(data))
}

export function getStorageValue(name) {
  return JSON.parse(localStorage.getItem(`${prefix}${name}`))
}

export function deleteStorageValue(name) {
  return localStorage.removeItem(`${prefix}${name}`)
}
