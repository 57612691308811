import { createSlice } from '@reduxjs/toolkit'
import { formCreateRoomActions } from './formCreateRoom.actions'

const formCreateRoom = createSlice({
  name: 'formCreateRoom',
  initialState: {
    optionsUser: [],
    optionsCategory: [],
    optionsCases: [],
    currentRequestId: undefined,
    error: null
  },
  reducers: formCreateRoomActions
})

const { setOptionsUser, setOptionsCategory, setOptionsCases, setUsersList } =
  formCreateRoom.actions
const formCreateRoomReducer = formCreateRoom.reducer

export {
  formCreateRoomReducer,
  setOptionsUser,
  setOptionsCategory,
  setOptionsCases,
  setUsersList
}
