import {
  createRoom,
  deleteRoom,
  listRoom,
  roomPostTerms,
  listGuestRoom
} from '../../../../services/room.service'

const roomsActions = {
  [listRoom.pending]: (state) => {
    state.loadingRoom = true
  },
  [listRoom.fulfilled]: (state, { payload }) => {
    state.room = payload.data
    state.loadingRoom = false
  },
  [listRoom.rejected]: (state, action) => {
    state.loadingRoom = false
  },

  [listGuestRoom.pending]: (state) => {
    state.loadingGuest = true
  },
  [listGuestRoom.fulfilled]: (state, { payload }) => {
    state.listGuest = payload.data
    state.loadingGuest = false
  },
  [listGuestRoom.rejected]: (state, action) => {
    state.loadingGuest = false
  },

  [createRoom.fulfilled]: (state, { payload }) => {
    state.room.push(payload.data.body)
  },

  [deleteRoom.fulfilled]: (state, { payload }) => {
    const { params } = payload
    state.room.splice(params.idx, 1)
  },
  [roomPostTerms.pending]: (state) => {
    state.loadingRoom = true
  },
  [roomPostTerms.fulfilled]: (state, { payload }) => {
    state.loadingRoom = false
    state.roomAcceptTerms = true
  },
  [roomPostTerms.rejected]: (state, { payload }) => {
    state.loadingRoom = true
  }
}

export { roomsActions }
