import {
  createCharacter,
  deleteCharacter,
  listCharacter,
  updateCharacter
} from '../../../services/character.service'

const characterApiActions = {
  [listCharacter.pending]: (state) => {
    state.loadingCharacter = true
  },
  [listCharacter.fulfilled]: (state, { payload }) => {
    state.characters = payload
    state.loadingCharacter = false
  },
  [listCharacter.rejected]: (state, action) => {
    state.error = action.error
    state.loadingCharacter = false
  },
  [createCharacter.fulfilled]: (state, { payload }) => {
    state.characters.push(payload.data)
  },
  [deleteCharacter.fulfilled]: (state, { payload }) => {
    const { idx } = payload
    state.characters.splice(idx, 1)
  },
  [updateCharacter.fulfilled]: (state, { payload }) => {
    const { idx, data } = payload
    state.characters.splice(idx, 1, data)
  }
}

export { characterApiActions }
