import { createSlice } from '@reduxjs/toolkit'
import { recordingActions } from './recording.actions'

const recordingApi = createSlice({
  name: 'recordingApi',
  initialState: {
    recording: [],
    loadingRecording: false,
    recordingAcceptTerms: false,
    currentRequestId: undefined,
    error: null
  },
  extraReducers: recordingActions
})

const recordingApiReducer = recordingApi.reducer

export { recordingApiReducer }
