import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardInfoReport, Search, Wrapper } from '../../../components'
import { useValidateRole } from '../../../hooks/ValidateRole/useValidateRole'
import { setDataTitle } from '../../../redux/reducers'
import { listQuantities } from '../../../services/report.service'
import { reportCard, reportNav } from './reportNav'
import { ReportStyle } from './styled'

function Report() {
  const { reportQuantities } = useSelector((state) => state.reportApiReducer)
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)

  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    profile && setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    dispatch(setDataTitle('Reportes'))
    dispatch(listQuantities())
  }, [dispatch])

  return (
    <Wrapper>
      <ReportStyle>
        <Search />
        <div className='c-contCardsInfo'>
          {reportCard.map((item, idx) => (
            <div key={`reporInfo-${idx}`}>
              <CardInfoReport
                title={item.title}
                icon={item.icon}
                porcent={{
                  value: reportQuantities[item.key],
                  format: item.format
                }}
              />
            </div>
          ))}
        </div>
        <div className='c-contCards'>
          {reportNav.map((item, idx) => (
            <Fragment key={`${idx}-report`}>
              {!visible && item.title === 'Auditoría' ? null : (
                <div className='c-contCards-item'>
                  <Card options={item} />
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </ReportStyle>
    </Wrapper>
  )
}

export default Report
