import { Text } from 'ui-components'
import { ConditionsStyled } from './syled'

function Conditions() {
  return (
    <ConditionsStyled>
      <Text type='h5' text-align='center' addClass='c-title'>
        TÉRMINOS Y CONDICIONES
      </Text>
      <Text type='h5' text-align='center' addClass='c-title'>
        CONDICIONES LEGALES DE USO DEL SIMULADOR DE CÁMARA DE GESELL
      </Text>
      <Text type='h6' text-align='center' addClass='c-title'>
        ESCUELA CIENCIAS SOCIALES ARTES Y HUMANIDADES PROGRAMA PSICOLOGÍA
      </Text>
      <Text type='p' text-align='justify'>
        Mediante las presentes condiciones de uso, la Universidad Nacional
        Abierta y a Distancia – UNAD, regula el acceso a la información y los
        diferentes espacios que hacen parte del simulador Cámara de Gesell, por
        parte de sus estudiantes y demás usuarios autorizados.
      </Text>
      <Text type='p' text-align='justify'>
        La información contenida en este escenario de interacción, es de
        carácter académico y como tal, es propiedad exclusiva de la Universidad
        Nacional Abierta y a Distancia.– UNAD, por lo que se encuentra protegida
        por su Acuerdo No. 006 del 26 de Agosto de 2008, por el cual se aprobó
        el Estatuto de Propiedad Intelectual de la Universidad Nacional Abierta
        y a Distancia – UNAD.
      </Text>
      <Text type='p' text-align='justify'>
        El usuario de este escenario se obliga a no utilizar la información
        ofrecida en la misma para la realización de actividades diferentes a las
        académicas y a las contrarias a las leyes, la moral, el orden público y,
        en general, a hacer un uso conforme a las presentes condiciones. El uso
        que se le dé a la información, es responsabilidad exclusiva de quien lo
        realiza, sin que pueda responsabilizarse a la Universidad Nacional
        Abierta y a Distancia – UNAD de los daños o perjuicios que pudieran
        derivarse de dicho acceso o uso de la información, ajenas a su voluntad.
      </Text>
      <Text type='p' text-align='justify'>
        Por tal razón, se prohíbe la reproducción total o parcial de sus
        contenidos, por cualquier medio, sin la debida referencia donde se
        reconozcan los Derechos de Autor a la Institución. Así, el uso no
        autorizado de la información contenida, como la lesión de los derechos
        de propiedad intelectual, dará lugar a las responsabilidades legalmente
        establecidas.
      </Text>
      <Text type='p' text-align='justify'>
        Se recuerda que estos escenarios sincrónicos que se disponen para el
        proceso formativo, deben estar basados en el respeto y la ética en el
        manejo de cada caso abordado; así mismo, se reconoce que la interacción
        en este escenario implica una disposición por parte del estudiante,
        quien debe contar con un espacio que optimice la atención para el
        desarrollo de la sesión, así como el tiempo suficiente para revisar el
        material de apoyo que le permitirá mejores comprensiones.
      </Text>
      <Text type='p' text-align='center'>
        <i>
          “Mediante la presente declaración, al hacer uso del presente simulador
          manifiesto que conozco y acepto las condiciones establecidas en el
          Acuerdo No. 006 del 26 de agosto de 2008, por el cual se aprobó el
          Estatuto de Propiedad Intelectual de la Universidad Nacional Abierta y
          a Distancia – UNAD, así como las disposiciones enunciadas
          anteriormente”
        </i>
      </Text>
    </ConditionsStyled>
  )
}

export { Conditions }
