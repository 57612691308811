import styled from 'styled-components'

export const AccordionStyle = styled.details`
  @keyframes open {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  summary {
    &::marker {
      display: none;
    }
    display: grid;
    width: 100%;
    background: white;
    grid-template-columns: 1fr max-content max-content;
    align-items: center;
    grid-template-rows: 40px 25px;
    cursor: pointer;
    border-bottom: 1px solid lightgray;
    overflow: hidden;
    &::before {
      content: '❯';
      width: 40px;
      height: 1em;
      font-weight: bold;
      transition: all 0.35s;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: flex-end;
      grid-row: 2 / 3;
      grid-column: 3 / 4;
    }
    .c-title {
      grid-row: 1 / 3;
      padding-left: 0.5rem;
      &-strong {
        color: var(--primaryColor);
      }
    }
    .u-iconReport {
      padding: 0.25rem;
      &:nth-child(even) {
        border-right: 1px solid hsla(0deg, 0%, 50%, 26%);
      }
    }
  }

  .c-tab-content {
    padding: 1rem;
    background: white;
    border-bottom: 1px solid lightgray;
    transition: all 0.35s;
  }

  &[open] {
    summary {
      &::before {
        transform: rotate(90deg);
      }
      & ~ * {
        animation: open 0.3s ease-in-out;
      }
    }
  }
  background: white;
`
