export default function dataArray(getDataTable) {
  // let { path } = useRouteMatch()

  const data = getDataTable.map((item) => [
    [
      <span key={`${item.id}_name`}>
        <b>Nombre:</b>
        {item.fileName}
      </span>
    ],
    [
      <span key={`${item.id}_case`}>
        <b>Caso:</b>
        {item.caseName}
      </span>
    ],
    [
      <span key={`${item.id}_name`}>
        <b>Formato:</b>
        {item.fileFormat}
      </span>
    ]
  ])
  return data
}
