import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading, TableGrid } from 'ui-components'
import { dataCreateForm } from '../../../assets/data/dataCreate'
import {
  FormInvited,
  FormRoom,
  ListGuest,
  Modal,
  ModalAlert,
  NotFound,
  Search,
  Wrapper
} from '../../../components'
import { useValidateRole } from '../../../hooks/ValidateRole/useValidateRole'
import { setDataCreate, setDataTitle } from '../../../redux/reducers'
import {
  createRoom,
  deleteRoom,
  inviteRoom,
  listRoom
} from '../../../services/room.service'
import dataArray from './dataTable'
import { SslStyle } from './styled'

function Ssl() {
  const [Hidden, setHidden] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { room, loadingRoom } = useSelector((state) => state.roomApiReducer)
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)

  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    room.length <= 0 && dispatch(listRoom())
    room.length === 0 ? setHidden(true) : setHidden(false)
  }, [dispatch, room.length])

  useEffect(() => {
    dispatch(setDataCreate(dataCreateForm.room))
    dispatch(setDataTitle('Lista de salas'))
  }, [dispatch])

  const handleDeleteRoom = async ({ id, idx }) => {
    await dispatch(deleteRoom({ idx, id }))
  }
  const handleCreateRoom = async (data) => {
    await dispatch(createRoom(data))
  }

  const handleInviteRoom = async (data) => {
    await dispatch(inviteRoom({ id: dataTemp.id, idx: dataTemp.idx, ...data }))
  }

  useEffect(() => {
    // cambia el estado para refrescar la vista y habilitar el boton de ingreso
    const timer = setTimeout(() => {
      setLoading(!loading)
    }, 30000)
    return () => clearTimeout(timer)
  }, [loading])

  return (
    <Wrapper>
      <SslStyle>
        <Search create='Crear sala' />
        {loadingRoom ? (
          <Loading />
        ) : (
          <>
            <TableGrid
              gridColumn={{
                gridTemplateColumns: 'repeat(5, 1fr)'
              }}
              headContent={[
                { type: 'default', label: 'Nombre' },
                { type: 'default', label: 'Usuarios' },
                { type: 'default', label: 'Autor' },
                { type: 'default', label: 'Fecha de inicio' },
                { type: 'default', label: '' }
              ]}
              hidden={Hidden}
              bodyContent={dataArray(room, visible)}
            ></TableGrid>
            {Hidden && <NotFound text='No hay salas creadas.' />}
          </>
        )}
      </SslStyle>
      <Modal id='modalCrear'>
        <FormRoom submitFn={handleCreateRoom}></FormRoom>
      </Modal>

      <Modal id='modalAlert'>
        <ModalAlert handleConfirm={handleDeleteRoom} />
      </Modal>

      <Modal id='modalInvited'>
        <FormInvited submitFn={handleInviteRoom} />
      </Modal>

      <Modal id='modalListUser'>
        <ListGuest />
      </Modal>
    </Wrapper>
  )
}

export default Ssl
