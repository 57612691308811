export const reportNav = [
  {
    reportId: '1',
    title: 'Reportes individuales',
    image: './assets/images/reportesIndividuales.png',
    navigation: '/reportes/reporte-secciones'
  },
  {
    reportId: '2',
    title: 'Auditoría',
    image: './assets/images/auditoria.png',
    navigation: '/reportes/auditorias'
  },
  {
    reportId: '3',
    title: 'Cursos',
    image: './assets/images/cursos.png',
    navigation: '/reportes/cursos'
  }
]

export const reportCard = [
  {
    title: 'Casos',
    icon: 'folder_shared',
    key: 'cases'
  },
  {
    title: 'Categorías',
    icon: 'folder',
    key: 'categories'
  },
  {
    title: 'Curso',
    icon: 'school',
    key: 'courses'
  },
  {
    title: 'Documentos',
    icon: 'library_books',
    key: 'files'
  },
  {
    title: 'Memoria libre',
    icon: 'memory',
    key: 'freemem',
    format: 'Gb'
  },
  {
    title: 'Grupos',
    icon: 'groups',
    key: 'groups'
  },
  {
    title: 'Grabaciones',
    icon: 'videocam',
    key: 'recordings'
  },
  {
    title: 'Salas',
    icon: 'tv',
    key: 'rooms'
  },
  {
    title: 'Tamaño memoria',
    icon: 'memory',
    key: 'sizemem',
    format: 'Gb'
  },
  {
    title: 'Usuarios',
    icon: 'peoples',
    key: 'users'
  }
]
