import { useEffect } from 'react'
import { Icon } from 'ui-components'
import { useValidateRole } from '../../hooks/ValidateRole/useValidateRole'
import { ButtonModal } from '../ButtonModal'
import { AccordionStyle } from './styled'

function Accordion({ children, title, id, dataEdit, role, idx, iconReport }) {
  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    setRolId(role.i)
  }, [role, setRolId])

  return (
    <AccordionStyle>
      <summary>
        <p className='c-title'>
          <b className='c-title-strong'>{title.strong}</b> {title.text}
        </p>
        {visible && dataEdit ? (
          <>
            <ButtonModal
              target='modalEdit'
              payload={{
                title: dataEdit.titleEdit,
                id,
                idx,
                form: dataEdit.form
              }}
              type={{
                styled: 'primary-icon',
                icon: 'edit',
                addClass: 'button-icon'
              }}
            />
            <ButtonModal
              target='modalAlert'
              payload={{
                title: dataEdit.titleDelete,
                id,
                idx
              }}
              type={{
                styled: 'primary-icon',
                icon: 'delete',
                addClass: 'button-icon'
              }}
            />
          </>
        ) : null}
        {iconReport && (
          <>
            <span className='u-iconReport'>
              <Icon nameIcon='today' />
              <small>{iconReport.date}</small>
            </span>
            <span className='u-iconReport'>
              <Icon nameIcon='link' />
              <small>{iconReport.code}</small>
            </span>
          </>
        )}
      </summary>
      <div className='c-tab-content'>{children}</div>
    </AccordionStyle>
  )
}

export { Accordion }
