import styled from 'styled-components'

export const IntroductionStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  h2 {
    font-size: clamp(2.5rem, -0.875rem + 8.333333vw, 3.75rem) !important;
  }

  .loading {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 1100px) {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
`
