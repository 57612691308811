import { Link } from 'react-router-dom'
import { Button } from 'ui-components'
import { ButtonModal } from '../../../components'

export default function dataArray(getDataTable, visible) {
  /**
   * Parse String to Local Date
   * @param {String} value
   */
  const parseLocaleDateString = (value) => {
    let date = new Date(value)
    date = !date.getDate() ? convertStringToDate(value) : date
    const year = date.getFullYear()
    const month = ('00' + (date.getMonth() + 1)).slice(-2)
    const day = ('00' + date.getDate()).slice(-2)
    let hour = date.getHours()
    const minute = ('00' + date.getMinutes()).slice(-2)
    const meridian = hour <= 12 ? 'a. m.' : 'p. m.'
    hour = ('00' + (hour <= 12 ? hour : hour - 12)).slice(-2)

    return `${day}/${month}/${year} ${hour}:${minute} ${meridian}`
  }

  /**
   * Parse [yyyy/mm/dd HH:mm] to [Date()]
   * @param {String} value
   */
  const convertStringToDate = (value) => {
    const date = new Date(value)
    if (!date.getDate()) {
      value = value.replace(/(-|:|\/)/g, '').replace(' ', 'T')
      const [stringDate, stringTime] = value.split('T')
      return new Date(
        stringDate.slice(0, 4) +
          '-' +
          stringDate.slice(4, 6) +
          '-' +
          stringDate.slice(6, 8) +
          ' ' +
          stringTime.slice(0, 2) +
          ':' +
          stringTime.slice(2, 4)
      )
    } else {
      return date
    }
  }

  // const validateDate = (date) => {
  //   const startTime = convertStringToDate(date).getTime()
  //   const currentTime = new Date().getTime()
  //   console.log('startTime', startTime)
  //   console.log('currentTime', currentTime)
  //   const minute = (startTime - currentTime) / 60000 // min as milliseconds
  //   // revisar minute menor a 1
  //   return minute < 1
  // }

  const data = getDataTable.map((item, idx) => [
    [<span key={`${item.roomId}_name`}>{item.roomName}</span>],
    [
      <ButtonModal
        key={`${item.roomId}_user`}
        type={{
          styled: 'primary-icon',
          addClass: 'button-icon',
          icon: 'group'
        }}
        label={`${item.numberUsers}`}
        payload={{
          id: item.roomId,
          idx
        }}
        target='modalListUser'
      />
    ],
    [
      <span
        key={`${item.roomId}_create`}
      >{`${item.authorName} ${item.authorLastName}`}</span>
    ],
    [
      <span key={`${item.roomId}_startTime`}>
        {parseLocaleDateString(item.startTime)}
      </span>
    ],
    [
      <div key={`${item.roomId}_btns`} className='c-item-btns'>
        <Link
          className={item.room_internal_id === '' ? 'disabled-link' : ''}
          key={`${item.roomId}_ssl`}
          to={`lista-salas/sala/${item.roomId}/consentimiento`}
        >
          <Button
            styled='primary-icon'
            addClass='button-icon'
            icon='login'
            title='Ingresar'
          ></Button>
        </Link>
        {visible && (
          <>
            <ButtonModal
              type={{
                styled: 'primary-icon',
                addClass: 'button-icon',
                icon: 'person_add_alt_1'
              }}
              payload={{
                id: item.roomId,
                idx
              }}
              target='modalInvited'
            />
            <ButtonModal
              type={{
                styled: 'primary-icon',
                addClass: 'button-icon',
                icon: 'delete'
              }}
              target='modalAlert'
              payload={{
                title: '¿Está seguro de eliminar esta sala?',
                id: item.roomId,
                idx
              }}
            />
          </>
        )}
      </div>
    ]
  ])

  return data
}
