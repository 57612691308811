import login, {
  deleteSessionCookie,
  loginLTI,
  logout,
  setSessionCookie
} from '../../../services/session.service'
import {
  deleteStorageValue,
  setStorageValue
} from '../../../utilities/js/localStorage'

export const LoginApiActions = {
  [login.pending]: (state) => {
    state.loadingResponse.state = 'loading'
  },
  [login.fulfilled]: (state, { payload }) => {
    const { profile, token, nav, refreshToken } = payload
    state.nav = nav
    state.token = token
    state.profile = profile
    setSessionCookie('token', token)
    setSessionCookie('refreshToken', refreshToken)
    setStorageValue('profile', profile)
    setStorageValue('nav', nav)
    state.isLogged = true
    state.loadingResponse.state = 'sucess'
  },
  [login.rejected]: (state) => {
    state.loadingResponse.state = 'error'
    state.loadingResponse.message = 'Usuario o contraseña incorrecta.'
  },
  [loginLTI.pending]: (state) => {
    state.loadingResponse.state = 'loading'
  },
  [loginLTI.fulfilled]: (state, { payload }) => {
    const { profile, token, nav, refreshToken } = payload
    state.nav = nav
    state.token = token
    state.profile = profile
    setSessionCookie('token', token)
    setSessionCookie('refreshToken', refreshToken)
    setStorageValue('profile', profile)
    setStorageValue('nav', nav)
    state.isLogged = true
    state.loadingResponse.state = 'sucess'
  },
  [loginLTI.rejected]: (state) => {
    state.loadingResponse.state = 'error'
    state.loadingResponse.message = 'El token expiró, inicie sesión de nuevo'
  },

  [logout.fulfilled]: (state, { payload }) => {
    if (payload === 'success') {
      state.isLogged = false
      deleteSessionCookie('token')
      deleteSessionCookie('refreshToken')
      deleteStorageValue('profile')
      deleteStorageValue('nav')
    }
  }
}
