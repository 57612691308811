import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Search, Wrapper } from '../../../../components'
import { setDataTitle } from '../../../../redux/reducers'
import { listTables } from '../../../../services/report.service'
import { AuditStyle } from './styled'

function Audit() {
  // const [Hidden, setHidden] = useState(false)
  const dispatch = useDispatch()
  // const [data, setdata] = useState()
  // const [dataTable, setDataTable] = useState()
  // const { profile } = useSelector((state) => state.LoginApiReducer)
  const { reportTables } = useSelector((state) => state.reportApiReducer)

  useEffect(() => {
    dispatch(listTables())
  }, [dispatch])

  useEffect(() => {
    dispatch(setDataTitle('Auditoría'))
  }, [dispatch])

  return (
    <Wrapper>
      <AuditStyle>
        <Search back />
        <div className='c-contCards'>
          {reportTables.map((item, idx) => (
            <div key={`reportSection-${idx}`} className='c-contCards-item'>
              <Card
                options={{
                  title: item.value,
                  navigation: `/reportes/auditorias/${item.id}`
                }}
              />
            </div>
          ))}
        </div>
      </AuditStyle>
    </Wrapper>
  )
}

export default Audit
