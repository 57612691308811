import PageNotFound from '../pages/404/404'
import Home from '../pages/Home/Home'
import TermsConditions from '../pages/Introductions/TermsConditions/TermsConditions'
import VideoIntroduction from '../pages/Introductions/VideoIntroduction/VideoIntroduction'
import Welcome from '../pages/Introductions/Welcome/Welcome'
import Documents from '../pages/Sections/Documents/Documents'
import Help from '../pages/Sections/Help/Help'
import Case from '../pages/Sections/ManageCase/Case/Case'
import Cases from '../pages/Sections/ManageCase/Cases/Cases'
import Character from '../pages/Sections/ManageCase/Character/Character'
import Document from '../pages/Sections/ManageCase/Document/DocumentCase'
import ManageCase from '../pages/Sections/ManageCase/ManageCase'
import Recording from '../pages/Sections/ManageCase/Recording/RecordingCase'
import ConsentRoom from '../pages/Sections/ManageRooms/Consent/Consent'
import Curtain from '../pages/Sections/ManageRooms/Curtain/Curtain'
import ManageRooms from '../pages/Sections/ManageRooms/ManageRooms'
import Room from '../pages/Sections/ManageRooms/Room/Room'
import ManageUser from '../pages/Sections/ManageUser/ManageUser'
import Orientation from '../pages/Sections/Orientation/Orientation'
import Protocols from '../pages/Sections/Protocols/Protocols'
import ProtocolsRole from '../pages/Sections/Protocols/ProtocolsRole/ProtocolsRole'
import Recordings from '../pages/Sections/Recordings/Recordings'
import Audit from '../pages/Sections/Report/Audit/Audit'
import AuditView from '../pages/Sections/Report/Audit/AuditView'
import CoursesReport from '../pages/Sections/Report/CoursesReports/CoursesReports'
import Report from '../pages/Sections/Report/Report'
import ReportSection from '../pages/Sections/Report/UserReport/ReportSection/ReportSection'
import ReportUserFile from '../pages/Sections/Report/UserReport/ReportSection/ReportUserSection/ReportFiles/ReportUserFile'
import ReportUserFilesCase from '../pages/Sections/Report/UserReport/ReportSection/ReportUserSection/ReportFilesCase/ReportUserFilesCase'
import ReportUserRecording from '../pages/Sections/Report/UserReport/ReportSection/ReportUserSection/ReportRecording/ReportUserRecording'
import SectionReport from '../pages/Sections/Report/UserReport/ReportSection/ReportUserSection/Section/SectionReport'
import UserReport from '../pages/Sections/Report/UserReport/UserReport'

const paths = [
  {
    id: 2,
    name: 'Welcome',
    path: '/bienvenida',
    component: <Welcome />
  },
  {
    id: 3,
    name: 'Vodeo introduction',
    path: '/video-introduccion',
    component: <VideoIntroduction />
  },
  {
    id: 4,
    name: 'Conditions',
    path: '/terminos-condiciones',
    component: <TermsConditions />
  },
  {
    id: 5,
    name: 'Home',
    header: true,
    states: {
      nav: false
    },
    footer: false,
    path: '/secciones',
    component: <Home />
  },
  {
    id: 6,
    name: 'Manage user',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/gestionar-usuarios',
    component: <ManageUser />
  },
  {
    id: 7,
    name: 'Manage case',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true,
      edit: true,
      alert: true
    },
    path: '/gestionar-casos',
    component: <ManageCase />
  },
  {
    id: 8,
    name: 'Cases',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/gestionar-casos/categoria/:idcategory',
    component: <Cases />
  },
  {
    id: 8,
    name: 'Cases',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/gestionar-casos/categoria/:idcategory/caso/:idcaso',
    component: <Case />
  },
  {
    id: 9,
    name: 'Document case',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/gestionar-casos/categoria/:idcategory/caso/:idcaso/documentos',
    component: <Document />
  },
  {
    id: 10,
    name: 'Recording case',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/gestionar-casos/categoria/:idcategory/caso/:idcaso/grabaciones',
    component: <Recording />
  },
  {
    id: 101,
    name: 'Character case',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/gestionar-casos/categoria/:idcategory/caso/:idcaso/personajes',
    component: <Character />
  },
  {
    id: 11,
    name: 'Manage documents',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/gestionar-documentos',
    component: <Documents />
  },
  {
    id: 12,
    name: 'Manage rooms',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/lista-salas',
    component: <ManageRooms />
  },
  {
    id: 13,
    name: 'Curtain',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/lista-salas/sala/:idroom/cortinilla',
    component: <Curtain />
  },
  {
    id: 14,
    name: 'Consent',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/lista-salas/sala/:idroom/consentimiento',
    component: <ConsentRoom />
  },
  {
    id: 15,
    name: 'Sala',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/lista-salas/sala/:idroom',
    component: <Room />
  },
  {
    id: 16,
    name: 'Recordings',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/grabaciones',
    component: <Recordings />
  },
  {
    id: 17,
    name: 'Report',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes',
    component: <Report />
  },
  {
    id: 18,
    name: 'Report user',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/reporte-secciones',
    component: <UserReport />
  },
  {
    id: 28,
    name: 'Report user',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/reporte-secciones/:idUser',
    component: <ReportSection />
  },
  {
    id: 29,
    name: 'Report recording',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/reporte-secciones/:idUser/reporte-grabaciones',
    component: <ReportUserRecording />
  },
  {
    id: 30,
    name: 'Report section',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/reporte-secciones/:idUser/reporte-sesiones',
    component: <SectionReport />
  },
  {
    id: 31,
    name: 'Report files',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/reporte-secciones/:idUser/reporte-documentos',
    component: <ReportUserFile />
  },
  {
    id: 32,
    name: 'Report files case',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/reporte-secciones/:idUser/reporte-documentos-caso',
    component: <ReportUserFilesCase />
  },
  {
    id: 25,
    name: 'auditsReports',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/auditorias',
    component: <Audit />
  },
  {
    id: 27,
    name: 'auditsReports',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/auditorias/:id',
    component: <AuditView />
  },
  {
    id: 26,
    name: 'CoursesReport',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/reportes/cursos',
    component: <CoursesReport />
  },
  {
    id: 19,
    name: 'SupportMaterial',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/material-apoyo',
    component: <Documents />
  },
  {
    id: 20,
    name: 'Protocols',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/protocolos',
    component: <Protocols />
  },
  {
    id: 24,
    name: 'Protocols role',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: false
    },
    path: '/protocolos/rol/:idRole',
    component: <ProtocolsRole />
  },
  {
    id: 21,
    name: 'Orientation',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/orientacion-estudiante',
    component: <Orientation />
  },
  {
    id: 22,
    name: 'Helps',
    header: true,
    footer: true,
    states: {
      nav: true,
      create: true
    },
    path: '/ayuda',
    component: <Help />
  },
  {
    id: 23,
    name: 'not found',
    header: true,
    footer: false,
    states: {
      nav: true,
      create: false
    },
    component: <PageNotFound />
  }
]

export default paths
