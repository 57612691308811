import styled from 'styled-components'

export const ConsentStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  height: 100%;
  .c-title {
    margin: 1.2rem 0;
  }
  a {
    width: 100%;
    max-width: 400px;
  }
  [text-align='justify'] {
    text-align: justify;
  }
  ul {
    margin: 2rem;
    li {
      margin-bottom: 0.5rem;
    }
  }
`
