import { configureStore } from '@reduxjs/toolkit'
import reducers from './reducers'

const Store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          profile: 'extra'
        }
      },
      serializableCheck: false
    })
})

export default Store
