import { useDispatch, useSelector } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import './App.css'
import { setLogged } from './redux/reducers'
import Routes from './routing'
import { deleteSessionCookie } from './services/session.service'

function App() {
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  window.addEventListener('storage', (e) => {
    if (e.key === 'cg_profile' && profile) {
      dispatch(setLogged(false))
      localStorage.clear()
      deleteSessionCookie('token')
      deleteSessionCookie('refreshToken')
      location.reload()
    }
  })
  return (
    <Router
    // basename='/gesell'
    >
      <Routes />
    </Router>
  )
}
// setTimeout(() => {
//   window.location.reload()
// }, 30000)
export default App
