import { useDispatch } from 'react-redux'
import { Button } from 'ui-components'
import { setDataTemp } from '../../redux/reducers'
import { reportCoursesGroup } from '../../services/report.service'

function ButtonModal({ target, label, payload, type, report, title }) {
  const dispacth = useDispatch()
  const titles = {
    delete: 'Eliminar',
    edit: 'Editar',
    visibility: 'Deshabilitar',
    visibility_off: 'Habilitar',
    add: 'Agregar',
    person_add_alt_1: 'Agregar usuario',
    people: 'Integrantes'
  }

  function handleClick() {
    const modalElm = document.querySelector(`#${target}`)
    modalElm && modalElm.classList.toggle('is-visible')
    payload && dispacth(setDataTemp(payload))
    if (report) {
      dispacth(reportCoursesGroup(payload))
    }
  }

  return (
    <Button
      title={title || titles[type.icon]}
      label={label}
      onClick={handleClick}
      {...type}
    />
  )
}

export { ButtonModal }
