import {
  deleteUser,
  fetchUsers,
  postTerms
} from '../../../services/user.service'
import {
  getStorageValue,
  setStorageValue
} from '../../../utilities/js/localStorage'

const userApiActions = {
  [fetchUsers.pending]: (state, action) => {
    state.loadingUsers = true
  },
  [fetchUsers.fulfilled]: (state, { payload }) => {
    state.users = payload?.response
    state.loadingUsers = false
  },
  [fetchUsers.rejected]: (state, action) => {
    state.error = action.error
    state.loadingUsers = false
  },
  [deleteUser.fulfilled]: (state, { payload }) => {
    const { params } = payload
    const newItems = state.users.filter((item) => item.id !== params.userId)
    state.users = newItems
  },
  [deleteUser.rejected]: (state, action) => {},
  [postTerms.pending]: (state) => {
    state.loadingUsers = true
  },
  [postTerms.fulfilled]: (state) => {
    const res = getStorageValue('profile')
    res.acceptTerms.initialTerms = 1
    setStorageValue('profile', res)
    state.loadingUsers = false
    state.userAcceptTerms = true
  },
  [postTerms.rejected]: (state) => {
    state.loadingUsers = true
  }
}

export { userApiActions }
