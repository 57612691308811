import styled from 'styled-components'

export const CasesStyle = styled.div`
  .c-tab-content {
    &-btns {
      display: flex;
      justify-content: flex-end;
    }
    &-txt {
      .c-link a {
        color: var(--primaryColorDark);
        padding-top: 0.5rem;
        display: block;
        text-align: right;
        font-weight: bold;
      }
    }
  }
`
