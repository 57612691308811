import { createSlice } from '@reduxjs/toolkit'
import { casesApiActions } from './casesAPI.actions'
const casesAPI = createSlice({
  name: 'casesApi',
  initialState: {
    cases: [],
    loadingCases: false,
    casesBycategory: [],
    currentRequestId: undefined,
    error: null
  },
  reducers: {
    getCaseByCategory(state, { payload }) {
      state.casesBycategory = state.cases.filter(
        (item) => item.category_id === parseInt(payload)
      )
    }
  },
  extraReducers: casesApiActions
})

const caseAPIReducer = casesAPI.reducer
const { getCaseByCategory } = casesAPI.actions

export { caseAPIReducer, getCaseByCategory }
