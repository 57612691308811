import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Loading, Text } from 'ui-components'
import { IntroductionStyle } from './styled'

function Introduction({ navigation, children, showBtn = false }) {
  const { loadingUsers, userAcceptTerms } = useSelector(
    (state) => state.usersApiReducer
  )
  return (
    <IntroductionStyle>
      <Text type='h2' text-align='center'>
        Bienvenido a la Cámara de Gesell
      </Text>
      {children}
      {loadingUsers ? (
        <Loading addClass='loading' />
      ) : !showBtn ? (
        <Link to={navigation} disabled={!userAcceptTerms}>
          <Button
            disabled={!userAcceptTerms}
            label='Continuar'
            addClass='button-continue'
            styled='secondary'
          ></Button>
        </Link>
      ) : (
        <Link to={navigation}>
          <Button
            label='Continuar'
            addClass='button-continue'
            styled='secondary'
            title='continuar'
          ></Button>
        </Link>
      )}
    </IntroductionStyle>
  )
}

export { Introduction }
