import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import { Button, Video } from 'ui-components'
import { Wrapper } from '../../../../components'
import { setDataTitle } from '../../../../redux/reducers'
import { CurtainStyle } from './styled'

function Curtain() {
  const { params } = useRouteMatch()
  const dispatch = useDispatch()
  useEffect(() => dispatch(setDataTitle('')))

  return (
    <Wrapper>
      <CurtainStyle>
        <Video
          url='./assets/videos/bgWelcome.mp4'
          addClass='c-video'
          width='900px'
          poster='./assets/images/posterDefault.png'
        ></Video>
        <Link to={`/lista-salas/sala/${params.idroom}/consentimiento`}>
          <Button
            addClass='button-continue'
            label='Continuar'
            styled='secondary'
          ></Button>
        </Link>
      </CurtainStyle>
    </Wrapper>
  )
}

export default Curtain
