import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Text } from 'ui-components'
import { useValidateRole } from '../../hooks/ValidateRole/useValidateRole'
import { ButtonModal } from '../ButtonModal'
import { CardStyle } from './styled'
// TODO: verificar payload y dataCreate
function Card({ options, dataEdit, onFn }) {
  const { profile } = useSelector((state) => state.LoginApiReducer)

  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    profile && setRolId(profile.r.i)
  }, [profile, setRolId])

  return (
    <CardStyle>
      <div className='c-card-img'>
        <figure>
          <img src={options.image} alt={options.title} />
        </figure>
        {visible && dataEdit ? (
          <ButtonModal
            target='modalEdit'
            payload={dataEdit}
            type={{
              styled: 'primary-icon',
              icon: 'edit',
              addClass: 'button-icon modal-btn'
            }}
          />
        ) : null}
      </div>

      <div className='c-card-cont'>
        <div className='c-card-cont--title'>
          <Text type='h6'>{options.title}</Text>
        </div>
        <div className='c-card-cont--btn'>
          {options.navigation && (
            <Link to={options.navigation}>
              <Button label='Ingresar' styled='secondary' />
            </Link>
          )}
          {options.link && (
            <a href={options.link} target='blank'>
              <Button label='Ingresar' styled='secondary' />
            </a>
          )}
          {options.idFn && (
            <Button
              label='Ingresar'
              styled='secondary'
              onClick={onFn}
              addClass='c-btnFn'
              data-id={options.idFn}
            />
          )}
          {options.action && (
            <ButtonModal
              type={{
                styled: 'secondary',
                addClass: 'button-modal'
              }}
              target={options.action}
              label='ingresar'
              payload={{
                title: options.title
              }}
            ></ButtonModal>
          )}
          {visible && dataEdit ? (
            <ButtonModal
              target='modalAlert'
              payload={{
                title: dataEdit.titleDelete,
                id: dataEdit.id,
                idx: dataEdit.idx
              }}
              type={{
                addClass: 'button-icon',
                styled: 'primary-icon',
                icon: 'delete'
              }}
            />
          ) : null}
        </div>
      </div>
    </CardStyle>
  )
}

export { Card }
