import styled from 'styled-components'

export const AuditCaseStyled = styled.div`
  .c-title-audit {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }
  .c-contInfo-modal {
    overflow-y: scroll;
  }
  .c-contInfo-modal--item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 10px;
    max-height: 70vh;
    &[data-flex='true'] {
      grid-template-columns: 1fr;
    }
    .c-cont-before,
    .c-cont-after {
      text-align: left;
      padding: 1rem;
    }
    .c-cont-before[data-border='true'] {
      border-right: 1px solid var(--primaryColorLight);
    }
    @media screen and (max-width: 900px) {
      display: block;
      .c-cont-before[data-border='true'] {
        border-bottom: 1px solid var(--primaryColorLight);
        border-right: none;
      }
    }
  }
`
