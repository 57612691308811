import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { AsideNav, Loading } from 'ui-components'
import { Icon, Title, UserCard } from '../../'
import { logout } from '../../../services/session.service'
import { AsideNavStyle, NavMobileStyle } from './styled'

function NavMobile({ data, loading, hidden }) {
  const dispatch = useDispatch()

  const handleLogout = async () => {
    await dispatch(logout())
  }
  return (
    <AsideNavStyle>
      {hidden && (
        <>
          <AsideNav
            dataButton={{
              type: 'icon',
              label: 'menu',
              styled: 'primary'
            }}
          >
            <NavMobileStyle>
              <UserCard />
              <div className='c-nav-navbar'>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    {data.map((item, index) => (
                      <div
                        key={`${index}-navMobile`}
                        className='c-nav-navbar--link'
                      >
                        <NavLink
                          to={item.link}
                          activeClassName='active'
                          aria-current='page'
                        >
                          <Icon iconName={item.icon} />
                          <span>{item.label}</span>
                        </NavLink>
                      </div>
                    ))}
                    <div className='c-nav-navbar--link'>
                      <button
                        className='c-logout'
                        onClick={handleLogout}
                        type='button'
                        title='cerrar sesión'
                      >
                        <Icon iconName='logout' />
                        <span>Cerrar sesión</span>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </NavMobileStyle>
          </AsideNav>

          <Title type='h5' align='center' />
        </>
      )}
      <div className='c-contHelpNoti'>
        <NavLink
          to='/ayuda'
          activeClassName='active'
          aria-current='page'
          className='c-link'
        >
          <Icon iconName='ayuda' />
          <span>ayuda</span>
        </NavLink>
      </div>
    </AsideNavStyle>
  )
}

export { NavMobile }
