import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useRouteMatch } from 'react-router-dom'
import { Button, Loading, Text } from 'ui-components'
import { dataCreateForm } from '../../../../assets/data/dataCreate'
import {
  Accordion,
  ButtonModal,
  Modal,
  ModalAlert,
  ModalCreate,
  ModalEdit,
  NotFound,
  Search,
  Wrapper
} from '../../../../components'
import { useValidateRole } from '../../../../hooks/ValidateRole/useValidateRole'
import { setDataCreate, setDataTitle } from '../../../../redux/reducers'
import { getCaseByCategory } from '../../../../redux/reducers/casesAPI/casesApi'
import {
  createCase,
  deleteCase,
  listCases,
  updateCase
} from '../../../../services/case.service'
import { CasesStyle } from './styled'

function Cases() {
  const { dataTemp } = useSelector((state) => state.utilitiesReducer)
  const { cases, loadingCases, casesBycategory } = useSelector(
    (state) => state.caseAPIReducer
  )
  const { newResponse } = useSelector((state) => state.utilitiesReducer)
  const { idcategory } = useParams()
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { setRolId, visible } = useValidateRole()

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    dispatch(listCases(idcategory))
  }, [dispatch, idcategory])

  useEffect(() => {
    if (cases.length <= 0) {
      dispatch(listCases())
    } else {
      dispatch(getCaseByCategory(idcategory))
    }
  }, [dispatch, idcategory, cases])

  useEffect(() => {
    dispatch(setDataTitle('Banco de casos'))
    dispatch(setDataCreate(dataCreateForm.case))
  }, [dispatch])

  const handleDeleteCase = async (params) => {
    await dispatch(deleteCase(params))
  }

  const handleCreateCase = async (dataform) => {
    const newCaseDB = {
      ...dataform,
      status_id: 1,
      category_id: Number(idcategory)
    }

    await dispatch(createCase(newCaseDB))
  }

  const handleUpdateCase = async (dataForm, type) => {
    const data = { ...dataForm, id: dataTemp.id }
    await dispatch(updateCase({ data, type, idx: dataTemp.idx }))
  }

  const handleEnabledCase = ({ status_id }) => {
    if (status_id === 1) {
      status_id = 2
    } else {
      status_id = 1
    }
    handleUpdateCase({ status_id }, 'status')
  }

  return (
    <Wrapper>
      <CasesStyle>
        <Search create='Crear caso' back />
        {loadingCases ? (
          <Loading />
        ) : casesBycategory && casesBycategory.length > 0 ? (
          casesBycategory.map((item, idx) => (
            <Fragment key={idx}>
              {visible || item.status_id === 1 ? (
                <Accordion
                  title={{
                    strong: `Caso ${idx + 1}:`,
                    text: item.title
                  }}
                  idx={idx}
                  dataEdit={{
                    titleEdit: `Editar caso: ${item.title}`,
                    titleDelete: `¿Está seguro de eliminar el caso: ${item.title}?`,
                    form: [
                      {
                        key: '1-caseEdit',
                        label: 'Título',
                        type: 'text',
                        name: 'title',
                        value: item.title
                      },
                      {
                        key: '2-caseEdit',
                        label: 'Intenciones de aprendizaje',
                        type: 'textarea',
                        name: 'learning',
                        rows: '5',
                        value: item.learning
                      },
                      {
                        key: '3-caseEdit',
                        label: 'Párrafo inicial',
                        type: 'textarea',
                        name: 'introduction',
                        rows: '5',
                        value: item.introduction
                      },
                      {
                        key: '4-caseEdit',
                        label: 'Contexto del caso',
                        type: 'textarea',
                        name: 'context',
                        rows: '5',
                        value: item.context
                      },
                      {
                        key: '5-caseEdit',
                        label: 'Cuerpo del caso',
                        type: 'textarea',
                        name: 'body',
                        rows: '5',
                        value: item.body
                      }
                    ]
                  }}
                  id={item.id}
                  role={profile.r}
                >
                  <div className='c-tab-content-btns'>
                    {visible && (
                      <ButtonModal
                        payload={{
                          title: `¿Está seguro de ${
                            item.status_id === 1 ? 'inhabilitar' : 'habilitar'
                          } este caso?`,
                          id: item.id,
                          idx,
                          status_id: item.status_id
                        }}
                        type={{
                          styled: 'primary-icon',
                          addClass: 'button-icon',
                          icon:
                            item.status_id === 1
                              ? 'visibility'
                              : 'visibility_off'
                        }}
                        target='modalEnable'
                      ></ButtonModal>
                    )}
                    <Link to={`${url}/caso/${item.id}/personajes`}>
                      <Button
                        icon='person'
                        styled='primary-icon'
                        addClass='button-icon'
                        title='Personajes'
                      />
                    </Link>
                    <Link to={`${url}/caso/${item.id}/documentos`}>
                      <Button
                        icon='description'
                        styled='primary-icon'
                        addClass='button-icon'
                        title='Documentos'
                      />
                    </Link>
                    <Link to={`${url}/caso/${item.id}/grabaciones`}>
                      <Button
                        icon='videocam'
                        styled='primary-icon'
                        addClass='button-icon'
                        title='Grabaciones'
                      />
                    </Link>
                  </div>
                  <div className='c-tab-content-txt'>
                    <Text type='p'>{item.learning}</Text>
                    <span className='c-link'>
                      <Link
                        to={{
                          pathname: `${url}/caso/${item.id}`,
                          state: {
                            case: {
                              body: item.body,
                              title: item.title,
                              context: item.context,
                              id: item.id,
                              introduction: item.introduction,
                              learning: item.learning
                            },
                            id: idx
                          }
                        }}
                      >
                        Leer mas..
                      </Link>
                    </span>
                  </div>
                </Accordion>
              ) : null}
            </Fragment>
          ))
        ) : (
          <NotFound text='No hay casos creados.' />
        )}
      </CasesStyle>

      <Modal id='modalCrear' size='large' response={newResponse}>
        <ModalCreate styled='cases' handleCreate={handleCreateCase} />
      </Modal>
      <Modal id='modalEdit' size='large' response={newResponse}>
        <ModalEdit styled='cases' handleEdit={handleUpdateCase} />
      </Modal>
      <Modal id='modalAlert' response={newResponse}>
        <ModalAlert handleConfirm={handleDeleteCase} />
      </Modal>
      <Modal id='modalEnable' response={newResponse}>
        <ModalAlert handleConfirm={handleEnabledCase} />
      </Modal>
    </Wrapper>
  )
}

export default Cases
