import styled from 'styled-components'

export const CoursesReportStyled = styled.div`
  .c-contInfo-modal {
    overflow-y: scroll;
    .c-contInfo {
      max-height: 70vh;
      width: 100%;
    }
  }
  .c-tab-content-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 1rem;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .c-title-groups {
    background: var(--primaryColorDark);
    color: white;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    i {
      font-size: 1.5rem;
      width: max-content;
      height: max-content;
    }
  }

  .c-item-user {
    padding: 1rem 0;
    border-bottom: 1px solid lightgray;
    width: 100%;
    &:last-child {
      border-bottom: none;
    }
  }
`
