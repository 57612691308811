import styled from 'styled-components'

export const WrapperStyle = styled.section`
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  &:after {
    content: '';
    width: '300px';
    height: '200px';
  }
  & > div {
    margin-top: 2rem;
  }
`
