import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Icon } from 'ui-components'
import { setNewResponse } from '../../redux/reducers'
import { ModalStyle } from './styled'

function Modal({ children: childrenProp, size, id, noAction, close }) {
  const { modalVisible } = useSelector((state) => state.utilitiesReducer)
  const dispatch = useDispatch()

  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement(child) || noAction) {
      return null
    }

    return React.cloneElement(child, {
      onClick: handleModal
    })
  })

  function handleModal({ target }) {
    const modalElm = document.querySelector(`#${id}`)
    modalElm && modalElm.classList.toggle('is-visible')
    dispatch(setNewResponse({ state: 'idle', message: '', errors: [] }))
  }

  return (
    <ModalStyle id={id}>
      <div
        className='c-modal-overlay'
        id='overlay'
        data-visible={modalVisible}
      />

      <div className='c-modal ui-modal' id='modal' data-size={size}>
        {!close && (
          <Icon
            nameIcon='close'
            onClick={handleModal}
            style={{ cursor: 'pointer' }}
          />
        )}

        <div className='ui-modal-content'>{children}</div>
      </div>
    </ModalStyle>
  )
}

export { Modal }
