import { createAsyncThunk } from '@reduxjs/toolkit'
import { post } from './request.service'

const nameCollection = 'subscription'

const subscription = async () => {
  try {
    const register = await navigator.serviceWorker.register('/worker.js', {
      scope: '/'
    })
    const subscription = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUnit8Array(
        process.env.REACT_APP_PUBLIC_VAPID_KEY
      )
    })
    await post(nameCollection, subscription)
  } catch (error) {
    console.log(`subscription ~ error`, error)
  }
}

const sendNotification = createAsyncThunk(
  'notification/send',
  async (roomId) => {
    const response = await post(`${nameCollection}/notify`, { roomId })
    return response
  }
)

const urlBase64ToUnit8Array = (base64string) => {
  const padding = '='.repeat((4 - (base64string.length % 4)) % 4)
  const base64 = (base64string + padding).replace(/-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export { subscription, sendNotification }
