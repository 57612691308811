import { Link } from 'react-router-dom'
import { Button } from 'ui-components'

// TODO: pasar handleClick
export default function dataArray(getDataTable) {
  const data = getDataTable.map((item) => [
    [
      <div key={`${item.id}_name`} className='c-item-user'>
        <span>{`${item.name} ${item.lastName}`}</span>
        <span>{item.email}</span>
        <span>
          {item.courseCode && <b>Código del curso: </b>}
          {item.courseCode}
        </span>
      </div>
    ],
    [
      <Link
        to={`/reportes/reporte-secciones/${item.id}`}
        key={`${item.id}_link`}
      >
        <Button
          styled='primary-icon'
          addClass='button-icon'
          icon='visibility'
          title='Ver'
        ></Button>
      </Link>
    ]
  ])
  return data
}
