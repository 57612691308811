import { createAsyncThunk } from '@reduxjs/toolkit'
import { setNewResponse } from '../redux/reducers'
import { destroy, get, post, put } from './request.service'

const nameCollection = 'caseFile'

const listFilesCases = createAsyncThunk(
  'listFilesCases/list',
  async (idcaso) => {
    const response = await get(`${nameCollection}/?case=${idcaso}`)
    return { data: response.data.body }
  }
)

const updateFileCases = createAsyncThunk(
  'listFilesCases/update',
  async (params, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await put(
        `${nameCollection}/${params.data.fileId}`,
        params.data
      )
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido editado correctamente.'
        })
      )
      return { data: response.data.body, idx: params.index }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo no ha sido editado correctamente.'
        })
      )
      throw error
    }
  }
)

const deleteFileCases = createAsyncThunk(
  'listFilesCases/delete',
  async ({ fileName, index }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const response = await destroy(`${nameCollection}/${fileName}`)
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo ha sido eliminado correctamente.'
        })
      )
      return { response, index }
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo no ha sido eliminado correctamente.'
        })
      )
      throw error
    }
  }
)

const createFileCases = createAsyncThunk(
  'listFilesCases/create',
  async ({ idcaso, file, roleId }, { dispatch }) => {
    dispatch(setNewResponse({ state: 'loading' }))
    try {
      const params = new FormData()
      params.append('roleId', roleId)
      params.append('typeId', 1)
      params.append('files', file[0])
      params.append('caseId', idcaso)
      params.append('statusId', 1)

      const response = await post(nameCollection, params)
      const newDocument = response.data.body
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo se ha cargado correctamente.'
        })
      )
      return newDocument
    } catch (error) {
      dispatch(
        setNewResponse({
          state: 'sucess',
          message: 'El archivo no pudo ser cargado.'
        })
      )
      throw error
    }
  }
)

export { listFilesCases, deleteFileCases, createFileCases, updateFileCases }
