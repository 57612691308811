import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from './request.service'

const nameCollection = 'role?type=2'

const listRoomRoles = createAsyncThunk(
  'room/listRole',
  async (params, { getState }) => {
    const { profile } = getState().LoginApiReducer
    const rolId = profile.r.i === 'pnWt'
    if (rolId) {
      return { response: [] }
    } else {
      const response = await get(nameCollection)
      return { response: response.data.body }
    }
  }
)

export { listRoomRoles }
