export default function dataArray(getDataTable) {
  const formarDate = (value) => {
    const date = new Date(value)
    const months = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ]
    const formatDate = `
      ${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`

    return formatDate
  }

  const data = getDataTable.map((item) => [
    [
      <span key={`${item.id}_name`}>
        <b>Nombre:</b>
        {item.sessionName}
      </span>
    ],
    [
      <span key={`${item.id}_date`}>
        <b>Fecha: </b>
        {formarDate(item.sessionDate)}
      </span>
    ],
    [
      <span key={`${item.id}_case`}>
        <b>Caso </b>
        {item.caseName}
      </span>
    ],
    [
      <span key={`${item.id}_state`}>
        <b>Estado: </b>
        {item.sessionStatus}
      </span>
    ]
  ])
  return data
}
