import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  setOptionsCases,
  setOptionsCategory,
  setOptionsUser
} from '../redux/reducers/roomsApi'
import { get } from './request.service'

const getOptionsUser = createAsyncThunk(
  'form/optsUser',
  async ({ profile }, { getState, dispatch }) => {
    let listUsers = getState().usersApiReducer.users
    const getProfile = getState().LoginApiReducer.profile
    const rolId = getProfile.r.i === 'pnWt'
    if (rolId) {
      dispatch(setOptionsUser([]))
    } else {
      if (listUsers.length <= 0) {
        const response = await get('user')
        listUsers = response.data.body
      }
      const arrOptions = setOptionsUsers({ listUsers, profile })

      dispatch(setOptionsUser(arrOptions))
    }
  }
)

const getOptionsCategory = createAsyncThunk(
  'form/optsCategory',
  async (params, { getState, dispatch }) => {
    let listCategory = getState().categoryApiReducer.category
    if (listCategory.length <= 0) {
      const response = await get('category')
      listCategory = response.data.body
    }
    const arrOptions = setOptions(listCategory, {
      id: 'categoryId',
      value: 'category'
    })
    dispatch(setOptionsCategory(arrOptions))
  }
)

const getOptionsCase = createAsyncThunk(
  'form/optsCase',
  async (categoryId, { getState, dispatch }) => {
    let listCases = getState().caseAPIReducer.cases
    if (listCases.length <= 0) {
      const response = await get('case')
      listCases = response.data.body
      listCases = listCases.filter(
        (item) => item.category_id === parseInt(categoryId)
      )
    }
    const arrOptions = setOptions(listCases, {
      id: 'id',
      value: 'title'
    })
    dispatch(setOptionsCases(arrOptions))
  }
)

const setOptions = (obj, keys) => {
  const data = []
  obj.map((item) => {
    const option = { id: item[keys.id], value: item[keys.value] }
    data.push(option)
    return true
  })
  return data
}

const setOptionsUsers = ({ listUsers, profile }) => {
  const array = listUsers.filter((value) => {
    return value.email !== profile.email
  })
  return array
}
export { getOptionsUser, getOptionsCategory, getOptionsCase }
