import {
  createCase,
  deleteCase,
  listCases,
  updateCase
} from '../../../services/case.service'

const casesApiActions = {
  [listCases.pending]: (state) => {
    state.loadingCases = true
  },
  [listCases.fulfilled]: (state, { payload }) => {
    state.cases = payload.data
    state.loadingCases = false
  },
  [listCases.rejected]: (state, action) => {
    state.error = action.error
    state.loadingCases = false
  },

  [createCase.fulfilled]: (state, { payload }) => {
    state.cases.push(payload.data)
  },

  [deleteCase.fulfilled]: (state, { payload }) => {
    const { id } = payload
    const newItems = state.cases.filter((item) => item.id !== id)
    state.cases = newItems
  },

  [updateCase.fulfilled]: (state, { payload }) => {
    const { id, data } = payload
    const itemSelected = state.cases.findIndex((item) => item.id === id)
    state.cases.splice(itemSelected, 1, data)
  }
}

export { casesApiActions }
