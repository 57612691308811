import styled from 'styled-components'

export const ProtocolsStyle = styled.div`
  .c-contCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 335px));
    gap: 2rem;
    /* @media screen and (max-width: 1300px) {
      justify-content: center;
    } */
    &-item {
      background: white;
    }
    &[data-center='true'] {
      justify-content: center;
    }
  }
  .c-card-img figure img {
    object-position: 20% 15%;
  }
`
