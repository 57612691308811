import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Loading, TableGrid } from 'ui-components'
import { NotFound, Search, Wrapper } from '../../../../components'
import { useValidateRole } from '../../../../hooks/ValidateRole/useValidateRole'
import { setDataTitle } from '../../../../redux/reducers'
import { listRecording } from '../../../../services/recording.service'
import dataArray from './dataTable'
import { RecordingCaseStyle } from './styled'

function RecordingCase() {
  const [Data, setData] = useState()
  const { recording, loadingRecording } = useSelector(
    (state) => state.recordingApiReducer
  )
  const { params } = useRouteMatch()
  const dispatch = useDispatch()
  const { profile } = useSelector((state) => state.LoginApiReducer)
  const { setRolId, visible } = useValidateRole()
  const [dataTable, setDataTable] = useState()

  useEffect(() => {
    setRolId(profile.r.i)
  }, [profile, setRolId])

  useEffect(() => {
    recording.length <= 0 && dispatch(listRecording())
  }, [dispatch])

  useEffect(() => {
    const dataRecording = recording?.filter(
      (item) => item.case_id === Number(params.idcaso)
    )
    setData(dataRecording)
    dispatch(setDataTitle('Grabaciones del caso'))
  }, [params.idcaso, dispatch, recording])

  useEffect(() => {
    if (Data) {
      const dataTable = dataArray(Data, visible)
      setDataTable(dataTable)
    }
  }, [Data, visible])

  return (
    <Wrapper>
      <RecordingCaseStyle>
        <Search back />
        {loadingRecording ? (
          <Loading />
        ) : dataTable && dataTable.length > 0 ? (
          <TableGrid
            gridColumn={{
              gridTemplateColumns: ' 1fr 1fr 1fr 1fr 40px'
            }}
            headContent={[
              { type: 'default', label: 'Nombre sala' },
              { type: 'default', label: 'Imagen miniatura' },
              { type: 'default', label: 'Fecha' },
              { type: 'default', label: 'Usuario' },
              { type: 'default', label: '' }
            ]}
            bodyContent={dataTable}
          ></TableGrid>
        ) : dataTable && dataTable.length <= 0 ? (
          <NotFound text='Este caso no tiene grabaciones activas.' />
        ) : null}
      </RecordingCaseStyle>
    </Wrapper>
  )
}

export default RecordingCase
