import { useEffect, useState } from 'react'

export default function useFilter(data, value, type) {
  const [DataFilter, setData] = useState([])
  const [DataRequestFilter, setDataRequestFilter] = useState(data)
  const [DataValueFilter, setDataValueFilter] = useState(value)

  useEffect(() => {
    setDataRequestFilter(data)
    if (DataValueFilter !== '' && DataValueFilter) {
      const dataFilterSelect = DataRequestFilter.filter(
        (item) => item[type] === DataValueFilter
      )
      setData(dataFilterSelect)
    } else {
      setData(DataRequestFilter)
    }
  }, [DataRequestFilter, DataValueFilter, data, type])

  return { DataFilter, setDataRequestFilter, setDataValueFilter }
}
